import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
  faCalendarDays,
  faClock,
  faEye,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import {
  Spin,
  Modal,
  Button,
  Form,
  Input,
  Upload,
  message,
  Select,
  notification,
  Avatar,
  DatePicker,
} from "antd";
import { Player, LoadingSpinner } from "video-react";
import "video-react/dist/video-react.css";
import { UserOutlined } from "@ant-design/icons";
import numeral from "numeral";
import GroupIcon from "@mui/icons-material/Group";
import ReactPlayer from "react-player";
import LoadingSpin from "../../reUsable/loadingSpin";
import * as savedCategoriesAction from "../../redux/actions/newCategory.action";
import { searchReviewID } from "../../redux/actions/eventID.action";
import { getInfluencers } from "../../redux/actions/influencerNew.action";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

function UgcDeleted({
  getReviewControl,
  getReviewControlData,
  reviewPublish,
  getAllCreator,
  getAllCreatorData,
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
  savedGeneralCategories,
  savedCategory,
  savedSubCategory,
}) {
  const childRef = useRef();
  const dispatch = useDispatch();
  // const [formLink] = Form.useForm();
  const {
    getAllInfluencers
  } = useSelector((state) => {
    return state;
  });
  const [loading, setLoading] = useState(true);
  const [currentData, setCurrentData] = useState({});
  const [publishLoading, setPublishLoading] = useState(false);

  // const fromDate = moment().startOf('year').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const fromDate = moment().startOf("year").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [influencerId, setInfluencerId] = useState();
  const [influencer, setInfluencer] = useState("");
  const [sortBy, setSortBy] = useState("date");
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [reviewID, setReviewID] = useState();
  const [reviewIDLoad, setReviewIDLoad] = useState("");
  const [searchReviewIDs, setSearchReviewIDs] = useState([]);
  const limit = 8;

  useEffect(() => {
    dispatch(getInfluencers()).then((res) => {
    })
    setGeneralLoad(true);
    setReviewIDLoad(true)
    dispatch(searchReviewID("", "deleted", startDate, endDate)).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
    getSavedGeneralCategories().then((res) => {
      if (res.success) {
        setGeneralLoad(false);
      } else {
        setGeneralLoad(false);
      }
    });
    getReviewControl(
      true,
      1,
      limit,
      "deleted",
      startDate,
      endDate,
      influencerId,
      gender,
      category,
      subCategory,
      sortBy
    ).then(() => setLoading(false));

    getAllCreator().then((res) => {
      if (res.payload.success) {
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  }, []);

  function onPublish(seletedItem) {
    setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To UnPublish This Review?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: `var(--primary-color)`,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setPublishLoading(true);
        reviewPublish({
          review_id: seletedItem._id,
          status: false,
        })
          .then(() => {
            setPublishLoading(false);
            notification.success({
              message: "Successfully UnPublished",
              className: "toast-success",
            });
            setLoading(true);
            getReviewControl(
              true,
              1,
              limit,
              "deleted",
              startDate,
              endDate,
              influencerId,
              gender,
              category,
              subCategory,
              sortBy
            ).then(() => setLoading(false));
          })
          .catch((err) => {
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }
  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  }


  const handleReviewID = (e) => {
    setReviewIDLoad(true)
    dispatch(searchReviewID(e.replaceAll("-", ''), "deleted", startDate, endDate)).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
  }
  function renderContent() {
    if (!loading) {
      return (
        <Row className="post-analytics-tab-boxes-ift influencer-reviews-main">
          {getReviewControlData.data.length > 0 ? (
            getReviewControlData.data.map((item, i) => (
              <Col xs={12} xl={3} md={6}>
                <div
                  key={i}
                  className={`card any_bx analytic-box campaign-box pb-0`}
                >
                  <div className="camp-row row">
                    <div className="campaign-header review-control-box col-12">
                      {/* <h6>
                        <span className="review-box-header mr-2">
                          {item?.influencer?.profile_image_url ?
                            <Avatar
                              icon={<UserOutlined />}
                              src={item?.influencer?.profile_image_url}
                            />
                            :
                            <Avatar
                              icon={<UserOutlined />}
                            />
                          }

                        </span>

                        <div className="inf-username-box">
                          {item?.influencer?.instagram_username ?
                            <a
                              className="inf-username"
                              style={{ color: "$primary-color", fontSize: 14, fontWeight: "bold" }}
                              target="_blank"
                              href={`https://www.instagram.com/${item?.influencer?.instagram_username}`}
                              title={item?.influencer?.instagram_username}
                            >
                              {item?.influencer?.instagram_username.length > 20
                                ? item?.influencer?.instagram_username.slice(0, 20) + "..."
                                : item?.influencer?.instagram_username}
                            </a>
                            :
                            <span
                              className="inf-username"
                              title={item?.influencer?.name}
                            >
                              {item?.influencer?.name}
                            </span>
                          }
                          <span className="inf-email">
                            {item?.influencer?.email?.length > 24
                              ? item?.influencer?.email.slice(0, 24) + "..."
                              : item?.influencer?.email}
                          </span>

                        </div>
                      </h6> */}

                      <div className="inf-header">
                        {/* <span className="review-box-header mr-2">
                          {item?.influencer?.profile_image_url ? (
                            <Avatar
                              icon={<UserOutlined />}
                              src={item?.influencer?.profile_image_url}
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </span> */}
                        <div className="inf-username-box">
                          {item?.influencer?.name ? (
                            <a
                              title={item?.influencer?.name}
                              className="inf-username"
                              target="_blank"
                            // href={`https://www.instagram.com/${item?.influencer?.name}`}
                            >
                              {item?.influencer?.name.length > 20
                                ? item?.influencer?.name.slice(0, 20) + "..."
                                : item?.influencer?.name}
                            </a>
                          ) : item?.influencer?.insta_handle ? (
                            <a
                              title={item?.influencer?.insta_handle}
                              className="inf-username"
                              target="_blank"
                              href={`https://www.instagram.com/${item?.influencer?.insta_handle}`}
                            >
                              @
                              {item?.influencer?.insta_handle?.length > 20
                                ? item?.influencer?.insta_handle.slice(0, 20) +
                                "..."
                                : item?.influencer?.insta_handle}
                            </a>
                          ) : (
                            <a
                              title={item?.influencer?.ig_handle}
                              className="inf-username"
                              target="_blank"
                              href={`https://www.instagram.com/${item?.influencer?.ig_handle}`}
                            >
                              @
                              {item?.influencer?.ig_handle?.length > 20
                                ? item?.influencer?.ig_handle.slice(0, 20) +
                                "..."
                                : item?.influencer?.ig_handle}
                            </a>
                            // <span
                            //   className="inf-username"
                            //   title={item?.influencer?.name}
                            // >
                            //   {item?.influencer?.name}
                            // </span>
                          )}
                          {/* <span className="inf-email">{item?.influencer?.email}</span> */}
                          {/* <span className="inf-email">Followers: 10</span> */}
                        </div>
                        {/* <div className="inf-head-followers">
                          <GroupIcon className="fol-icon" />
                          <h5>
                            {item?.influencer?.followers
                              ? numberFormat(item?.influencer?.followers)
                              : 0}
                          </h5>
                        </div> */}
                      </div>

                      {/* <div className="category-text-main">
                        <div className="category-text">
                          {item?.category?.category_name}
                        </div>
                      </div> */}
                    </div>
                    <div
                      className="any-post-img-col col-12"
                    // style={{ marginBottom: 50 }}
                    >
                      <div className="any-post-image media-post-new">
                        <div className="any-image-box">
                          <div className="any-image-box-iner review-video">
                            <div className="review-video">
                              <ReactPlayer
                                playing
                                // className={`review_react_player ${item?.media_url?.split(".")[
                                //   item?.media_url?.split(".")?.length - 1
                                // ] === "m3u8" && "objectFit"
                                //   }`}
                                // className="review_react_player objectFit"
                                // className={`review_react_player ${item?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                                className={`review_react_player`}
                                width={"100%"}
                                height={"250px"}
                                url={item?.media_url}
                                controls
                                light={item?.banner}
                                playsinline
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="inf-content-area mb-10">
                      {/* <div className='event-view-sale-info'>
                        <div className='event-views-sales-box'>
                          <div className='views-sales-hd'>
                            <FontAwesomeIcon
                              className="event-icon"
                              icon={faEye}
                            />
                          </div>{numeral("11250").format("0.00a")}</div>
                        <div className='event-views-sales-box'>
                          <div className='views-sales-hd'>
                            <FontAwesomeIcon
                              className="event-icon"
                              icon={faDollar}
                            />
                          </div>{numeral("11250").format("0.00a")}</div>
                      </div> */}

                      <div className="d-flex justify-content-between">
                        <div className="event-date-main top-date">
                          <div className="event-date">
                            <FontAwesomeIcon
                              className="event-icon"
                              icon={faCalendarDays}
                            />
                            <div className="event-txt">
                              {moment(item?.updated_at)
                                .utc()
                                .format("MM-DD-YYYY")}
                            </div>
                          </div>

                          <div className="event-date">
                            <FontAwesomeIcon
                              className="event-icon"
                              icon={faClock}
                            />

                            <div className="event-txt">
                              {moment(item?.updated_at).format("hh:mm A")}
                            </div>
                          </div>
                        </div>

                        <div className="category-text-main">
                          {item?.gen_category?.name && (
                            <div className="category-text">
                              {item?.gen_category?.name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="campaign-header review-control-box col-12 p-0">
                        <h5 title={item.title} className="btm-title">
                          {item.title.length > 50
                            ? item.title.slice(0, 50) + "..."
                            : item.title}
                        </h5>
                      </div>
                      {item?.review_counter && <p className="mt-2 mb-0"><span className="fw-bold">Review ID: </span> {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="col-md-12">
              <NoDataFound />
            </div>
          )}
        </Row>
      );
    } else {
      return <LoadingSpin />;
    }
  }

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    getReviewControl(
      true,
      page + 1,
      limit,
      "deleted",
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      influencerId,
      gender,
      category,
      subCategory,
      sortBy
    ).then(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const page = e.selected;
    setCurrentPage(0);
    setLoading(true);
    setReviewIDLoad(true)
    dispatch(searchReviewID("", "deleted", moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
    getReviewControl(
      true,
      1,
      limit,
      "deleted",
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      influencerId,
      gender,
      category,
      subCategory,
      sortBy,
      "",
      reviewID
    ).then(() => {
      setLoading(false);
      setInfluencer("");
    });
  };

  const handleReset = () => {
    setReviewIDLoad(true)
    dispatch(searchReviewID("", "deleted", fromDate, toDate)).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
    setInfluencerId();
    setGender(null);
    setCategory(null);
    setSubCategory(null);
    setSortBy("date");
    // setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setStartDate(fromDate);
    // setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    setEndDate(toDate);
    setLoading(true);
    setReviewID(null)
    childRef.current?.clear();
    getReviewControl(
      true,
      1,
      limit,
      "deleted",
      fromDate,
      // moment(new Date()).format("YYYY-MM-DD"),
      toDate,
      "",
      "",
      "",
      "",
      "date"
    ).then(() => {
      setLoading(false);
      setInfluencer("");
      setGender(null);
      setCategory(null);
      setSubCategory(null);
    });
  };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectInfluencer = (value) => {
    setInfluencerId(value);
  }
  const getInfluencer = (influencerData) => {
    setInfluencerId(influencerData);
  };

  const handleGender = (e) => {
    setGender(e);
    setCategory(null);
    setSubCategory(null);
    setCatLoad(true);
    getSavedCategories(e).then((res) => {
      if (res.success) {
        setCatLoad(false);
      } else {
        setCatLoad(false);
      }
    });
  };
  const handleCategory = (e) => {
    setCategory(e);
    setSubCategory(null);
    setSubCatLoad(true);
    getSavedSubCategories(gender, e).then((res) => {
      if (res.success) {
        setSubCatLoad(false);
      } else {
        setSubCatLoad(false);
      }
    });
  };

  const handleSubCategory = (e) => {
    setSubCategory(e);
  };
  const handleSortBy = (value) => {
    setSortBy(value);
  };

  const handleCreator = (value, option) => {
    setInfluencerId(value);
  };

  return (
    <div className="container-fluid p-0">
      <div className="row row w-20-desktop-main w-25-desktop-main">
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Start Date / End Date</p>
          <RangePicker
            size="large"
            className="w-100"
            key={4}
            value={
              startDate && endDate ? [moment(startDate), moment(endDate)] : []
            }
            allowClear={false}
            ranges={{
              "All Time": [
                moment().startOf("year"),
                moment(),
              ],
              "Last Year": [
                moment().subtract(1, 'year').startOf("year"),
                moment().subtract(1, 'year').endOf("year"),
              ],
              "YTD": [moment().startOf("year"), moment()],
              "Last 30 Days": [moment().subtract(30, "days"), moment()],
              "Last 7 Days": [moment().subtract(7, "days"), moment()],
              "Today": [moment(), moment()],
              // Today: [moment(), moment()],
              // Tomorrow: [
              //   moment().add(1, 'days'),
              //   moment().add(1, 'days'),
              // ],
              // Yesterday: [
              //   moment().subtract(1, 'days'),
              //   moment().subtract(1, 'days'),
              // ],
              // "Last Year": [
              //   moment().startOf("year"),
              //   moment().subtract(1, "year").endOf("year"),
              // ],
              // "This Year": [moment().startOf("year"), moment().endOf("year")],
              // "Last Month": [
              //   moment().subtract(1, "month").startOf("month"),
              //   moment().subtract(1, "month").endOf("month"),
              // ],
              // "This Month": [
              //   moment().startOf("month"),
              //   moment().endOf("month"),
              // ],
            }}
            format={dateFormat}
            onChange={dateRangePickerChanger}
          />
        </div>

        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Department</p>
          <Select
            size="large"
            className="w-100"
            key={4}
            placeholder="Select Department"
            focus={false}
            onChange={handleGender}
            value={gender}
            onBlur={false}
            loading={generalLoad}
            disabled={!savedGeneralCategories || generalLoad}
          >
            {savedGeneralCategories &&
              savedGeneralCategories?.message?.map((item, i) => {
                return <Option value={item?.category_id}>{item?.name}</Option>;
              })}
          </Select>
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Category</p>

          <Select
            size="large"
            className="w-100"
            key={4}
            placeholder="Select Category"
            focus={false}
            onChange={handleCategory}
            value={category}
            onBlur={false}
            loading={catLoad}
            disabled={!savedCategory.success || catLoad || !gender}
          >
            <Option value="all">{"All"}</Option>
            {savedCategory &&
              savedCategory?.message?.map((item, i) => {
                return (
                  <Option value={item?.category_id}>
                    {item?.category_name}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Sub Category</p>
          <Select
            size="large"
            className="w-100"
            key={4}
            placeholder="Select Sub Category"
            focus={false}
            onChange={handleSubCategory}
            value={subCategory}
            onBlur={false}
            loading={subCatLoad}
            disabled={
              !savedSubCategory?.success || subCatLoad || !gender || !category
            }
          >
            <Option value="">{"All"}</Option>
            {savedSubCategory &&
              savedSubCategory?.message?.map((item, i) => {
                return (
                  <Option value={item?.sub_category_id}>
                    {item?.sub_category_name}
                  </Option>
                );
              })}
          </Select>
        </div>

        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Creator</p>
          {/* <div className="select-inf-field d-flex flow-row">
          <AsyncInfluencer
            ref={childRef}
            getInfluencer={getInfluencer}
            influencer={influencer}
          />
          </div> */}
          <Select
            showSearch
            name="sort"
            size="large"
            placeholder="Select Creator"
            className="w-100 select-style dark-field"
            optionFilterProp="children"
            onChange={(value) => selectInfluencer(value)}
            // onSearch={onSearch}
            value={influencerId}
            filterOption={filterOption}
            options={getAllInfluencers?.message && [
              ...getAllInfluencers?.message].map((influencer) => {
                return ({
                  label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  value: influencer?.user_id
                }
                )
              })
            }
          />
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Review ID</p>
          <Select
            showSearch
            size="large"
            className="w-100"
            key={4}
            type="Number"
            notFoundContent={reviewIDLoad && <Spin size="small" />}
            loading={reviewIDLoad}
            optionFilterProp="children"
            placeholder="Enter Review ID"
            labelInValue
            onChange={(e) => setReviewID(e?.value?.[0] + "-" + e?.value?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-'))}
            optionLabelProp="label"
            onSearch={handleReviewID}
            filterOption={(input, option) => {
              const rawValue = option.value;
              return rawValue.toLowerCase().includes(input.toLowerCase());
            }}
            focus={false}
            value={reviewID}
            onBlur={false}
          >
            {searchReviewIDs && !reviewIDLoad &&
              searchReviewIDs?.map((item, i) => {
                return (
                  <Option value={item?.review_counter}>
                    {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Sort By</p>
          <Select
            className="w-100 select-style dark-field"
            placeholder="Select Sort By"
            size="large"
            // focus={false}
            // onBlur={false}
            onChange={handleSortBy}
            value={sortBy}
          >
            <Option value="date">Date</Option>
            <Option value="follower">Followers</Option>
            <Option value="views">Views</Option>
            <Option value="sales">Sales</Option>
            <Option value="likes">Likes</Option>
          </Select>
        </div>

        <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
          <Button
            onClick={handleSubmit}
            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Search
          </Button>

          <Button
            onClick={handleReset}
            className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Reset
          </Button>
        </div>
      </div>
      <hr className="separator-line mt-0" />
      {renderContent()}
      {getReviewControlData?.data?.length > 0 && !loading && (
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          pageClassName="page-item "
          pageLinkClassName="page-link custom-paginate-link btn btn-primary"
          previousClassName="page-item"
          previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
          nextClassName="page-item"
          nextLinkClassName="page-link custom-paginate-next btn btn-primary"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
          pageCount={Math.ceil(getReviewControlData.total_count / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
          onPageChange={handlePageClick}
          containerClassName={
            "pagination justify-content-center mt-2 custom-paginate review-paginate"
          }
          activeClassName={"active"}
        />
      )}
    </div>
  );
}
function mapStateProps({
  getReviewControlData,
  getAllCreatorData,
  savedGeneralCategories,
  savedCategory,
  savedSubCategory,
}) {
  return {
    getReviewControlData,
    getAllCreatorData,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
  };
}
export default connect(mapStateProps, {
  ...influencerReviewAction,
  ...savedCategoriesAction,
})(UgcDeleted);
