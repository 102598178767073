import {
  GET_STORE_SETUP_REQUEST,
  GET_STORE_SETUP_SUCCESS,
  GET_STORE_SETUP_ERROR,
  SAVE_STORE_SETUP_REQUEST,
  SAVE_STORE_SETUP_SUCCESS,
  SAVE_STORE_SETUP_ERROR,
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';


export const getStoreSetup = (event_id) => (dispatch) => {
  dispatch({ type: GET_STORE_SETUP_REQUEST });
  return axios({
    method: 'GET',
    url: `users/receive/getfeestructure`,
    data: { event_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_STORE_SETUP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_STORE_SETUP_ERROR,
        payload: error.response,
      });
    });
};

export const saveStoreSetup = (brand) => (dispatch) => {
  dispatch({ type: SAVE_STORE_SETUP_REQUEST });
  return axios({
    method: 'POST',
    url: `users/revise/shopOnOff`,
    data: { brand },
  })
    .then((response) => {
      return dispatch({
        type: SAVE_STORE_SETUP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_STORE_SETUP_ERROR,
        payload: error.response,
      });
    });
};
