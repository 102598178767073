import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Spin, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "../../css/login.scss";
import logo from "../../images/ormelogo.svg";
import { postLogin } from "../../redux/actions/login.action";
import axios from "axios";
import { BASEURL } from "../../config";
import { connnectSellerCenter, getProducts, postSellerCenter } from "../../redux/actions/shopify.action";
import { store } from "../../redux/store";
import { AGREEMENT, AUTHENTICATE, RESET_INSTA_STATE, CONNECT_STRIPE } from "../../redux/types/types";
import { getAgreementPDFLink } from "../../redux/actions/agreement.action";
import * as jose from 'jose'
export default function Login({ setHit }) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [spinner, setSpin] = useState(false);
  const { login, validate, agreementMsg } = useSelector((state) => {
    return state;
  });

  const [error, setError] = useState("");
  const [sidLoading, setSidLoading] = useState(false);

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);

  const notificationKey = `open${Date.now()}`;
  const load = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are syncing</p></div>;
  const loadCustomProducts = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are uploading</p></div>;


  useEffect(() => {
    document.body.classList.add("login-page");
    return () => {
      document.body.classList.remove("login-page");
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("sid") !== null) {
      getSidUrl(searchParams.get("sid"));
    }
    if (setHit) {
      setHit(false)
    }

  }, []);


  const getSidUrl = async (sid) => {
    setSidLoading(true);
    await axios
      .post(`${BASEURL}signin/session`, { sid: sid })

      .then((res) => {
        localStorage.setItem("token", JSON.stringify(res?.message?.token));
        history("/login");
      })
      .catch((err) => {
        notification.error({
          message: err.response.data.message,
          className: "toast-error",
        });
        setTimeout(() => {
          window.location.replace("https://www.ormelive.com/");
        }, 1000);
      });
  };
  const onFinish = (values) => {
    setSpin(true);
    dispatch(postLogin(values)).then(async (res) => {
      if (res?.success) {


        if (res?.message?.agreement === "waiting" || res?.message?.agreement === "rejected") {
          localStorage.setItem("token", JSON.stringify(res?.message?.token));
          history("/agreement")
          store.dispatch({ type: AGREEMENT, payload: {} })
          setError("");
        }
        else if (res?.message?.is_stripe_account_connected === false && res?.message?.contract?.is_stripe_connect_user) {
          localStorage.setItem("token", JSON.stringify(res?.message?.token));
          history("/connect-stripe")
          setError("");
        }
        else if (res?.message?.seller_center) {
          let data = {
            shopName: res?.message?.seller_center?.shop_name,
            apiKey: res?.message?.seller_center?.api_key,
          }
          localStorage.setItem("token", JSON.stringify(res?.message?.token));

          let payload = {
            brand_id: res?.message?._id,
            brand_name: res?.message?.brand_name,
            pixel_id: res?.message?.pixel_id,
          }
          const secret = new TextEncoder().encode(
            process.env.REACT_APP_SELLER_CENTER_JWT,
          )
          const alg = 'HS256'

          const jwt = await new jose.SignJWT(payload)
            .setProtectedHeader({ alg })
            .setIssuedAt()
            // .setIssuer('urn:example:issuer')
            // .setAudience('urn:example:audience')
            .setExpirationTime('120s')
            .sign(secret)


          dispatch(connnectSellerCenter(jwt, res?.message?.seller_center?.api_key)).then((response) => {
            if (response?.payload?.success) {
              console.log(response?.payload, 'response?.payload seller center');
              localStorage.setItem("sc_token", JSON.stringify(response?.payload?.token))
            }

          })
        }

        // else if (res?.message?.agreement === "rejected") {
        //     history("/login")
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("Role");
        //     store.dispatch({type:AUTHENTICATE, payload: {}})
        //     store.dispatch({ type: RESET_INSTA_STATE });

        // }
        else {
          localStorage.setItem("token", JSON.stringify(res?.message?.token));
          history("/login");
          setError("");
          // window.location.reload();
          // notification.open({
          //   message: 'Product Syncing...',
          //   description: load,
          //   key: notificationKey,
          //   duration: 0,
          // })
          // dispatch(getProducts(res?.message?.token))
          //   .then((res) => {
          //     if (res?.type === "GET_PRODUCT_ERROR") {
          //       notification.destroy({ key: notificationKey })
          //       notification.error({
          //         message: res?.payload?.data?.message,
          //         className: "toast-error",
          //       });
          //     } else {
          //       notification.destroy({ key: notificationKey })
          //       notification.success({
          //         message: res?.payload?.message,
          //         className: "toast-success",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     notification.destroy({ key: notificationKey })
          //     notification.error({
          //       message: "Sync Failed",
          //       className: "toast-error",
          //     });
          //     console.log(err.response, "err");
          //   })
          setSpin(false);
          store.dispatch({ type: AGREEMENT, payload: {} })
          // window.location.reload();
        }
      } else {
        setSpin(false);
        setError(res?.response?.data?.message.replace("ormelive", "ORME"));
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (!sidLoading) {
    return (
      <>
        <div className="login-main">
          <div className="login-left">
            <div className="login-left-content">
              <div className="intro-main">
                <h1>Introducing</h1>
                <h2>LIVE SHOPPING</h2>
                <p>
                  Boost your sales, create authentic and engaging relationships
                  with your customers, in an innovative way!
                </p>
              </div>
            </div>
          </div>
          <div className="login-right">

            <div className="login-right-content form-styling">
              <div className="logo-login">
                <Link to="/">
                  {" "}
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <h2 className="login-hd">Login</h2>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                  ]}
                >
                  <Input size="large" placeholder="example@email.com" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="password" />
                </Form.Item>

                <p className="ant-form-item-explain-error text-center">
                  {error}
                  {agreementMsg?.message}
                </p>
                <div className="d-flex justify-content-between flex-row">
                  <div>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </div>
                  <div className="mb-10 text-right">
                    <Form.Item className="flex mb-0">
                      {/* <a className="login-form-forgot float-right" href="/forgot-password">
                  Forgot password
                </a> */}
                      <div className="d-flex flex-column">
                        <Link
                          to="/forgot-password"
                          className="login-form-forgot float-right"
                        >
                          Forgot password
                        </Link>
                      </div>
                    </Form.Item>
                    {/* <div>
                      New to ORME?{" "}
                      <Link className="signin-link" to="/register">
                        Register
                      </Link>
                    </div> */}
                  </div>
                </div>

                <Form.Item>
                  <Spin spinning={spinner}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="default-btn login-btn"
                    >
                      Login
                    </Button>
                  </Spin>
                </Form.Item>
              </Form>
              {/* New to ORME?{' '}
              <Link className="signin-link" to="/register">
                Create an Account
              </Link> */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}
