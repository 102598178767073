import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStepsForm } from 'sunflower-antd';

import AsyncProduct from '../create-event/scheduleEvent/asyncProduct';
import {
    InboxOutlined,
    UploadOutlined,
    PlusOutlined,
    CalendarFilled,
    DeleteFilled
} from '@ant-design/icons';
import numeral from 'numeral';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postEvent } from '../../redux/actions/event.action';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getParentCategories } from '../../redux/actions/category.action';
import { getRefreshProd } from '../../redux/actions/refreshProducts.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultImage from "../../images/user.png";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image as ImageA } from "@react-pdf/renderer";

import {
    getEventsBy_Id,
    UpdateEvents,
    getShowBy_Id,
    UpdateShows
} from '../../redux/actions/event.action';
import {
    faAngleLeft,
    faCalendarPlus,
    faCaretRight,
    faShare,
    faTriangleExclamation,
    faXmark,
    faVideo,
    faUser,
    faUserTie,
    faEye,
    faAngleRight,
    faDownload
} from '@fortawesome/free-solid-svg-icons';
import {
    Steps,
    Input,
    Button,
    Form,
    Result,
    Tabs,
    Space,
    DatePicker,
    TimePicker,
    Upload,
    message,
    notification,
    Select,
    Spin,
    Checkbox,
    Image,
    Affix,
    Modal,
    Table,
    Switch,
    Tag,
    Empty,
    Popover,
    AutoComplete
} from 'antd';
import moment from 'moment';
import useMediaQuery from '../../reUsable/useMediaQuery';
import { set } from 'lodash';
import LoadingSpin from '../../reUsable/loadingSpin';
import { disableSku, getInventory, searchActiveSkus, replaceSkus, sellOutSku, searchDesc, getLinkedVideos } from '../../redux/actions/inventoryActive.action';
import ReactPaginate from 'react-paginate';
import { searchEventID, searchReviewID, searchShowID } from '../../redux/actions/eventID.action';
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from '../../redux/actions/newCategory.action';
const type = 'DraggableBodyRow';
var total = 0;

const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

export default function Active(props) {
    const location = useLocation();
    const matches = useMediaQuery('(min-width: 1024px)');
    const deadline = '2018-12-20T07:37:50.886Z';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [streamModal, setIsModalOpenStream] = useState(false);
    const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
    const [isCheckedOpen, setIsCheckedOpen] = useState(false);
    const [dataDetails, setDataDetail] = useState([]);
    const [referralPercent, setReferralPercent] = useState("0");
    const [discount, setDiscount] = useState("");
    const [influencer_percent, setInfluencer_percent] = useState("0");
    const [promo, setPromo] = useState("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [flag, setFlag] = useState(false);
    const [defaultBanner, setDefaultBanner] = useState(false);
    const [videoRatio, setRatio] = useState("16:9");
    const [fileList, setFileList] = useState([]);
    const [contentType, setContentType] = useState("");
    const [promptModal, setPromptModal] = useState(true);
    const [vidMsg, setVidMsg] = useState(false);
    const [multipleHosts, setMultipleHosts] = useState(false);
    const [recURL, setRecURL] = useState("");
    const [infoTitle, setInfoTitle] = useState("");
    const [infoDesc, setInfoDesc] = useState("");
    const [platform, setPlatform] = useState(false);
    const [streamUrl, setStreamUrl] = useState("local");
    const [externalUrl, setExternalUrl] = useState("");
    const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
    const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
    const [orientation, setOrientation] = useState("");
    const [flagg, setFlagg] = useState(false);
    const [fields, setFields] = useState({
        image: "",
    });
    const [imageThumb, setImageThumb] = useState("");
    const [ext, setExt] = useState({});
    const [sort, setSort] = useState('');
    const [form] = Form.useForm();
    const dateFormat = "MM-DD-YYYY";
    const { RangePicker } = DatePicker;
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    const [startDate, setStartDate] = useState(min_date);
    const [endDate, setEndDate] = useState(max_date);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [gender, setGender] = useState("All");
    const [category, setCategory] = useState("All");
    const [subCategory, setSubCategory] = useState("All");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [genLoading, setGenLoading] = useState(true);
    const [actionChange, setActionChange] = useState(false);
    const [skuNewArr, setSkuNewArr] = useState([]);
    const [newData, setNewData] = useState([]);
    const [viewIndex, setViewIndex] = useState();
    const [tempSKU, setTempSKU] = useState();
    const [sortOn, setSortOn] = useState("inventory");
    const [sortBy, setSortBy] = useState("desc");

    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
    const [isLinkModalLoader, setIsLinkModalLoader] = useState(false);
    const [videoListTitle, setVideoListTitle] = useState('events');
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [linkDataDetails, setLinkDataDetail] = useState([]);

    const showModal = (s) => {
        console.log(s, "----");
        setDataDetail(s);
        setIsModalOpen(true);
    };

    const callbackTabClicked = (key, event) => {
        setVideoListTitle(key);
        setPagination({ current: 1, pageSize: 10 })
    };

    const showLinkModal = (item) => {
        setIsLinkModalLoader(true)
        setIsLinkModalOpen(true);
        // Api placed
        dispatch(getLinkedVideos(item?._id)).then((res) => {
            if (res?.success) {
                setIsLinkModalLoader(false)
                setLinkDataDetail(res?.message); // set response in this state
            }
            else {
                setIsLinkModalLoader(false)
            }
        })
    };

    const PDF = ({ item }) => {
        const styles = StyleSheet.create({
            page: {
                padding: 30,
            },
            container: {
                display: "flex",
                // flex: 1,
                flexDirection: 'row',
            },
            image: {
                marginBottom: 10,
            },
            marginBottom: {
                marginBottom: 10,
            },
            leftColumn: {
                flexDirection: 'column',
                width: 170,
                paddingTop: 30,
                paddingRight: 15,
            },
            footer: {
                fontSize: 12,
                textAlign: 'center',
                marginTop: 15,
                paddingTop: 5,
                borderWidth: 3,
                borderColor: 'gray',
                borderStyle: 'dashed',
                '@media orientation: landscape': {
                    marginTop: 10,
                },
            },
            headerarea: {
                flexDirection: 'row',
                borderBottomWidth: 2,
                borderBottomColor: '#112131',
                borderBottomStyle: 'solid',
                alignItems: 'stretch',
            },
            detailColumn: {
                flexDirection: 'column',
                flexGrow: 9,
                textTransform: 'uppercase',
            },
            linkColumn: {
                flexDirection: 'column',
                flexGrow: 2,
                alignSelf: 'flex-end',
                justifySelf: 'flex-end',
            },
            hname: {
                fontSize: 16,
            },
            rightContainer: {
                // flex: 1,
                flexDirection: 'column',
                paddingLeft: 15,
                marginBottom: 10,
                paddingTop: 10,
            },
            text: {
                fontSize: 12,
                color: 'black',
            },
            headerContainer: {
                marginTop: 30,
                width: 380
            },
            date: {
                fontSize: 10,
                color: 'black',
                textDecoration: 'none',
                alignSelf: 'flex-end',
                justifySelf: 'flex-end',
            },
            table: {
                display: 'table',
                width: 'auto',
                borderStyle: 'solid',
                borderWidth: 1,
                borderRightWidth: 0,
                borderBottomWidth: 0,
            },
            table: {
                display: 'table',
                width: 'auto',
                borderStyle: 'solid',
                borderWidth: 1,
                borderRightWidth: 0,
                borderBottomWidth: 0,
            },
            tableRow: {
                margin: 'auto',
                flexDirection: 'row',
            },
            tableCellHead: {
                display: "flex",
                flexDirection: 'row',
                flexWrap: "wrap",
                // justifyContent: "flex-start",
                textAlign: "left",
                fontSize: 12,
                color: 'black',
                paddingLeft: 15,
            },
            tableCell: {
                margin: 'auto',
                marginTop: 5,
                marginLeft: 5,
                fontSize: 10,
                padding: 3,
                borderWidth: 1,
                borderStyle: 'solid',
                justifyContent: "flex-start",
                textAlign: "left"
            },
            rowView: {
                display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8, paddingBottom: 8, textAlign: "left", fontSize: 10,
            }
        });
        const Table = ({ data }) => (
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>ID</Text>
                    <Text style={styles.tableCell}>Name</Text>
                    <Text style={styles.tableCell}>Age</Text>
                </View>
                {/* {data.map(item => (
              <TableRow {...item} />
            ))} */}
            </View>
        );
        const TableRow = ({ id, name, age }) => (
            <View style={styles.tableRow} key={id}>
                <Text style={styles.tableCell}>{id}</Text>
                <Text style={styles.tableCell}>{name}</Text>
                <Text style={styles.tableCell}>{age}</Text>
            </View>
        );
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.headerarea}>
                        <View style={styles.detailColumn}>
                            <Text style={styles.hname}>Worksheet - {item?.variants[0]?.sku} - {item?.title}</Text>
                        </View>
                        {/* <View style={styles.linkColumn}>
                  <Text style={styles.date}>{moment(item?.created_at).utc().format("MM-DD-YYYY")}</Text>
                </View> */}
                    </View>

                    <View style={styles.container}>
                        <View style={styles.leftColumn}>
                            <ImageA
                                src={item?.mediaUrl}
                                style={styles.image}
                            />
                        </View>
                        <View style={styles.headerContainer}>
                            <View style={styles.rightContainer}>
                                <Text style={styles.text}>
                                    Product Title : {item?.title}
                                </Text>
                            </View>
                            <View style={styles.rightContainer}>
                                <Text style={styles.text}>Product ID : {item?.productId}</Text>
                            </View>
                            <View style={styles.rightContainer}>
                                <Text style={styles.text}>Product SKU : {item?.ProductSku}</Text>
                            </View>
                            <View style={styles.rightContainer}>
                                <Text style={styles.text}>Product Link :  {item?.ProductUrl}</Text>
                            </View>
                            <View style={styles.rightContainer}>
                                <Text style={styles.text}>Product Price : ${item?.price}</Text>
                            </View>
                            {
                                item && item?.productDesc?.length > 0 &&
                                <View style={styles.rightContainer}>
                                    <Text style={styles.text}>Product Description : {item && item?.productDesc
                                        ? item?.productDesc
                                        : ""}</Text>
                                </View>}
                            {
                                item && item?.tags?.length > 0 &&
                                <View style={styles.rightContainer}>
                                    <Text style={styles.text}>Product Tags : </Text>
                                </View>}
                            {
                                item && item?.tags?.length > 0 &&
                                <View style={styles.tableCellHead}>
                                    {item && item?.tags?.split(",").map((e, i) => {
                                        return <Text style={styles.tableCell}>{e}</Text>
                                    })}
                                </View>}
                        </View>
                    </View>


                    {/* <Table data={item?.options} /> */}

                    <View style={styles.marginBottom}>
                        <Text style={styles.text}>
                            Variants
                        </Text>
                    </View>
                    <View style={styles.rowView}>
                        <Text style={{ width: '50' }}>S.#</Text>
                        {item?.productOpt?.map((it, i) => {
                            return (
                                <Text style={{ width: '150' }}>{it.name}</Text>
                            );
                        })}
                        <Text style={{ width: '150' }}>Stock</Text>
                        <Text style={{ width: '150' }}>Price</Text>
                    </View>
                    {item?.variants.map((rowData, index) => <>
                        <View style={styles.rowView}>
                            <Text style={{ width: '50' }}>{index + 1}</Text>
                            {rowData.option1 && <Text style={{ width: '150' }}>{rowData.option1}</Text>}
                            {rowData.option2 && <Text style={{ width: '150' }}>{rowData.option2}</Text>}
                            {rowData.option3 && <Text style={{ width: '150' }}>{rowData.option3}</Text>}
                            <Text style={{ width: '150' }}>{rowData.inventory_quantity}</Text>
                            <Text style={{ width: '150' }}>${rowData.price}</Text>
                        </View>
                    </>)}

                </Page>
            </Document>
        );
    };

    const [top, setTop] = useState(30);

    const { TextArea } = Input;
    const dispatch = useDispatch();
    const history = useNavigate();
    const { parentCategory, refreshProd, soldOutInventory, savedGeneralCategories,
        savedCategory,
        savedSubCategory } = useSelector((state) => {
            return state;
        });

    const [spinner, setSpin] = useState(true);
    const [dateTime, setDate] = useState('');
    const [formState, setFormState] = useState('add');
    const [sku, setSku] = useState([]);
    const [banner, setBanner] = useState([]);
    const [host, setHost] = useState([]);
    const [hostError, setHostError] = useState(false);
    const [host2, setHost2] = useState([]);
    const [hostError2, setHostError2] = useState(false);
    const [host3, setHost3] = useState([]);
    const [hostError3, setHostError3] = useState(false);
    const [host4, setHost4] = useState([]);
    const [hostError4, setHostError4] = useState(false);
    const [skuError, setSkuError] = useState(false);
    const [parent, setParent] = useState('');
    const [imageUpload, setImageUpload] = useState(false);
    const [sampleBanner, setSampleBanner] = useState(false);
    const [note, setEventNote] = useState('');
    const [catgId, setCategoryId] = useState('');
    const [thankyou, setEventThankyou] = useState('');
    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(1);
    const [previewImage, setPreviewImage] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [removeSku, setRemoveSku] = useState('');
    const [replace, setReplace] = useState(false);
    const [replaceIndex, setReplaceIndex] = useState("");
    const [skuArr, setSkuArr] = useState([]);
    const [replaceSku, setReplaceSku] = useState([]);
    const [searchID, setSearchID] = useState();
    const [searchIDLoad, setSearchIDLoad] = useState("");
    const [searchDataIDs, setSearchDataIDs] = useState([]);
    const [idType, setIdType] = useState("event");
    // const [invSort, setInvSort] = useState("asc");
    const [invSort, setInvSort] = useState("inventoryHighToLow");
    const [skuLoad, setSkuLoad] = useState(false);
    const [filterSKU, setFilterSKU] = useState();
    const [searchSKUs, setSearchSKUs] = useState([]);
    const [replacedSku, setReplacedSku] = useState();
    const [generalLoad, setGeneralLoad] = useState(false);
    const [catLoad, setCatLoad] = useState(false);
    const [subCatLoad, setSubCatLoad] = useState(false);
    const [replaceModal, setReplaceModal] = useState(false);
    const [skuNull, setSkuNull] = useState(false);
    const [replaceID, setReplaceID] = useState("");
    const [open, setOpen] = useState(false);
    const [selectIndex, setSelectIndex] = useState("");

    const [tempSkuField, setTempSkuField] = useState("");
    const [filterSkuField, setFilterSkuField] = useState();
    const [filterTitleSku, setFilterTitleSku] = useState();
    const [searchSkuField, setSearchSkuField] = useState([]);
    const [skuFieldLoader, setSkuFieldLoader] = useState(false);

    const [tempDescField, setTempDescField] = useState("");
    const [filterDescField, setFilterDescField] = useState();
    const [filterDescSku, setFilterDescSku] = useState();
    const [searchDescField, setSearchDescField] = useState([]);
    const [descFieldLoader, setDescFieldLoader] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const { confirm } = Modal;
    const handleSkuValueChange = (value, option) => {
        setFilterSKU(value)
    }

    const handleReplace = () => {
        confirm({
            title: "Are you sure you want to Replace the SKU?",
            content:
                "Replacing it will remove SKU product from Active List and will be moved to Replaced List",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                dispatch(replaceSkus(replaceID, sku)).then((res) => {
                    setSpin(true);
                    if (res?.success) {
                        setSku([])
                        setReplaceModal(false)
                        setFilterTitleSku()
                        setFilterDescSku()
                        setFilterSKU();
                        setFilterSkuField();
                        setFilterDescField();
                        setTempSKU()
                        setTempSkuField()
                        setTempDescField()
                        notification.success({
                            message: 'SKU Product Replaced Successfully',
                            className: 'toast-success',
                        });
                        dispatch(getInventory(pageLimit, currentPage + 1, "soldout", min_date,
                            max_date,
                            "",
                            "",
                            "", "", "inventory")).then((res) => {
                                setSpin(false);
                            })
                    } else {
                        setSpin(false);
                        notification.error({
                            message: res?.message,
                            className: 'toast-error',
                        });
                    }
                }).catch((err) => {
                    setSpin(false)
                })
            }
        })
    }

    useEffect(() => {
        let abc = [];
        // console.log(soldOutInventory, 'soldOutInventory');




    }, [soldOutInventory])

    useEffect(() => {
        setSkuFieldLoader(true)
        const getData = setTimeout(() => {
            dispatch(searchDesc('soldout', tempSkuField, undefined, moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"), gender, category, subCategory)).then((res) => {
                    setSkuFieldLoader(false)
                    if (res?.success) {
                        setSearchSkuField(res?.message)
                    }
                })
        }, 1000)

        return () => clearTimeout(getData)
    }, [tempSkuField])

    const handleSkuChange = (value, option) => {
        setFilterSkuField(value)
        setFilterTitleSku(option?.sku)
    }


    const handleCancel = () => {
        setIsModalOpen(false);
        setDataDetail([])
        // setSku([])
    };

    const handleReplaceCancel = () => {
        setReplaceModal(false)
        // setDataDetail([])
        setSku([])
    };

    const handleLinkVideosCancel = () => {
        setIsLinkModalOpen(false);
        setVideoListTitle('events')
        // setDataDetail([])
        setLinkDataDetail([])
    };

    const params = useParams();
    const id = params.id;

    const getSum = (total, num) => {
        return total + Math.round(num);
    }
    const onToggle = (record, recInd) => {
        setActionChange(true)
        if (record?.is_active == true) {

            record.is_active = false
            let obj = record;
            const key = { ...obj };
            key.is_active = false

            setData(current =>
                current.map((obj, index) => {
                    if (index == recInd) {
                        return { ...obj, is_active: false };
                    }
                    return obj;
                }),
            );

        }
        else {

            record.is_active = true
            let obj = record;
            const key = { ...obj };
            key.is_active = true


            setData(current =>
                current.map((obj, index) => {
                    if (index == recInd) {
                        return { ...obj, is_active: true };
                    }
                    return obj;
                }),
            );
        }
    };
    useEffect(() => {
        if (replace && removeSku) {
            setSkuArr([])
        }
        else if (removeSku) {
            setData(prevArray => [...prevArray, sku])
            setData(data?.filter(avail => { return avail.ProductSku !== removeSku?.ProductSku }))
        } else if (replace) {
            if (skuArr.length > 0) {
                notification.error({
                    message: 'One SKU Product Already Added',
                    className: 'toast-error',
                });
            } else {
                setSkuArr([replaceSku])
            }
        }
        else if (sku) {
            setData(prevArray => [...prevArray, sku])
        }
    }, [sku, removeSku])

    useEffect(() => {
        if (replace) {
            if (skuArr.length > 0) {
                notification.error({
                    message: 'One SKU Product Already Added',
                    className: 'toast-error',
                });
            } else {
                setSkuArr([replaceSku])
            }
        }
    }, [replaceSku])
    useEffect(() => {
        setSearchIDLoad(true)
        dispatch(searchEventID("", "event", startDate, endDate, true)).then((res) => {
            setSearchIDLoad(false)
            if (res?.success) {

                setSearchDataIDs(res?.message)
            }
        })
        setGeneralLoad(true);
        dispatch(getSavedGeneralCategories()).then((res) => {
            if (res.success) {
                setGeneralLoad(false);
            } else {
                setGeneralLoad(false);
            }
        });
        dispatch(getInventory(pageLimit, currentPage + 1, "soldout", startDate,
            endDate,
            gender,
            category,
            subCategory, filterSKU, sortOn, idType, searchID?.value, sortBy)).then(async (event) => {
                if (event?.success) {
                    setSpin(false);
                    if (location?.state?.inventory === "shows") {
                        let orderData = event.payload?.message[0]?.orders;
                        let productsData = event.payload?.message[0]?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)

                        setSampleBanner(
                            event?.payload?.message[0]?.default_banner ? true : false
                        );
                        setDefaultBanner(
                            event?.payload?.message[0]?.default_banner ? true : false
                        );
                        setRecURL(event?.payload?.message?.[0]?.recording_url);
                        setPreviewImage(event?.payload?.message[0]?.banner);
                        setEventThankyou(event.payload.message[0]?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.title);
                        setEventNote(event.payload.message[0]?.notes);
                        setproductPromoCodePromo(event.payload.message[0]?.promo);
                        setProductPromoCodeDscs(event.payload.message[0]?.discount);
                        setOrientation(event.payload.message[0]?.orientation);

                        setRatio(event.payload.message[0]?.ratio);

                        setPlatform(
                            event.payload.message[0]?.is_facebook_live ? true : false
                        );
                        if (event.payload.message[0]?.is_facebook_live) {
                            setInfoTitle(event.payload.message[0]?.facebook?.title);
                            setInfoDesc(event.payload.message[0]?.facebook?.description);
                        }

                        setReferralPercent(
                            event.payload.message[0]?.referral_percent === ""
                                ? "0"
                                : event.payload.message[0]?.referral_percent
                        );
                        setPromo(
                            event.payload.message[0]?.promo === ""
                                ? "0"
                                : event.payload.message[0]?.promo
                        );
                        setInfluencer_percent(
                            event.payload.message[0]?.influencer_percent === ""
                                ? "0"
                                : event.payload.message[0]?.influencer_percent
                        );
                        setDiscount(
                            event.payload.message[0]?.discount === ""
                                ? "0"
                                : event.payload.message[0]?.discount
                        );
                        setMultipleHosts(
                            event.payload.message[0]?.multiple_hosts
                                ? event.payload.message[0]?.multiple_hosts
                                : false
                        );
                        //setStreamUrl(event.payload.message[0]?.stream_type);
                        setStreamUrl("local");

                        setExternalUrl(event.payload.message[0]?.streaming_url);
                        setDate(moment(moment(event.payload.message[0]?.start_date)));
                        setBanner(event?.payload?.message[0]?.banner);

                        setFormState("edit");
                        setLoad(false);
                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item?.category_id == event?.payload?.message?.[0]?.category_id
                        );
                        setParent(parent?.[0]?.category_name);
                    } else {
                        let orderData = event.payload?.message[0]?.event?.orders;
                        let productsData = event.payload?.message[0]?.event?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)
                        setSampleBanner(
                            event.payload?.message[0]?.event?.default_banner ? true : false
                        );

                        setPreviewImage(event.payload?.message[0]?.event?.banner);
                        setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.event?.title);
                        setEventNote(event.payload.message[0]?.event?.notes);
                        setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
                        setBanner(event.payload?.message[0]?.event?.banner);

                        let primary_data =
                            (await event.payload?.message[0]?.hosts[1]?.primary) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let primary_host = await primary_data.filter(
                            (num) => num.primary === true
                        );

                        let host2_data =
                            (await event.payload?.message[0]?.hosts[2]?.host1) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_2 = await host2_data.filter((num) => num.host1 === true);

                        let host3_data =
                            (await event.payload?.message[0]?.hosts[3]?.host2) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_3 = await host3_data.filter((num) => num.host2 === true);

                        let host4_data =
                            (await event.payload?.message[0]?.hosts[4]?.host3) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_4 = await host4_data.filter((num) => num.host3 === true);

                        setHost(primary_host);
                        setHost2(host_2);
                        setHost3(host_3);
                        setHost4(host_4);
                        setCategoryId(event.payload?.message[0]?.event?.category_id);

                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item.category_id == event.payload?.message[0]?.event?.category_id
                        );
                        setParent(parent[0]?.category_name);
                    }




                }
            });
    }, [id]);



    const inventoryStatus = (record) => {
        let status = record?.variants?.reduce((acc, item) => {
            return (
                acc = acc + item.inventory_quantity
            )
        }, 0)
        console.log(status);
        if (status > 0) {
            return false
        } else {
            return true
        }
    }

    // const getSku = (skuData, removedSku) => {

    //     setRemoveSku(removedSku)


    //     var matchedSku = null;
    //     skuData.forEach(element => {
    //         data.forEach(ele => {
    //             if (element.ProductSku === ele.ProductSku) {

    //                 matchedSku = ele.ProductSku;
    //             }
    //         })
    //     })

    //     setSkuError(false);
    //     setIsCheckedOpen(false)

    //     if (skuData.length > 0) {


    //         if (skuData.length > 0) {
    //             skuData.map((item) => {
    //                 if (!(item.ProductSku == matchedSku) && !replace) {
    //                     setSku(item)
    //                 } else if (!(item.ProductSku == matchedSku) && replace) {
    //                     setReplaceSku(item)
    //                 }

    //             })
    //         }

    //     };
    // }


    const getSku = (skuData) => {
        setSkuError(false);
        setSku(skuData);
    };

    const DisableSku = (id) => {
        confirm({
            title: "Are you sure you want to disable the SKU?",
            content:
                "Disabling it will remove SKU product from Sold Out List and will be moved to Disabled List",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                dispatch(disableSku(id)).then((res) => {
                    setSpin(true);
                    setFilterTitleSku()
                    setFilterDescSku()
                    setFilterSKU();
                    setFilterSkuField();
                    setFilterDescField();
                    setTempSKU()
                    setTempSkuField()
                    setTempDescField()
                    if (res.success) {
                        dispatch(getInventory(pageLimit, currentPage + 1, "soldout", min_date,
                            max_date,
                            "",
                            "",
                            "", "", "inventory")).then((res) => {
                                setSpin(false);
                            })
                        notification.success({
                            message: 'Product Marked Disabled Successfully',
                            className: 'toast-success',
                        });
                    } else {
                        notification.error({
                            message: res.message,
                            className: 'toast-error',
                        });
                    }
                })
            }
        })


    }
    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef(null);
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: (item) => {
                moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: {
                index,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));
        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{
                    cursor: 'move',
                    ...style,
                }}
                {...restProps}
            />
        );
    };
    const content = (record) => {
        return (

            <>
                {
                    !record?.is_disabled &&
                    <a className={` balance-btn default-btn  medium-btn padb-3 ml-`} onClick={() => {
                        {
                            DisableSku(record?._id)
                        }
                    }}
                    >
                        Disable
                    </a>}
                <a className={`ml-2 balance-btn default-btn  medium-btn padb-3 ml-`} onClick={() => {
                    {
                        setSkuNull(true)
                        setReplaceModal(true);
                        setReplaceID(record?._id);
                        setReplacedSku(record)
                    }
                }}
                >
                    Replace
                </a>
                <hr className='mb-2' />
                <div>
                    <Button onClick={() => setOpen(false)} className='h-0 pw-2 default-btn small-btn outline'>Close</Button>
                </div>
            </>
        )
    }
    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: 50,
            render: (text, record, index) => {
                return (
                    <p>
                        {index + 1 + currentPage * 10}
                    </p>
                );
            },
        },
        {
            title: 'SKU',
            dataIndex: 'ProductSku',
            key: 'ProductSku',
            width: 50,
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.gen_category?.name ? record?.gen_category?.name : "All"}
                    </div>
                );
            },
        },

        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.category?.category_name ? record?.category?.category_name : "All"}
                    </div>
                );
            },
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'subCategory',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.sub_category?.sub_category_name ? record?.sub_category?.sub_category_name : "All"}
                    </div>
                );
            },
        },
        {
            title: 'Image',
            dataIndex: 'mediaUrl',
            key: 'mediaUrl',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="listing-image">
                        {/* <img src={`${record?.mediaUrl}`} /> */}
                        <Image
                            src={record?.mediaUrl}
                            rootClassName="no-preview-image"
                        />
                    </div>
                );
            },
        },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="desc-limit" title={record?.title}>
                        {record?.title}
                    </div>
                );
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 50,
            render: (text, record) => {

                return (

                    <div className="desc-limit">
                        ${record?.price}
                    </div>
                );
            },
        },
        {
            title: 'Stock',
            dataIndex: 'event',
            key: 'event',
            width: 100,
            // sortOrder: sort,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => {
            //     let aa = a?.variants?.reduce((acc, item) => {
            //         return (
            //             acc = acc + item.inventory_quantity
            //         )
            //     }, 0);
            //     let bb = b?.variants?.reduce((acc, item) => {
            //         return (
            //             acc = acc + item.inventory_quantity
            //         )
            //     }, 0)
            //     return aa - bb;
            // },
            // onHeaderCell: () => ({
            //     onClick: () => setSort(sort === 'ascend' || sort === '' ? 'descend' :
            //         'ascend'),
            //         sortDirections: ['descend'],
            // }),
            render: (text, record, index) => {
                let status = record?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)
                return (
                    <>
                        <div className='balance-area'>
                            <div className='balance-count'>
                                {status}
                            </div>
                            {
                                // status > 0 ?

                                // :
                                <>
                                    <span className='text-danger fw-bold'>Sold-out</span>
                                    <a className='balance-btn default-btn outline small-btn' onClick={() => {
                                        showModal(record);
                                        setViewIndex(index)
                                    }}
                                    >
                                        View
                                        {/* <FontAwesomeIcon icon={faEye} /> */}
                                    </a>
                                </>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            title: 'Units Sold',
            dataIndex: 'qty_sold',
            key: 'qty_sold',
            width: 80,
            render: (text, record) => {
                return (
                    <div>
                        {numeral(record?.qty_sold).format('0,0')}
                    </div>
                );
            },
        },
        {
            title: 'Sales',
            dataIndex: 'sale',
            key: 'sale',
            width: 50,
            render: (text, record) => {
                return (
                    <div>
                        {numeral(record?.sale).format("$0,0.0'")}

                    </div>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            // fixed: 'right',
            width: 70,
            // sorter: true,
            render: (text, record, index) => {
                let status = record?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)

                // if (status !== 0) {
                return (
                    <>
                        <Popover
                            content={content(record)}
                            title="Select Option"
                            trigger="click"
                            open={open && index === selectIndex}
                            onOpenChange={() => { handleOpenChange(); setSelectIndex(index) }}
                            placement="left"
                        >
                            <Button type="primary" className='default-btn small-btn' >Select</Button>
                        </Popover>
                        {/* // <a className={` action-icons`} onClick={() => { */}

                        {/* <a className={`ml-4 action-icons`} onClick={() => {
                                {
                                    setReplaceModal(true);
                                    setReplaceID(record?._id);
                                }
                            }}
                            >
                                <PublishedWithChangesIcon size={20} />
                            </a> */}
                    </>
                )
                // }
            },
        }



    ];

    const replaceColumns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: 50,
            render: (text, record, index) => {
                return (
                    <p>
                        {index + 1 + currentPage * 10}
                    </p>
                );
            },
        },
        {
            title: 'SKU',
            dataIndex: 'ProductSku',
            key: 'ProductSku',
            width: 50,
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.gen_category?.name ? record?.gen_category?.name : (replacedSku?.gen_category?.name ? replacedSku?.gen_category?.name : "All")}
                    </div>
                );
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.category?.category_name ? record?.category?.category_name : (replacedSku?.category?.category_name ? replacedSku?.category?.category_name : "All")}
                    </div>
                );
            },
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'subCategory',
            width: 120,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.sub_category?.sub_category_name ? record?.sub_category?.sub_category_name : (replacedSku?.sub_category?.sub_category_name ? replacedSku?.sub_category?.sub_category_name : "All")}
                    </div>
                );
            },
        },
        {
            title: 'Image',
            dataIndex: 'mediaUrl',
            key: 'mediaUrl',
            width: 70,
            render: (text, record) => {
                return (
                    <div className="listing-image">
                        <img src={`${record?.mediaUrl}`} />
                    </div>
                );
            },
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'productDesc',
        //     key: 'productDesc',
        //     width: 250,
        //     render: (text, record) => {
        //         return (
        //             <div className="desc-limit">
        //                 {record?.productDesc}
        //             </div>
        //         );
        //     },
        // },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="desc-limit" title={record?.title}>
                        {record?.title}
                    </div>
                );
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 50,
            render: (text, record) => {

                return (

                    <div className="desc-limit">
                        ${record?.price}
                    </div>
                );
            },
        },
        {
            title: 'Stock',
            dataIndex: 'event',
            key: 'event',
            align: 'center',
            width: 120,
            // sortOrder: sort,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => {
            //     let aa = a?.variants?.reduce((acc, item) => {
            //         return (
            //             acc = acc + item.inventory_quantity
            //         )
            //     }, 0);
            //     let bb = b?.variants?.reduce((acc, item) => {
            //         return (
            //             acc = acc + item.inventory_quantity
            //         )
            //     }, 0)
            //     return aa - bb;
            // },
            // onHeaderCell: () => ({
            //     onClick: () => setSort(sort === 'ascend' || sort === '' ? 'descend' :
            //         'ascend'),
            //         sortDirections: ['descend'],
            // }),
            render: (text, record, index) => {
                let status = record?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)
                return (
                    <>
                        <div className='balance-area'>
                            <div className='balance-count'>
                                {status}
                            </div>
                            {status > 0 ?
                                <a className='balance-btn default-btn outline small-btn' onClick={() => {
                                    showModal(record);
                                    setViewIndex(index)
                                }}
                                >
                                    View
                                    {/* <FontAwesomeIcon icon={faEye} /> */}
                                </a>
                                :
                                <span className='text-danger fw-bold'>Sold-out</span>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            title: 'Units Sold',
            dataIndex: 'qty_sold',
            key: 'qty_sold',
            width: 120,
            render: (text, record) => {
                return (
                    <div>
                        {numeral(record?.qty_sold).format('0,0')}
                    </div>
                );
            },
        },
        {
            title: 'Sales',
            dataIndex: 'sale',
            key: 'sale',
            width: 50,
            render: (text, record) => {
                return (
                    <div>
                        {numeral(record?.sale).format("$0,0.0'")}

                    </div>
                );
            },
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'event',
        //     key: 'event',
        //     width: 40,
        //     render: (text, record, index) => {
        //         let status = record?.variants?.reduce((acc, item) => {
        //             return (
        //                 acc = acc + item.inventory_quantity
        //             )
        //         }, 0)
        //         if (status > 70) {
        //             return (
        //                 <div className='status-info'><div className='status-flag green'></div>
        //                     {/* <p>green</p> */}
        //                 </div>
        //             )
        //         } else if (status >= 50 && status <= 70) {
        //             return (
        //                 <div className='status-info'><div className='status-flag yellow'></div>
        //                     {/* <p>yellow</p> */}
        //                 </div>
        //             )
        //         } else if (status < 50) {
        //             return (
        //                 <div className='status-info'><div className='status-flag red'></div>
        //                     {/* <p>red</p> */}
        //                 </div>
        //             )
        //         }
        //         else {
        //             return null
        //         }
        //     }
        // },
    ];

    const videoListcolumns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: 50,
            render: (text, record, index) => {
                const { current, pageSize } = pagination;
                return (current - 1) * pageSize + (index + 1);
            }
        },
        {
            title: 'Banner',
            dataIndex: 'banner',
            key: 'banner',
            width: 70,
            render: (text, record) => {
                return (
                    <div className="listing-image">
                        <Image
                            src={record?.banner}
                            rootClassName="no-preview-image"
                        />
                    </div>
                );
            },
        },
        {
            title: 'Video Title',
            dataIndex: 'title',
            key: 'title',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="desc-limit" title={record?.title}>
                        {record?.title}
                    </div>
                );
            },
        },
        {
            title: 'Video ID',
            dataIndex: 'event_counter',
            key: 'event_counter',
            width: 250,
            render: (text, record) => {

                return (
                    <>
                        {
                            videoListTitle === 'events' ?
                                <div className="desc-limit" title={record?.event_counter}>
                                    {record?.event_counter?.[0] + "-" + record?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                                </div>
                                :
                                videoListTitle === 'shows' ?
                                    <>
                                        <div className="desc-limit" title={record?.show_counter}>
                                            {record?.show_counter?.[0] + "-" + record?.show_counter?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                                        </div>
                                    </>
                                    :
                                    videoListTitle === 'reviews' ?
                                        <>
                                            <div className="desc-limit" title={record?.review_counter}>
                                                {record?.review_counter?.[0] + "-" + record?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                                            </div>
                                        </>
                                        :
                                        <></>
                        }
                    </>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 250,
            render: (text, record) => {
                return (
                    <>
                        {
                            videoListTitle === 'reviews' ?
                                record?.content_type === 'brand' ?
                                    ((record?.is_hard_published && !record?.is_published && !record?.is_deleted) ?
                                        <div className="desc-limit firstLetterCapital">
                                            Brand - Control
                                        </div>
                                        :
                                        (record?.is_published && !record?.is_deleted) ?
                                            < div className="desc-limit firstLetterCapital">
                                                Brand - Published
                                            </div >
                                            :
                                            (record?.is_hard_published && !record?.is_published && record?.is_deleted) ?
                                                < div className="desc-limit firstLetterCapital">
                                                    Brand - Deleted
                                                </div >
                                                :
                                                < div className="desc-limit firstLetterCapital">
                                                    -
                                                </div >)
                                    :
                                    (
                                        (record?.is_hard_published && !record?.is_published && !record?.is_deleted && !record?.under_review) ?
                                            <div className="desc-limit firstLetterCapital">
                                                User - UnPublished
                                            </div>
                                            :
                                            (record?.is_published && !record?.is_deleted) ?
                                                < div className="desc-limit firstLetterCapital">
                                                    User - Published
                                                </div >
                                                :
                                                (record?.is_hard_published && !record?.is_published && record?.is_deleted) ?
                                                    < div className="desc-limit firstLetterCapital">
                                                        User - Deleted
                                                    </div >
                                                    :
                                                    (!record?.is_published && record?.under_review) ?
                                                        < div className="desc-limit firstLetterCapital">
                                                            User - Under Review
                                                        </div >
                                                        :
                                                        < div className="desc-limit firstLetterCapital">
                                                            -
                                                        </div >
                                    )
                                :
                                videoListTitle === 'events' ?
                                    (
                                        // record?.
                                        <div className="desc-limit firstLetterCapital">
                                            {
                                                record.stream_type === 'local' ?
                                                    (record?.event_status === 'scheduled' ? 'Upcoming'
                                                        :
                                                        record?.event_status === 'live' ? 'Live'
                                                            :
                                                            (record?.event_status === 'recorded' && !record?.is_published)
                                                                ? "Management"
                                                                : (record?.event_status === 'recorded' && record?.is_published)
                                                                    ? "Published"
                                                                    : "-")
                                                    :
                                                    (
                                                        record?.event_status === 'scheduled' ? 'Upcoming'
                                                            :
                                                            record?.event_status === 'live' ? 'Live'
                                                                :
                                                                record?.event_status === 'ended'
                                                                    ? "Ended"
                                                                    : "-"
                                                    )


                                            }
                                        </div >
                                    )
                                    :
                                    videoListTitle === 'shows' ?
                                        (
                                            < div className="desc-limit firstLetterCapital">
                                                {record?.is_published ? 'Published' : 'Management'}
                                            </div>
                                        )
                                        :
                                        <></>


                        }
                    </>
                );
            },
        },
        {
            title: 'Event Type',
            dataIndex: 'stream_type',
            key: 'stream_type',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.stream_type === 'local' ? 'Internal' : record?.stream_type === 'external' ? 'External' : '-'}
                    </div>
                );
            },
        },



    ];

    // const columnDetails = [

    //     {
    //         title: 'Size',
    //         key: 'option1',
    //         dataIndex: ['option1'],
    //         width: 40,
    //         render: (text, record, index) => {
    //             return (
    //                 <div className=''> {record?.option1 ? record?.option1 : '-'} </div>
    //             );
    //         },
    //     },

    //     {
    //         title: 'Wash',
    //         key: 'option1',
    //         dataIndex: ['option1'],
    //         width: 100,
    //         render: (text, record, index) => {

    //             return (
    //                 <>


    //                     <div className=''> {record?.option2 ? record?.option2 : '-'} </div>


    //                 </>
    //             );

    //         },
    //     },

    //     {
    //         title: 'Inseam',
    //         key: 'option1',
    //         dataIndex: ['option1'],
    //         width: 70,
    //         render: (text, record, index) => {

    //             return (
    //                 <>




    //                     <div className=''>{record?.option3 ? record?.option3 : '-'} </div>

    //                 </>
    //             );

    //         },
    //     },

    //     {
    //         title: 'Stock',
    //         dataIndex: 'inventory_quantity',
    //         key: 'inventory_quantity',
    //         width: 60,
    //     },

    // ];
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            setActionChange(true)
            const dragRow = data[dragIndex];
            setIsCheckedOpen(false)
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );

        },
        [data],

    );
    const trigger = () => {
        setSpin(false);
        setIsCheckedOpen(true)

        let formData = new FormData();

        let newData = data.map(skuItem => {
            return ({
                ...skuItem,
                influencer_percent: data?.[0]?.influencer_percent ? data?.[0]?.influencer_percent : "0",
                referral_percent: data?.[0]?.referral_percent ? data?.[0]?.referral_percent : "0"
            })
        })
        if (location?.state?.inventory === "shows") {
            formData.append(
                "banner",
                typeof banner === "object" ? banner : undefined
            );
            formData.append("show", fields.image);
            formData.append("id", newData[0]?.id);
            formData.append("products", JSON.stringify(newData));
            formData.append("title", eventTitle);
            formData.append("notes", note);
            formData.append("video_ratio", videoRatio);
            // formData.append("promo", promo);
            // formData.append("discount", (discount += "%"));
            formData.append("discount", productPromoCodeDscs);
            formData.append("promo", productPromoCodePromo);
            formData.append(
                "referral_percent",
                referralPercent > 0 ? referralPercent : 0
            );
            formData.append(
                "influencer_percent",
                influencer_percent > 0 ? influencer_percent : 0
            );
            formData.append("thankyou_message", thankyou);

            formData.append("default_banner", sampleBanner);
            formData.append("orientation", orientation);
        } else {

            formData.append('image', banner);
            formData.append('id', newData[0]?.id);
            formData.append('primary_host', JSON.stringify(host));
            formData.append('host1', JSON.stringify(host2));
            formData.append('host2', JSON.stringify(host3));
            formData.append('host3', JSON.stringify(host4));
            formData.append('products', JSON.stringify(newData));
            formData.append('title', eventTitle);
            formData.append('notes', note);
            formData.append('thankyou_message', thankyou);
            formData.append('start_date', dateTime.toISOString());
            formData.append('category_id', catgId);
            formData.append('default_banner', sampleBanner);
        }
        // formData.append('social_platform', platform);
        dispatch((location?.state?.inventory === "shows" ? UpdateShows(formData, id) : UpdateEvents(formData, id))).then((res) => {
            if (res?.payload?.success) {
                notification.success({
                    message: 'Product Updated Successfully',
                    className: 'toast-success',
                });
                setActionChange(false)
                // showModal();
            } else {
                setSpin(false);
                notification.error({
                    message: 'Event Failed',
                    className: 'toast-error',
                });
            }
        });

    }

    const getProd = () => {
        dispatch(getRefreshProd(location?.state?.inventory === "shows" ? { "event": id } : { "event": id })).then(async (res) => {
            if (res.payload.success === true) {
                notification.success({
                    message: res.payload.message,
                    className: 'toast-success',
                });
                dispatch((location?.state?.inventory === "shows" ? getShowBy_Id(id) : getEventsBy_Id(id))).then(async (event) => {
                    if (event?.success) {
                        setSpin(false);
                        let orderData = event.payload?.message[0]?.event?.orders;
                        let productsData = event.payload?.message[0]?.event?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)
                        setSampleBanner(
                            event.payload?.message[0]?.event?.default_banner ? true : false
                        );

                        setPreviewImage(event.payload?.message[0]?.event?.banner);
                        setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.event?.title);
                        setEventNote(event.payload.message[0]?.event?.notes);
                        setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
                        setBanner(event.payload?.message[0]?.event?.banner);

                        let primary_data =
                            (await event.payload?.message[0]?.hosts[1]?.primary) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let primary_host = await primary_data.filter(
                            (num) => num.primary === true
                        );

                        let host2_data =
                            (await event.payload?.message[0]?.hosts[2]?.host1) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_2 = await host2_data.filter((num) => num.host1 === true);

                        let host3_data =
                            (await event.payload?.message[0]?.hosts[3]?.host2) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_3 = await host3_data.filter((num) => num.host2 === true);

                        let host4_data =
                            (await event.payload?.message[0]?.hosts[4]?.host3) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_4 = await host4_data.filter((num) => num.host3 === true);

                        setHost(primary_host);
                        setHost2(host_2);
                        setHost3(host_3);
                        setHost4(host_4);
                        setCategoryId(event.payload?.message[0]?.event?.category_id);

                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item.category_id == event.payload?.message[0]?.event?.category_id
                        );
                        setParent(parent[0]?.category_name);

                    }
                });
            }
        })
    }

    const formList = [
        <>
            <div className="row d-flex mb-20">
                {/* <div className="col-12 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Sku Management</h5>
          <p>
            Create product list to live event by using SKU numbers as added on
            the ecommerce platform.
          </p>
        </div> */}
                <div className="col-12 col-xl-12  flex-stretch form-styling">

                    <DndProvider backend={HTML5Backend}>
                        <Table
                            sorter
                            rowKey="ProductSku"
                            // rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                            pagination={false}
                            columns={columns}
                            dataSource={soldOutInventory?.message?.[0]?.data.length > 0 ? soldOutInventory?.message?.[0]?.data : ""}
                            loading={spinner}
                            // components={components}
                            onRow={(_, index) => {
                                const attr = {
                                    index,
                                    moveRow,
                                };
                                return attr;
                            }}
                            scroll={
                                data.length > 6
                                    ? { y: 550, x: 1000 }
                                    : { x: 1000 }
                            }
                        // scroll={{
                        //     x: 1000,
                        //     y: 150,
                        //   }}
                        />
                    </DndProvider>

                </div>
            </div>

        </>
    ];
    const handleIDType = (e) => {
        setIdType(e)
        setSearchDataIDs([])
        setSearchID(null)

        switch (e) {
            case "event":
                return dispatch(searchEventID("", "event", moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"), true)).then((res) => {
                        setSearchIDLoad(false)
                        if (res?.success) {

                            setSearchDataIDs(res?.message)
                        }
                    })
            case "show":
                return dispatch(searchShowID("", "show", moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"))).then((res) => {
                        setSearchIDLoad(false)
                        if (res?.success) {

                            setSearchDataIDs(res?.message)
                        }
                    })
            case "review":
                return dispatch(searchReviewID("", "review", moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"))).then((res) => {
                        setSearchIDLoad(false)
                        if (res?.success) {

                            setSearchDataIDs(res?.message)
                        }
                    })
        }
    }

    const handleReviewID = (e) => {
        setSearchIDLoad(true)
        switch (idType) {
            case "event":
                return dispatch(searchEventID(e.replaceAll("-", ''), "event", moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"), true)).then((res) => {
                        setSearchIDLoad(false)
                        if (res?.success) {

                            setSearchDataIDs(res?.message)
                        }
                    })
            case "show":
                return dispatch(searchShowID(e.replaceAll("-", ''), "show", moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"))).then((res) => {
                        setSearchIDLoad(false)
                        if (res?.success) {

                            setSearchDataIDs(res?.message)
                        }
                    })
            case "review":
                return dispatch(searchReviewID(e.replaceAll("-", ''), "review", moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"))).then((res) => {
                        setSearchIDLoad(false)
                        if (res?.success) {

                            setSearchDataIDs(res?.message)
                        }
                    })
        }
    }
    const dateRangePickerChanger = (value, dataString) => {
        const startDate = dataString[0];
        const endDate = dataString[1];
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleGender = (e) => {
        setCurrentPage(0);
        setGender(e);
        setCategory("All");
        setSubCategory("All");
        setCatLoad(true);
        dispatch(getSavedCategories(e)).then((res) => {
            if (res.success) {
                setCatLoad(false);
            } else {
                setCatLoad(false);
            }
        });
    };
    const handleCategory = (e) => {
        setCurrentPage(0);
        setCategory(e);
        setSubCategory("All");
        setSubCatLoad(true);
        dispatch(getSavedSubCategories(gender, e)).then((res) => {
            if (res.success) {
                setSubCatLoad(false);
            } else {
                setSubCatLoad(false);
            }
        });
    };
    useEffect(() => {
        setSkuLoad(true)
        const getData = setTimeout(() => {
            dispatch(searchActiveSkus("soldout", moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"), gender, category, subCategory, tempSKU)).then((res) => {
                    setSkuLoad(false)
                    if (res?.success) {
                        setSearchSKUs(res?.message)
                    }
                })
        }, 1000)

        return () => clearTimeout(getData)
    }, [tempSKU])

    const handleSubmit = (e) => {
        e.preventDefault();
        setSpin(true);
        dispatch(
            getInventory(
                pageLimit,
                1,
                "soldout",
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"),
                gender,
                category,
                subCategory,
                filterSKU,
                sortOn,
                idType,
                searchID?.value,
                sortBy,
                filterSkuField,
                filterTitleSku,
                filterDescField,
                filterDescSku
            )
        ).then((res) => {
            setSpin(false);
            setCurrentPage(0);
        });
    };
    const handlePageClick = (e) => {
        const page = e.selected;
        setSpin(true);
        setCurrentPage(page);
        dispatch(getInventory(pageLimit, page + 1, "soldout",
            moment(startDate).format("YYYY-MM-DD"),
            moment(endDate).format("YYYY-MM-DD"),
            gender,
            category,
            subCategory,
            filterSKU,
            sortOn,
            idType,
            searchID?.value, sortBy, filterSkuField,
            filterTitleSku, filterDescField, filterDescSku)).then(async (event) => {

                if (event?.success) {
                    setSpin(false);
                } else {
                    setSpin(false);
                }
            })
    };


    const handleSubCategory = (e) => {
        setCurrentPage(0);
        setSubCategory(e);
    };
    const handleReset = (res) => {
        setIdType("event")
        setTempSKU()
        setTempSkuField()
        setTempDescField()
        setFilterTitleSku()
        setFilterDescSku()
        setSearchIDLoad(true)
        setSearchID(null)
        const min_date = moment().startOf("year").format("YYYY-MM-DD");
        const max_date = moment(new Date()).format("YYYY-MM-DD");
        dispatch(searchEventID("", "event", min_date, max_date, true)).then((res) => {
            setSearchIDLoad(false)
            if (res?.success) {

                setSearchDataIDs(res?.message)
            }
        })
        setSearchID()
        setGender("All");
        setCategory("All");
        setSubCategory("All");
        // setInvSort("asc")
        setFilterSKU();
        setSpin(true)
        setCurrentPage(0);
        setInvSort("inventoryHighToLow")
        setSortOn("inventory");
        setSortBy("desc");
        setFilterSkuField();
        setFilterDescField();
        setSearchSkuField([])
        setSearchDescField([])
        setStartDate(min_date);
        setEndDate(max_date);
        dispatch(
            getInventory(
                pageLimit,
                1,
                "soldout",
                min_date,
                max_date,
                "",
                "",
                "",
                "",
                "inventory",
                "event",
                undefined,
                "desc"
            )
        ).then((res) => {
            setSpin(false);
        });
    };

    const handleChange = (e) => {
        setInvSort(e)
        if (e === "inventoryLowToHigh") {
            setSortOn("inventory");
            setSortBy("asc");
            // setInvSort("inventory")
        } else if (e === "inventoryHighToLow") {
            setSortOn("inventory");
            setSortBy("desc");
            // setInvSort("inventory")
        } else if (e === "priceLowToHigh") {
            setSortOn("price");
            setSortBy("asc");
            // setInvSort("order")
        } else if (e === "priceHighToLow") {
            setSortOn("price");
            setSortBy("desc");
            // setInvSort("order")
        }
        else if (e === "unitSoldLowToHigh") {
            setSortOn("sold");
            setSortBy("asc");
            // setInvSort("order")
        }
        else if (e === "unitSoldHighToLow") {
            setSortOn("sold");
            setSortBy("desc");
            // setInvSort("order")
        }
        else if (e === "salesLowToHigh") {
            setSortOn("sale");
            setSortBy("asc");
            // setInvSort("order")
        }
        else if (e === "salesHighToLow") {
            setSortOn("sale");
            setSortBy("desc");
            // setInvSort("order")
        }
    };


    return (

        <>
            {/* <div className='row justify-content-center'>

                <div className='col-lg-5 col-xl-4'>
                    <div className="event-preview-box-main mb-20">


                    </div>
                </div>

            </div> */}
            {/* <div className="top-area-box">
          <Link to="/events?type=Upcoming" className="prev-next-link ff-nunito">
            <FontAwesomeIcon icon={faAngleLeft} />
            <span> Go back</span>
          </Link>
        </div> */}
            <div className="top-area-box ">
                <div className="title-area mb-3 pb-0">
                    <h1 className="mb-0 pb-0">Sold Out SKU</h1>
                    <div className="mb-2 fw-bold">
                        Total Products: {spinner ? <span className="spin-color mx-2"><Spin size="small" /></span> : (soldOutInventory?.message?.[0]?.total || 0)}
                    </div>
                </div>
            </div>
            <div className="brand_container_main aff-payment">
                <Form >
                    <div className="row w-20-desktop-main">
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Select Start Date / End Date</p>
                            <RangePicker
                                size="large"
                                className="w-100"
                                key={4}
                                value={
                                    startDate && endDate
                                        ? [moment(startDate), moment(endDate)]
                                        : []
                                }
                                allowClear={false}
                                ranges={{
                                    "All Time": [
                                        moment().subtract(4, 'year').startOf("year"),
                                        moment(),
                                    ],
                                    "Last Year": [
                                        moment().subtract(1, 'year').startOf("year"),
                                        moment().subtract(1, 'year').endOf("year"),
                                    ],
                                    "YTD": [moment().startOf("year"), moment()],
                                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                                    "Today": [moment(), moment()],
                                    // "Last Year": [
                                    //     moment().startOf("year"),
                                    //     moment().subtract(1, "year").endOf("year"),
                                    // ],
                                    // "This Year": [moment().startOf("year"), moment()],
                                    // "Last Month": [
                                    //     moment().subtract(1, "month").startOf("month"),
                                    //     moment().subtract(1, "month").endOf("month"),
                                    // ],
                                    // "This Month": [
                                    //     moment().startOf("month"),
                                    //     moment().endOf("month"),
                                    // ],
                                    // Today: [moment(), moment()],
                                }}
                                format={dateFormat}
                                onChange={dateRangePickerChanger}
                            />
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Search ID</p>
                            <div className='d-flex align-items-center'>
                                <Select
                                    placeholder="Type"
                                    size="large"
                                    className='idTypeSelect'
                                    style={{
                                        width: '35%',
                                    }}
                                    focus={false}
                                    loading={loading}
                                    onBlur={false}
                                    value={idType}
                                    onChange={(e) => handleIDType(e)}
                                >
                                    <Option value="event">Event</Option>
                                    <Option value="show">Show</Option>
                                    <Option value="review">Review</Option>
                                </Select>
                                <Select
                                    showSearch
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    disabled={idType ? false : true}
                                    notFoundContent={searchIDLoad && <Spin size="small" />}
                                    loading={searchIDLoad}
                                    optionFilterProp="children"
                                    placeholder="Enter ID"
                                    labelInValue
                                    onChange={(e) => setSearchID(e)}
                                    optionLabelProp="label"
                                    onSearch={handleReviewID}
                                    filterOption={(input, options) =>
                                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    focus={false}
                                    value={searchID ? searchID?.label?.[0] + "-" + searchID?.label?.split(idType === "event" ? 'E' : (idType === "review" ? "R" : "S"))[1]?.split('-').join('')?.match(/.{1,3}/g)?.join('-') : null}
                                    onBlur={false}
                                >
                                    {searchDataIDs && !searchIDLoad &&
                                        searchDataIDs?.map((item, i) => {
                                            return (
                                                <Option key={item?._id ? item?._id : (item?._id ? item?._id : item?._id)} label={item?.event_counter ? item?.event_counter : (item?.review_counter ? item?.review_counter : item?.show_counter)} >
                                                    {
                                                        idType === "event" ? item?.event_counter && item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                                                            (idType === "review" ? item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                                                                item?.show_counter?.[0] + "-" + item?.show_counter?.split('S')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-'))
                                                    }
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>

                        </div>

                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Department</p>
                            <Select
                                size="large"
                                className="w-100"
                                key={4}
                                placeholder="Select Department"
                                focus={false}
                                onChange={handleGender}
                                value={gender}
                                onBlur={false}
                                loading={generalLoad}
                                disabled={!savedGeneralCategories || generalLoad}
                            >
                                <Option value={"All"}>
                                    All
                                </Option>
                                {savedGeneralCategories &&
                                    savedGeneralCategories?.message?.map((item, i) => {
                                        return (
                                            <Option value={item?.category_id}>
                                                {item?.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Category</p>
                            <Select
                                size="large"
                                className="w-100"
                                key={4}
                                placeholder="Select Category"
                                focus={false}
                                onChange={handleCategory}
                                value={category}
                                onBlur={false}
                                loading={catLoad}
                                disabled={!savedCategory.success || catLoad || !gender || gender === "All"}
                            >
                                <Option value={"All"}>
                                    All
                                </Option>
                                {savedCategory &&
                                    savedCategory?.message?.map((item, i) => {
                                        return (
                                            <Option value={item?.category_id}>
                                                {item?.category_name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Sub Category</p>
                            <Select
                                size="large"
                                className="w-100"
                                key={4}
                                placeholder="Select Sub Category"
                                focus={false}
                                onChange={handleSubCategory}
                                value={subCategory}
                                onBlur={false}
                                loading={subCatLoad}
                                disabled={
                                    !savedSubCategory?.success ||
                                    subCatLoad ||
                                    !gender || gender === "All" ||
                                    !category || category === "All"
                                }
                            >
                                <Option value={"All"}>
                                    All
                                </Option>
                                {savedSubCategory &&
                                    savedSubCategory?.message?.map((item, i) => {
                                        return (
                                            <Option value={item?.sub_category_id}>
                                                {item?.sub_category_name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">SKU</p>
                            <AutoComplete
                                listHeight={300}
                                dropdownMatchSelectWidth={false}
                                style={{ width: '100%' }}
                                size="large"
                                value={filterSKU}
                                options={searchSKUs && !skuLoad && searchSKUs?.map((item) => ({
                                    value: item?.sku,
                                    label: item?.sku,
                                    // sku: item?.ProductSku,
                                }))}
                                onSearch={(e) => setTempSKU(e)}
                                // onChange={(e) => setFilterSkuField(e)}
                                onChange={handleSkuValueChange}
                                placeholder="Enter SKU"
                                notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                                loading={skuLoad}
                                filterOption={(inputValue, option) =>
                                    option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
                                }
                            >
                                {/* {skuFieldLoader && <Spin size="small" />} */}
                            </AutoComplete>
                            {/* <Select
                                showSearch
                                size="large"
                                className="w-100"
                                key={4}
                                type="Number"
                                notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                                loading={skuLoad}
                                optionFilterProp="children"
                                placeholder="Enter SKU"
                                onChange={(e) => setFilterSKU(e)}
                                onSearch={(e) => setTempSKU(e)}
                                filterOption={(input, options) =>
                                    options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                focus={false}
                                // onChange={handleFilterSKU}
                                value={filterSKU}
                                onBlur={false}
                            >
                                {searchSKUs && !skuLoad &&
                                    searchSKUs?.map((item, i) => {
                                        return (
                                            <Option value={item?.sku}>
                                                {item?.sku}
                                            </Option>
                                        );
                                    })}
                            </Select> */}
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Title</p>
                            {/* <Select
                                showSearch
                                size="large"
                                className="w-100"
                                key={4}
                                type="Number"
                                notFoundContent={skuFieldLoader ? <Spin size="small" /> : <Empty />}
                                loading={skuFieldLoader}
                                optionFilterProp="children"
                                placeholder="Enter Description"
                                onChange={(e) => setFilterSkuField(e)}
                                onSearch={(e) => setTempSkuField(e)}
                                filterOption={(input, options) =>
                                    options.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                                focus={false}
                                // onChange={handleFilterSKU}
                                value={filterSkuField}
                                onBlur={false}
                            >
                                {searchSkuField && !skuFieldLoader &&
                                    searchSkuField?.map((item, i) => {
                                        return (
                                            <Option value={item?.ProductSku}>
                                                {item?.productDesc}
                                            </Option>
                                        );
                                    })}
                            </Select> */}
                            <AutoComplete
                                listHeight={300}
                                dropdownMatchSelectWidth={false}
                                style={{ width: '100%' }}
                                size="large"
                                value={filterSkuField}
                                options={searchSkuField && !skuFieldLoader && searchSkuField.map((item) => ({
                                    value: item?.title,
                                    label: item?.title,
                                    sku: item?.ProductSku,
                                }))}
                                onSearch={(e) => setTempSkuField(e)}
                                // onChange={(e) => setFilterSkuField(e)}
                                onSelect={handleSkuChange}
                                // onChange={handleSkuChange}
                                placeholder="Enter Title"
                                notFoundContent={skuFieldLoader ? <Spin size="small" /> : <Empty />}
                                loading={skuFieldLoader}
                            // filterOption={(inputValue, option) =>
                            //     option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
                            // }
                            >
                                {/* {skuFieldLoader && <Spin size="small" />} */}
                            </AutoComplete>
                        </div>

                        {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Inventory Sort</p>
                            <Select
                                className="w-100 select-style dark-field"
                                placeholder="Select Group By"
                                size="large"
                                focus={false}
                                loading={loading}
                                disabled={loading}
                                onBlur={false}
                                value={invSort}
                                onChange={(e) => setInvSort(e)}
                                defaultValue="asc"
                            >
                                <Option value="asc">Ascending</Option>
                                <Option value="desc">Descending</Option>

                            </Select>
                        </div> */}
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Sort By</p>
                            <Select
                                className="w-100 select-style dark-field"
                                placeholder="Select Group By"
                                size="large"
                                focus={false}
                                loading={loading}
                                disabled={loading}
                                onBlur={false}
                                value={invSort}
                                onChange={(e) => handleChange(e)}
                                defaultValue="inventoryHighToLow"
                            >
                                <Option value="inventoryLowToHigh">Stock - Low to High</Option>
                                <Option value="inventoryHighToLow">Stock - High to Low</Option>
                                <Option value="priceLowToHigh">Price - Low to High</Option>
                                <Option value="priceHighToLow">Price - High to Low</Option>
                                <Option value="unitSoldLowToHigh">Units Sold - Low to High</Option>
                                <Option value="unitSoldHighToLow">Units Sold - High to Low</Option>
                                <Option value="salesLowToHigh">Sales - Low to High</Option>
                                <Option value="salesHighToLow">Sales - High to Low</Option>

                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 linked-product-adjust-col col-12 d-flex align-items-end mb-15"></div>
                        <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15 justify-content-end">
                            <Button
                                onClick={handleSubmit}
                                className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                                type="primary"
                                htmlType="submit"
                            >
                                Search
                            </Button>
                            <Button
                                onClick={handleReset}
                                className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                                type="primary"
                                htmlType="submit"
                            >
                                Clear
                            </Button>
                        </div>
                    </div>
                </Form>
                <hr className="separator-line mt-0" />

            </div>


            {spinner ? <LoadingSpin /> :
                <>
                    <div className="inventory-box-main">

                        <Form >{formList}</Form>

                    </div>
                    {/* {soldOutInventory?.message?.[0]?.total > 10 && (
                        <ReactPaginate
                            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                            // marginPagesDisplayed={2}
                            pageCount={
                                soldOutInventory?.message?.[0]?.total > 0 ? Math.ceil(soldOutInventory?.message?.[0]?.total / 10) : 0
                            }
                            forcePage={currentPage}
                            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                            pageClassName="page-item d-none"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item d-none"
                            breakLinkClassName="page-link"
                            containerClassName={"pagination custom-paginate"}
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    )} */}


                    {soldOutInventory?.message?.[0]?.total > 10 && (
                        <ReactPaginate
                            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                            // marginPagesDisplayed={2}
                            pageCount={
                                soldOutInventory?.message?.[0]?.total > 0 ? Math.ceil(soldOutInventory?.message?.[0]?.total / 10) : 0
                            }
                            forcePage={currentPage}
                            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                            pageClassName="page-item d-none"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item d-none"
                            breakLinkClassName="page-link"
                            containerClassName={"pagination custom-paginate"}
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    )}


                    {/* <Modal
                        maskClosable={false}
                        className="modal-generic modal-600"
                        centered
                        visible={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}
                    // footer={[
                    //   // <Button key="back" onClick={handleCancel}>
                    //   //   Cancel
                    //   // </Button>,
                    //   <Button key="submit" type="primary"
                    //     className="d-table m-auto"
                    //     // onClick={handleOk}
                    //     // onClick={handleCancel}
                    //   >
                    //     Submit
                    //   </Button>,
                    // ]}

                    >
                        <h2 className="modal-hd1">Details</h2>
                        <div className="co">
                            <Table
                                pagination={false}
                                columns={skuNewArr?.[viewIndex]}
                                size="small"
                                className="product-listing-tabl inventory-box-main details-table"
                                //  rowSelection={{ ...rowSelection }}
                                dataSource={dataDetails}
                                // scroll={
                                //     dataDetails.length > 32
                                //         ? { y: 450, x: 500 }
                                //         : false
                                // }
                                scroll={
                                    dataDetails.length > 10
                                        ? { y: 400, x: 500 }
                                        : { x: 500 }
                                }
                            />
                        </div>
                    </Modal> */}

                    {/* <Modal
                        zIndex={9999999}
                        maskClosable={false}
                        className="modal-generic modal-800"
                        centered
                        visible={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}
                    // footer={[
                    //   // <Button key="back" onClick={handleCancel}>
                    //   //   Cancel
                    //   // </Button>,
                    //   <Button key="submit" type="primary"
                    //     className="d-table m-auto"
                    //     // onClick={handleOk}
                    //     // onClick={handleCancel}
                    //   >
                    //     Submit
                    //   </Button>,
                    // ]}

                    >
                        <h2 className="modal-hd1">Details</h2>
                        <div className="table-container inventory-table">
                            <table class={`table m-0 ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                <thead>
                                    <tr>
                                        {dataDetails?.productOpt?.map((item, i) => {
                                            return (
                                                <th className="width-25 border-bottom-0">{item.name}</th>
                                            );
                                        })}
                                        <th className="width-25 border-bottom-0">Stock</th>
                                        <th className="width-25 border-bottom-0">Price</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="table-body scrollbar-style">
                                <table class={`table ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                    <tbody>
                                        {dataDetails?.variants?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td className="width-25">{item.option1}</td>
                                                    {item.option2 && (
                                                        <td className="width-25">
                                                            {item.option2}
                                                        </td>
                                                    )}
                                                    {item.option3 && (
                                                        <td className="width-25">
                                                            {item.option3}
                                                        </td>
                                                    )}
                                                    <td className="width-25">
                                                        {item.inventory_quantity}
                                                    </td>
                                                    <td className="width-25">${item.price}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </Modal> */}

                    <Modal
                        zIndex={9999999}
                        maskClosable={false}
                        className="modal-generic modal-1400 product-detail"
                        centered
                        visible={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} style={{ marginTop: "20px" }} />}
                        footer={false}
                    >
                        <h2 className="modal-hd1 d-flex align-items-center justify-content-between">
                            <div>
                                Product Details
                            </div>
                            <div className="mr-6">
                                {
                                    dataDetails?._id &&
                                    <Button
                                        className="balance-btn default-btn outline small-btn"
                                        type="primary"
                                        size="large"
                                        onClick={() => showLinkModal(dataDetails)}
                                    >
                                        Video List Items</Button>}
                                <PDFDownloadLink document={<PDF item={dataDetails} />} fileName={`worksheet-${dataDetails?.variants?.[0]?.sku}-${dataDetails?.title}.pdf`}>
                                    <Button
                                        className="balance-btn default-btn outline small-btn"
                                        type="primary"
                                        size="large"
                                        title="Download Worksheet"
                                    >
                                        <FontAwesomeIcon
                                            size="md"
                                            className="event-icon"
                                            icon={faDownload}
                                        />
                                        Download</Button>
                                </PDFDownloadLink>
                            </div>
                        </h2>
                        {/* <div className="table-container inventory-table new-height">
                            <table class={`table m-0 ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                <thead className={dataDetails?.variants?.length > 15 ? "head-space" : ""}>
                                    <tr>
                                        {dataDetails?.productOpt?.map((item, i) => {
                                            return (
                                                <th className="width-25 border-bottom-0">{item.name}</th>
                                            );
                                        })}
                                        <th className="width-25 border-bottom-0">Stock</th>
                                        <th className="width-25 border-bottom-0">Price</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="table-body scrollbar-style">
                                <table class={`table ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                    <tbody>
                                        {dataDetails?.variants?.map((item, i) => {
                                            return (
                                                <tr>
                                                    {item.option1 && (<td className="width-25">{item.option1}</td>)}
                                                    {item.option2 && (
                                                        <td className="width-25">
                                                            {item.option2}
                                                        </td>
                                                    )}
                                                    {item.option3 && (
                                                        <td className="width-25">
                                                            {item.option3}
                                                        </td>
                                                    )}
                                                    <td className="width-25">
                                                        {item.inventory_quantity}
                                                    </td>
                                                    <td className="width-25">${item.price}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                {/* <Carousel>
                                    {dataDetails?.images?.map((item, i) => {
                                        return (
                                            <div>
                                                <img
                                                    src={`${item?.src ? item.src : defaultImage}`}
                                                    className="img-fluid"
                                                />
                                            </div>
                                        );
                                    })}
                                </Carousel> */}
                                <div>
                                    <img
                                        src={`${dataDetails?.mediaUrl ? dataDetails.mediaUrl : defaultImage}`}
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 items-column">
                                <div className="product-items">
                                    <span className="product-data">Product Title :</span>
                                    <span className="product-desc">
                                        {dataDetails?.title}
                                    </span>
                                </div>
                                <div className="product-items">
                                    <span className="product-data">Product ID :</span>
                                    <span className="product-desc">
                                        {dataDetails && dataDetails?.variants?.[0]?.product_id}
                                    </span>
                                </div>
                                <div className="product-items">
                                    <span className="product-data">Product SKU :</span>
                                    <span className="product-desc">
                                        {dataDetails && dataDetails?.variants?.[0]?.sku
                                            ? dataDetails?.variants?.[0]?.sku
                                            : "-"}
                                    </span>
                                </div>
                                <div className="product-items">
                                    <span className="product-data">Product Link:</span>
                                    <span className="product-desc">
                                        <a
                                            target="_blank"
                                            href={
                                                dataDetails?.ProductUrl
                                            }
                                        >
                                            {dataDetails?.ProductUrl}
                                        </a>
                                    </span>
                                </div>

                                <div className="product-items">
                                    <span className="product-data">Product Price :</span>
                                    <span className="product-desc">
                                        ${dataDetails && dataDetails?.variants?.[0]?.price}
                                    </span>
                                </div>
                                {dataDetails && dataDetails?.productDesc?.length > 0 &&
                                    <div className="product-items">
                                        <span className="product-data">
                                            Product Description :
                                        </span>
                                        <span className="product-desc">
                                            <TextArea
                                                className="border-textarea-light"
                                                style={{
                                                    height: 120,
                                                    resize: 'none',
                                                }}
                                                rows={4}
                                                value={
                                                    dataDetails && dataDetails?.productDesc
                                                        ? dataDetails?.productDesc
                                                        : ""
                                                }
                                            />
                                        </span>
                                    </div>}
                                {dataDetails && dataDetails?.tags?.length > 0 &&
                                    <div class="product-items">
                                        <span className="product-data">Product Tags:</span>
                                        <div className="product-desc prodcut-tags-scroll scrollbar-style">
                                            <Space size={[0, 8]} wrap>
                                                {dataDetails?.tags?.split(",")?.map((item, i) => {
                                                    return <Tag>{item}</Tag>;
                                                })}
                                            </Space>
                                        </div>
                                    </div>}
                            </div>
                            <div className="col-md-5">
                                <div className="co mt3">
                                    <h4>Variants</h4>

                                    <div className="table-container inventory-table new-height">
                                        <table class={`table m-0 ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                            <thead>
                                                <tr>
                                                    <th className="width-15 border-bottom-0">S.#</th>
                                                    {dataDetails?.productOpt?.map((item, i) => {
                                                        return (
                                                            <th className="width-25 border-bottom-0">{item.name}</th>
                                                        );
                                                    })}
                                                    <th className="width-25 border-bottom-0"
                                                    // onClick={() => sortData('stock')}
                                                    >
                                                        Stock
                                                        {/* {getSortIcon('stock')} */}
                                                    </th>
                                                    <th className="width-25 border-bottom-0"
                                                    // onClick={() => sortData('price')}
                                                    >
                                                        Price
                                                        {/* {getSortIcon('price')} */}
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <div className="table-body scrollbar-style">
                                            <table class={`table ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.productOpt?.length && dataDetails?.productOpt?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                                <tbody>
                                                    {dataDetails?.variants?.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <td className="width-15">{i + 1}</td>
                                                                {item.option1 && <td className="width-25">{item.option1}</td>}
                                                                {item.option2 && (
                                                                    <td className="width-25">
                                                                        {item.option2}
                                                                    </td>
                                                                )}
                                                                {item.option3 && (
                                                                    <td className="width-25">
                                                                        {item.option3}
                                                                    </td>
                                                                )}
                                                                <td className="width-25">
                                                                    {item.inventory_quantity}
                                                                </td>
                                                                <td className="width-25">${item.price}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    {/* <Table
                        pagination={false}
                        // columns={skuNewArr?.[viewIndex]}
                        columns={columnDetails}
                        size="small"
                        className="product-listing-tabl inventory-box-main details-table"
                        //  rowSelection={{ ...rowSelection }}
                        dataSource={dataDetails?.variants}
                        // scroll={
                        //     dataDetails.length > 32
                        //         ? { y: 450, x: 500 }
                        //         : false
                        // }
                        scroll={
                            dataDetails?.variants?.length > 10
                                ? { y: 400, x: 500 }
                                : { x: 500 }
                        }
                    /> */}
                                </div>
                            </div>
                        </div>

                    </Modal>








                    {/*                     
                    <Modal
                        className="modal-generic modal-1000"
                        centered
                        open={replace}
                        onOk={handleReplace}
                        onCancel={handleCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}
                        maskClosable={false}

                    >
                        <h2 className="modal-hd1">Add Sku</h2>
                        <Form >
                            <Form.Item>
                                <Form.Item
                                    name="productsku"
                                    label="Product SKU"
                                    className="d-flex flex-column sku-field mb-10 pos-index"
                                >
                                    <AsyncProduct
                                        getSku={getSku}
                                        replaceSku={replaceSku}
                                        skuData={data}
                                        replace={replace}
                                        skuArr={skuArr}
                                    />
                                    {skuError && (
                                        <div role="alert" className="ant-form-item-explain-error">
                                            Please Select Product SKU
                                        </div>
                                    )}
                                </Form.Item>
                            </Form.Item>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    sorter
                                    // rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={skuArr?.length > 0 ? skuArr : ""}
                                    loading={spinner}
                                    components={components}
                                    className={'scrollbar-style-parent mb-15'}
                                    onRow={(_, index) => {
                                        const attr = {
                                            index,
                                            moveRow,
                                        };
                                        return attr;
                                    }}
                                    scroll={
                                        data.length > 6
                                            ? { y: 550, x: 900 }
                                            : { x: 900 }
                                    }

                                />
                            </DndProvider>
                        </Form>
                        <div className="d-flex justify-content-end">
                            <Button
                                // loading={loading}
                                onClick={handleReplace}
                                // className="default-btn padb-3 d-inline-flex align-items-center justify-content-center color-secondary"
                                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center 
            `}
                            >
                                <span className="nav-text">Replace</span>
                            </Button>
                            <Button
                                className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center"
                                key="back"
                                onClick={handleCancel}
                            >
                                Exit
                            </Button>
                        </div>

                    </Modal>
                 */}

                    <Modal
                        className="modal-generic modal-1200"
                        centered
                        open={replaceModal}
                        onOk={handleReplace}
                        onCancel={handleReplaceCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}
                        maskClosable={false}
                    >
                        <h2 className="modal-hd1">Replace Sku</h2>
                        <p className='fw-bold'>Replacing SKU:</p>
                        <Table
                            sorter
                            // rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                            pagination={false}
                            columns={replaceColumns}
                            dataSource={replacedSku ? [replacedSku] : ""}
                            loading={spinner}
                            className={'scrollbar-style-parent mb-15 inventory-box-main'}
                            scroll={
                                data?.length > 6
                                    ? { y: 550, x: 900 }
                                    : { x: 900 }
                            }
                        />
                        {/* <p>Replacing SKU: <b >{replacedSku?.ProductSku} </b> <span className='ml-3'>Inventory:</span> <b>{replacedSku?.inventory}</b> </p> */}
                        <Form >
                            <Form.Item>
                                <Form.Item
                                    name="productsku"
                                    label="With SKU"
                                    className="d-flex flex-column sku-field mb-10 pos-index replaceSkuss"
                                >
                                    <AsyncProduct
                                        getSku={getSku}
                                        sku={sku}
                                        skuData={data}
                                        replace={replace}
                                        setSkuNull={setSkuNull}
                                        skuNull={skuNull}
                                    />
                                    {skuError && (
                                        <div role="alert" className="ant-form-item-explain-error">
                                            Please Select Product SKU
                                        </div>
                                    )}
                                </Form.Item>
                            </Form.Item>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    sorter
                                    rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                                    pagination={false}
                                    columns={replaceColumns}
                                    dataSource={sku ? sku : ""}
                                    loading={spinner}
                                    // components={components}
                                    className={'scrollbar-style-parent mb-15 inventory-box-main'}
                                    // onRow={(_, index) => {
                                    //     const attr = { 
                                    //         index,
                                    //         moveRow,
                                    //     };
                                    //     return attr;
                                    // }}
                                    scroll={
                                        data?.length > 6
                                            ? { y: 550, x: 900 }
                                            : { x: 900 }
                                    }

                                />
                            </DndProvider>
                        </Form>
                        <div className="d-flex justify-content-end">
                            <Button

                                // loading={loading}
                                onClick={handleReplace}
                                disabled={sku?.length === 0}
                                // className="default-btn padb-3 d-inline-flex align-items-center justify-content-center color-secondary"
                                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center 
            `}
                            >
                                <span className="nav-text">Replace</span>
                            </Button>
                            <Button
                                className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center"
                                key="back"
                                onClick={handleReplaceCancel}
                            >
                                Exit
                            </Button>
                        </div>

                    </Modal>


                    {/* Link Videos Modal */}
                    <Modal
                        zIndex={9999999}
                        maskClosable={false}
                        className="modal-generic modal-1000 link-product-list-modal"
                        visible={isLinkModalOpen}
                        onOk={handleOk}
                        onCancel={handleLinkVideosCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}

                    >
                        <h2 className="modal-hd1">Video List Items</h2>
                        {
                            isLinkModalLoader ?
                                <div className="table-container inventory-table new-height">
                                    <span className="spin-color mx-2 d-flex justify-content-center p-2"><Spin size="medium" /></span>
                                </div>
                                :
                                <>
                                    <div className="grid-listing-area flex-column tabs-overflow-scroll scrollbar-style flex-sm-row align-items-start align-items-sm-center mb-2">
                                        <div className="grid-listing-left flex-grow-1">
                                            <Tabs
                                                // defaultActiveKey="allvideos"
                                                defaultActiveKey={videoListTitle}
                                                // onChange={onChange}
                                                className="events-tabs-main"
                                                onTabClick={callbackTabClicked}
                                            >
                                                <TabPane tab={<>
                                                    Events
                                                    <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                                        ({linkDataDetails?.events?.length})
                                                    </span>
                                                </>} key="events"></TabPane>
                                                <TabPane tab={<>
                                                    Shows
                                                    <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                                        ({linkDataDetails?.shows?.length})
                                                    </span>
                                                </>} key="shows"></TabPane>
                                                <TabPane tab={<>
                                                    Reviews
                                                    <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                                                        ({linkDataDetails?.reviews?.length})
                                                    </span>
                                                </>} key="reviews"></TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className={`upcoming-events-wrapper mb-2`}>
                                        {/* {title === 'uploadvideo' && <UploadVideo />} */}
                                        {/* {title === 'allvideos' && <AllVideos />} */}
                                        {
                                            <>
                                                <Table
                                                    size='medium'
                                                    // pagination={{
                                                    //     pageSize: 10,
                                                    // }}
                                                    pagination={{
                                                        current: pagination.current,
                                                        pageSize: pagination.pageSize,
                                                        onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
                                                    }}
                                                    // columns={videoListcolumns}
                                                    columns={videoListTitle === 'events' ? videoListcolumns : videoListcolumns.filter(column => column.dataIndex !== 'stream_type')}
                                                    dataSource={
                                                        (videoListTitle === 'events' && linkDataDetails?.events?.length > 0)
                                                            ? linkDataDetails?.events
                                                            :
                                                            (videoListTitle === 'shows' && linkDataDetails?.shows?.length > 0)
                                                                ? linkDataDetails?.shows
                                                                :
                                                                (videoListTitle === 'reviews' && linkDataDetails?.reviews?.length > 0)
                                                                    ? linkDataDetails?.reviews
                                                                    : []
                                                    }
                                                    className={'scrollbar-style-parent mb-15 inventory-box-main link-product-list-table'}
                                                />

                                            </>

                                        }
                                        {/* {title === 'publish' && <ReviewPublish />}
                {title === 'deleted' && <ReviewDeleted />} */}
                                        {/* {title === 'analytics' && "analytics"} */}
                                    </div>
                                </>

                        }
                    </Modal>

                </>
            }


        </>
    );
}
