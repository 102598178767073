import { BOOSTED_REVIEWS_REQUEST, BOOSTED_REVIEWS, BOOSTED_REVIEWS_ERROR } from "../../types/types";
export default function getBoostedReviews(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case BOOSTED_REVIEWS_REQUEST:
            return {
                loading: true,
            };
        case BOOSTED_REVIEWS:
            return {
                ...state,
                payload: payload,
            };
        case BOOSTED_REVIEWS_ERROR:
            return {
                ...state,
                loading: false,
                payload: [],
            };
        default:
            return state;
    }
}