import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import * as instaReelsAction from "../../redux/actions/instagramReels.action";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
  faCalendarDays,
  faClock,
  faFilePdf,
  faLink,
  faTrash,
  faThumbsUp,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import {
  Spin,
  Modal,
  Button,
  Form,
  Input,
  Upload,
  message,
  Select,
  notification,
  Avatar,
  Table,
  DatePicker,
  InputNumber,
  Progress,
} from "antd";
import "video-react/dist/video-react.css";
import AsyncProduct from "../create-event/scheduleEvent/asyncProduct";
import GroupIcon from "@mui/icons-material/Group";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import { getAllCreator } from "../../redux/actions/influencerReview";
import ReactPlayer from "react-player";
import LoadingSpin from "../../reUsable/loadingSpin";
import {
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import AsyncInfluencer from "./asyncInfluencer";
import { Percent } from "../../context/PercentProvider";
import { UPDATE_INSTA_STATE, UPLOAD_DONE_REVIEW } from "../../redux/types/types";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
import { getShopifyDetail } from "../../redux/actions/shopify.action";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from "@react-pdf/renderer";

import "jspdf-autotable";
import { hitAPIinsta } from "../../redux/actions/hitInstaApi.action";
import { store } from "../../redux/store";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

function LinkReels({
  instaReelsAction,
  getInfluencerVideo,
  linkInfluencerProduct,
  reviewDeleted,
  getInstaReels,
  linkInstaReel,
  instaReels
}) {
  const dispatch = useDispatch();
  const {
    parentCategory,
    getAllCreatorData,
    promoRequest,
    reactS3BucketData,
    savedGeneralCategories,
    uploadDetailReview,
    getStructureFees, getAllInfluencers, instaReelsData
  } = useSelector((state) => {
    return state;
  });

  const socketUrl = process.env.REACT_APP_SOCKET_URL;
  const percent = useContext(Percent);
  const childRef = useRef();
  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 2,
      reconnectInterval: 3000,
    }
  );
  useEffect(() => {
    dispatch(getShopifyDetail()).then((res) => {
      if (res?.payload?.connection) {
        setShopify(false)
      } else {
        setShopify(true)
      }

    })
    dispatch(getInfluencers()).then((res) => {
    })
    dispatch(getFeeStructure()).then((res) => { });
    return () => {
      didUnmount.current = true;
    };
  }, []);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [shopify, setShopify] = useState(false);
  const [videoData, setVideoData] = useState("");
  const [load, setLoad] = useState(false);
  const [banner, setBanner] = useState([]);
  const [sku, setSku] = useState([]);
  const [skuError, setSkuError] = useState(false);
  const [parent, setParent] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [dataDetails, setDataDetail] = useState([]);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [flag, setFlag] = useState(false);
  const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
  const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
  const [referralPercent, setReferralPercent] = useState("0");
  const [creatorFee, setCreator_fee] = useState("0");
  const [influencer_percent, setInfluencer_percent] = useState("0");
  // const fromDate = moment().startOf('year').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const toDateNew = moment(new Date()).add(1, 'days').format("YYYY-MM-DD");
  // const fromDate = moment().year(2010).month(8).date(1).format("YYYY-MM-DD");
  const fromDate = moment().startOf('year').format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [endDateNew, setEndDateNew] = useState(toDateNew);
  const [loadd, setLoadd] = useState(false);
  const [loadInd, setLoadInd] = useState(null);
  const [influencerId, setInfluencerId] = useState();
  const [influencer, setInfluencer] = useState("");
  const [sortBy, setSortBy] = useState();
  const [status, setStatus] = useState();
  const [sortedReels, setSortedReels] = useState([]);
  const limit = 8;
  const firstRender = useRef(true);

  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [genLoading, setGenLoading] = useState(true);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [userExist, setUserExist] = useState(false);
  const [msg, setMsg] = useState(false);
  const [dateTime, setDate] = useState("");
  const [influencerError, setInfluencerError] = useState(false);
  const [nextParams, setNextParams] = useState(null);
  let nextCursor = '';
  let previousCursor = '';
  const [form] = Form.useForm();


  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(8);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
    let dateReels = instaReelsData?.filter((reel) => (moment(reel?.timestamp).isBetween
      (moment(startDate), moment(endDateNew))))
    setSortedReels(dateReels)
    // setSortedReels(instaReelsData)
    // console.log("HEREE");
  }, [instaReelsData])
  const onLinkReel = async (item) => {
    setSaveLoading(true);
    let video = document.createElement('video');
    video.src = item?.media_url;
    video.onloadedmetadata = function () {
      let orientation = (video.videoWidth > video.videoHeight) ? 'Landscape' : 'portrait';
      // item.orientation = orientation;
      const fileName = 'banner.jpeg'

      fetch(item?.thumbnail_url)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], fileName, { type: 'image/jpeg' })
          // access file here
          let formData = new FormData();
          formData.append("caption", item?.caption);
          formData.append("comments_count", item?.comments_count);
          formData.append("id", item?.id);
          formData.append("is_added", item?.is_added);
          formData.append("like_count", item?.like_count);
          formData.append("media_type", item?.media_type);
          formData.append("media_url", item?.media_url);
          formData.append("permalink", item?.permalink);
          formData.append("status", item?.status);
          formData.append("timestamp", item?.timestamp);
          formData.append("orientation", orientation);
          formData.append("image", file);
          formData.append("user_id", getAllInfluencers && getAllInfluencers?.message?.[0]?.user_id);

          linkInstaReel(formData).then((res) => {
            if (res.type === "LINK_INSTA_REELS_SUCCESS") {
              // setLoading(true)
              notification.success({
                message: res.payload?.message,
                className: "toast-success",
              });
              setSortBy()
              setStatus()
              // setStartDate(fromDate);
              setCurrentPage(0)
              // setEndDate(toDate);
              setLoadd(false)
              setTimeout(() => {
                setLoading(false)
              }, 100);
              // dispatch(hitAPIinsta("hit"))
              let newSorteds = sortedReels?.map((reel) => {
                if (item?.id === reel?.id) {
                  return {
                    ...reel,
                    status: "Pending Linking"
                  }
                }
                else {
                  return reel
                }
              })
              setSortedReels(newSorteds)
              // console.log("instaReelsDataAAAAAAAAAAAA", instaReelsData);
              let newinstaReelsData = instaReelsData?.map((reel) => {
                if (item?.id === reel?.id) {
                  return {
                    ...reel,
                    status: "Pending Linking"
                  }
                }
                else {
                  return reel
                }
              })
              // console.log("newinstaReelsDataAAAAA", newinstaReelsData);

              store.dispatch({ type: UPDATE_INSTA_STATE, payload: newinstaReelsData })
            }
            else {
              notification.error({
                message: res.payload?.message,
                className: "toast-error",
              });
            }
          });
        })

    };

    // var vid = document.getElementById("myVideo");
    // console.log(vid.videoHeight);
    // console.log(vid.videoWidth);
    // const url = URL.createObjectURL(file);
    //       const $video = document.createElement("video");
    //       $video.src = url;
    //       $video.addEventListener("loadedmetadata", function () {

    //         if (this.videoWidth > this.videoHeight) {
    //           setOrientation("landscape")
    //         } else {
    //           setOrientation("portrait")
    //         }
    //       });


    //form.setFieldsValue({ banner: item?.banner });

    //setShowPlayer(true);
    setVideoData(item);
  };
  // console.log("sortedReels", sortedReels);
  const pgNum = [];
  for (let i = 1; i <= Math.ceil(sortedReels.length / postsPerPage); i++) {
    pgNum.push(i)
    // pgNum[i-1] = i;
  }

  const indexOfLastPost = (currentPage + 1) * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedReels.slice(indexOfFirstPost, indexOfLastPost)
  console.log(currentPosts, 'currentPosts');





  function renderContent() {
    if (!loading) {
      if (instaReelsData?.length > 0) {
        return (

          <>

            <Row className="post-analytics-tab-boxes-ift influencer-reviews-main insta-boxes-main">
              {currentPosts?.length > 0 ? (
                currentPosts?.map((item, i) => (
                  <Col xs={12} xl={3} md={6}>
                    <div
                      key={i}
                      className={`card any_bx analytic-box campaign-box pb-0`}
                    >
                      <div className="camp-row row">
                        <div className="campaign-header col-12">
                          <div className="inf-header">
                            {/* <span className="review-box-header mr-2">
                          {item?.influencer?.profile_image_url ? (
                            <Avatar
                              icon={<UserOutlined />}
                              src={item?.influencer?.profile_image_url}
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </span> */}
                            <div className="inf-username-box">
                              {item.caption ?
                                <span className="inf-username filename">
                                  Caption:{" "}
                                  <span className="file-color" title={item?.caption}>
                                    {item?.caption}{" "}
                                  </span>
                                </span>
                                : <span className="inf-username filename">
                                  Caption:{".... "}
                                  <span className="file-color">
                                  </span>
                                </span>}

                              {/* <span className="inf-email">{item?.influencer?.email}</span> */}
                              {/* <span className="inf-email">Followers: 10</span> */}
                            </div>

                            {/* <div className="inf-head-followers">
                          <GroupIcon className="fol-icon" />
                          <h5>
                            {item?.influencer?.followers
                              ? numberFormat(item?.influencer?.followers)
                              : 0}
                          </h5>
                        </div> */}
                          </div>
                        </div>
                        <div className="any-post-img-col col-12">
                          <div className="any-post-image media-post-new">
                            {/* {item?.media_url ? (
                          <div className="any-image-box">
                            <div className="any-image-box-iner review-video">
                              <div className="review-video">
                                <ReactPlayer
                                  className={`review_react_player ${item?.media_url?.split(".")[
                                    item?.media_url?.split(".")?.length - 1
                                    ] === "m3u8" && "objectFit"
                                    }`}
                                  width={"100%"}
                                  height={"250px"}
                                  url={item?.media_url}
                                  controls
                                  light={item?.banner}
                                  playsinline
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center align-items-center w-100 h-100 media-processing">
                              {reactS3BucketData?.payload?.length === 0 ||
                                reactS3BucketData?.length === 0 ? (
                                <>
                                  <div className="d-flex">
                                    <span className="spinclass spin-color position-relative mr-3">
                                      <Spin className="" />
                                    </span>
                                    <div className="process-hd ff-nunito">
                                      Media Processing
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="process-hd ff-nunito">
                                  Media Processed!
                                </div>
                              )}
                            </div>
                          </>
                        )} */}
                            <div className="review-video">
                              {item?.media_url ? (
                                item?.error_reason === "Uploading" &&
                                  !item.is_convert ? (
                                  <div className="reviewConverting">
                                    <Progress
                                      className="convertProgress"
                                      strokeLinecap="butt"
                                      strokeColor={"#52c41a"}
                                      showInfo={false}
                                      percent={100}
                                      size="default"
                                      status="active"
                                    />
                                    <span className="mt-2">
                                      Media is Converting
                                    </span>
                                  </div>
                                ) : item?.error_reason ===
                                  "Failed on media conversion" ? (
                                  <div
                                    className="media-processing process-height"
                                    style={{ padding: "2rem" }}
                                  >
                                    <div
                                      className="process-hd ff-nunito"
                                      style={{ color: "rgb(205, 24, 24)" }}
                                    >
                                      File is corrupted
                                    </div>
                                  </div>
                                ) : (
                                  <ReactPlayer
                                    id="myVideo"
                                    className={`review_react_player ${item?.media_url
                                      ? item?.media_url?.split(".")[
                                      item?.media_url?.split(".")?.length - 1
                                      ] === "m3u8" && "objectFit"
                                      : "objectFit"
                                      }`}
                                    width={"100%"}
                                    height={"250px"}
                                    url={item?.media_url}
                                    controls
                                    light={item?.thumbnail_url}
                                    playsinline
                                  />
                                )
                              ) : (
                                <div
                                  className="media-processing process-height"
                                  style={{ padding: "2rem" }}
                                >
                                  {percent[item.review_s3_id] ||
                                    percent[item.review_s3_id] === 0 ? (
                                    <Progress
                                      key={item.review_s3_id}
                                      percent={percent[item.review_s3_id]}
                                      status="active"
                                      type="circle"
                                      strokeLinecap="butt"
                                      strokeColor={"#52c41a"}
                                    />
                                  ) : item?.error_reason === "Uploading" ? (
                                    <div className="process-hd ff-nunito">
                                      Please wait while video in processing
                                    </div>
                                  ) : (
                                    <div
                                      className="process-hd ff-nunito"
                                      style={{ color: "rgb(205, 24, 24)" }}
                                    >
                                      {item?.error_reason}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="inf-content-area ">
                          <div className="  d-flex justify-content-between ">
                            <div className="event-date-main top-date  mb-2">
                              <div className="event-date">
                                <FontAwesomeIcon
                                  className="event-icon"
                                  icon={faThumbsUp}
                                />

                                <div className="event-txt">
                                  {item?.like_count ? item?.like_count : "0"}
                                </div>
                              </div>
                              <div className="event-date">
                                <FontAwesomeIcon
                                  className="event-icon"
                                  icon={faComment}
                                />

                                <div className="event-txt">
                                  {item?.comments_count ? item?.comments_count : "0"}
                                </div>
                              </div>
                            </div>
                            <div className="reel-status">
                              {item?.status}
                            </div>
                          </div>

                          <div className="event-date-main top-date">
                            <div className="event-date">
                              <FontAwesomeIcon
                                className="event-icon"
                                icon={faCalendarDays}
                              />
                              <div className="event-txt">
                                {moment(item?.timestamp)
                                  .utc()
                                  .format("MM-DD-YYYY")}
                              </div>
                            </div>
                            <div className="event-date">
                              <FontAwesomeIcon
                                className="event-icon"
                                icon={faClock}
                              />
                              <div className="event-txt">
                                {moment(item?.timestamp).format("hh:mm A")}
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>
                      <div
                        // className="cam-buttons col-12"
                        className="review-card-footer"
                      >



                        {item?.media_url && (


                          <Button
                            className="default-btn d-flex align-items-center justify-content-center"
                            type="primary"
                            size="large"
                            loading={loadd && i === loadInd}
                            disabled={item?.status === "" ?
                              false : true}
                            onClick={() => {
                              onLinkReel(item);
                              setLoadd(true);
                              setLoadInd(i)
                            }}
                            icon={
                              <FontAwesomeIcon
                                size="sm"
                                className="event-icon"
                                icon={faLink}
                              />
                            }
                          >
                            {item?.status === "" ?
                              "Link Reel" : "Linked"}
                          </Button>
                        )}

                      </div>
                    </div>
                  </Col>

                ))
              ) : (
                <div className="col-md-12">
                  <NoDataFound />
                </div>
              )}
            </Row>

            {/* 
						<div id="pgNums" className="mt-5 flex text-center  justify-center ">
							{
								pgNum.map((num, index) => {
									// console.log(`num ${num} index ${index} currentPage ${currentPage}`);
									return (
										<span key={index} onClick={() => { setCurrentPage(num); }} className={` ${currentPage === num ? "" : ""}`}>
											{num}
										</span>
									)
								})
							}
						</div> */}

          </>
        );
      } else {
        return (
          <div className="connect-facebook-area">
            <Link to="/account/social" className="default-btn h-initial">Connect Facebook</Link>
          </div>

        )
      }
    } else {
      return <LoadingSpin />;
    }
  }

  //   const filterOption = (input, option) =>
  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  // const selectInfluencer = (value) => {
  //   setInfluencer(value);
  //   setFields({
  //     ...fields,
  //     user_id: value,
  //   });
  // }

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    setTimeout(() => {
      setLoading(false)
    }, 200);
  };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    if (
      moment(startDate).isSame(moment().subtract(1, 'year').startOf('year'), 'day') &&
      moment(endDate).isSame(moment().subtract(1, 'year').endOf('year'), 'day')
    ) {
      // If "Last Year" is selected, update endDateNew
      setStartDate(startDate);
      setEndDate(endDate);
      setEndDateNew(endDate); // Sync endDateNew with the "Last Year" range's endDate
    } else {
      // For other ranges
      setStartDate(startDate);
      setEndDate(endDate);
      setEndDateNew(toDateNew); // Retain the default next-day value
    }
  };

  const showModalDetail = (s) => {
    setDataDetail(s.variants);
    setIsModalOpenDetail(true);
  };

  const handleCancelDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleOkDetail = () => {
    setIsModalOpenDetail(false);
  };

  const columnDetails = [
    {
      title: "Size",
      key: "option1",
      dataIndex: ["option1"],
      width: 40,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option1 ? record?.option1 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Wash",
      key: "option1",
      dataIndex: ["option1"],
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option2 ? record?.option2 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Inseam",
      key: "option1",
      dataIndex: ["option1"],
      width: 70,
      render: (text, record, index) => {
        return (
          <>
            <div className="">{record?.option3 ? record?.option3 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];

  console.log(instaReelsData, "instaReelsData");
  console.log(sortedReels, "sortedReels");


  const handleSearch = (e) => {
    // console.log("instaReelsData", instaReelsData);
    setCurrentPage(0)
    setLoading(true)

    if (status === "linked") {

      if (sortBy === "likes") {
        // let linkedReels = [...instaReelsData];
        let linkedReels = instaReelsData?.filter((links) => links?.status !== "" && (moment(links?.timestamp).isBetween
          (moment(startDate), moment(endDateNew)))).sort((a, b) => {
            return (
              b.like_count - a.like_count
            )
          })
        setSortedReels(linkedReels)
      } else if (sortBy === "comments") {
        let linkedReels = instaReelsData?.filter((links) => links?.status !== "" && (moment(links?.timestamp).isBetween
          (moment(startDate), moment(endDateNew)))).sort((a, b) => {
            return (
              b.comments_count - a.comments_count
            )
          })
        setSortedReels(linkedReels)

      } else {

        let linkedReels = instaReelsData?.filter((links) => links?.status !== "" && (moment(links?.timestamp).isBetween
          (moment(startDate), moment(endDateNew))))
        setSortedReels(linkedReels)

      }

    }
    else if (status === "unlinked") {


      if (sortBy === "likes") {
        let unLinkedReels = instaReelsData?.filter((links) => links?.status === "" && (moment(links?.timestamp).isBetween
          (moment(startDate), moment(endDateNew)))).sort((a, b) => {
            return (
              b.like_count - a.like_count
            )
          })

        setSortedReels(unLinkedReels)

      } else if (sortBy === "comments") {
        let unLinkedReels = instaReelsData?.filter((links) => links?.status === "" && (moment(links?.timestamp).isBetween
          (moment(startDate), moment(endDateNew)))).sort((a, b) => {
            return (
              b.comments_count - a.comments_count
            )
          })

        setSortedReels(unLinkedReels)

      } else {
        let unLinkedReels = instaReelsData?.filter((links) => links?.status === "" && (moment(links?.timestamp).isBetween
          (moment(startDate), moment(endDateNew))))


        setSortedReels(unLinkedReels)

      }
    }
    else if (sortBy === "likes") {
      let likedReels = [...instaReelsData];
      likedReels?.sort((a, b) => {
        return (
          b.like_count - a.like_count
        )
      })

      let DateLikes = likedReels.filter((reel) => {

        if (moment(reel?.timestamp).isBetween
          (moment(startDate), moment(endDateNew))) {
          return reel
        }
      })

      setSortedReels(DateLikes)

    }
    else if (sortBy === "comments") {
      let commentReels = [...instaReelsData];
      commentReels?.sort((a, b) => {
        return (
          b.comments_count - a.comments_count
        )
      })


      let DateComments = commentReels.filter((reel) => {

        if (moment(reel?.timestamp).isBetween
          (moment(startDate), moment(endDateNew))) {
          return reel
        }
      })
      setSortedReels(DateComments)

    }
    else {

      let dateReels = instaReelsData?.filter((reel) => (moment(reel?.timestamp).isBetween
        (moment(startDate), moment(endDateNew))))

      setSortedReels(dateReels)

    }
    setTimeout(() => {
      setLoading(false)
    }, 200);
  };

  const handleReset = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 200);
    setSortBy()
    setStatus()
    setStartDate(fromDate);
    setEndDate(toDate);
    setEndDateNew(toDateNew);
    setCurrentPage(0)
    let dateReels = instaReelsData?.filter((reel) => (moment(reel?.timestamp).isBetween
      (moment(fromDate), moment(toDate))))

    setSortedReels(dateReels)
  };

  const handleStatus = (value) => {
    setStatus(value);
  };

  const handleSort = (value) => {
    setSortBy(value);
  };

  return (
    <div className="container-fluid p-0 ">
      {instaReelsData?.length ?
        <div className="row w-20-desktop-main w-25-desktop-main align-items-center">
          <div className="col-xl-3 col-md-6 col-12 mb-5">
            <p className="mb-5rem">Select Start Date / End Date</p>
            <RangePicker
              size="large"
              className="w-100"
              key={4}
              value={
                startDate && endDateNew ? [moment(startDate), moment(endDateNew)] : []
              }
              allowClear={false}
              ranges={{
                // "All Time": [
                //   moment().subtract(4, 'year').startOf("year"),
                //   moment(),
                // ],
                "Last Year": [
                  moment().subtract(1, 'year').startOf("year"),
                  moment().subtract(1, 'year').endOf("year"),
                ],
                "YTD": [moment().startOf("year"), moment()],
                "Last 30 Days": [moment().subtract(30, "days"), moment()],
                "Last 7 Days": [moment().subtract(7, "days"), moment()],
                "Today": [moment(), moment()],
                // "Last Year": [
                //   moment().startOf("year"),
                //   moment().subtract(1, "year").endOf("year"),
                // ],
                // "This Year": [moment().startOf("year"), moment().endOf("year")],
                // "Last Month": [
                //   moment().subtract(1, "month").startOf("month"),
                //   moment().subtract(1, "month").endOf("month"),
                // ],
                // "This Month": [
                //   moment().startOf("month"),
                //   moment().endOf("month"),
                // ],
              }}
              format={dateFormat}
              onChange={dateRangePickerChanger}
            />
          </div>
          <div className="col-xl-3 col-md-6 col-12 mb-5">
            <p className="mb-5rem">Status</p>
            <Select
              className="w-100 select-style dark-field"
              placeholder="Select Status"
              size="large"
              // focus={false}
              // onBlur={false}
              onChange={handleStatus}
              value={status}
            // defaultValue="date"
            >
              {/* <Option value="date">Date</Option> */}
              <Option value="linked">Linked</Option>
              <Option value="unlinked">UnLinked</Option>
            </Select>
          </div>
          <div className="col-xl-3 col-md-6 col-12 mb-5">
            <p className="mb-5rem">Sort By</p>
            <Select
              className="w-100 select-style dark-field"
              placeholder="Select Sort By"
              size="large"
              // focus={false}
              // onBlur={false}
              onChange={handleSort}
              value={sortBy}
            // defaultValue="date"
            >
              <Option value="likes">Likes</Option>
              <Option value="comments">Comments</Option>
            </Select>
          </div>
          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
            <Button
              onClick={handleSearch}
              className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Search
            </Button>
            <Button
              onClick={handleReset}
              className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Reset
            </Button>
          </div>
        </div>
        : null
      }

      {renderContent()}

      {currentPosts?.length > 0 && !loading && (
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          pageClassName="page-item "
          pageLinkClassName="page-link custom-paginate-link btn btn-primary"
          previousClassName="page-item"
          previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
          nextClassName="page-item"
          nextLinkClassName="page-link custom-paginate-next btn btn-primary"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
          pageCount={pgNum?.length}
          marginPagesDisplayed={2}
          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
          onPageChange={handlePageClick}
          containerClassName={
            "pagination justify-content-center mt-2 custom-paginate review-paginate"
          }
          activeClassName={"active"}
        />

      )}

    </div>
  );
}
function mapStateProps({ instaReels }) {
  return { instaReels };
}
export default connect(mapStateProps, {
  ...instaReelsAction,
})(LinkReels);