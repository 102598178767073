import { PROFILE_BIO } from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

export const updateProfileBio = (name, bio, website_address, id, cover_color) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .put(`users/revise/profilefields/${id}`, { name, bio, website_address }, {
      })
      .then((res) => {
        dispatch({
          type: PROFILE_BIO,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};
