import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  DatePicker,
  Spin,
  Select,
  Empty,
  Table,
  Image,
  Carousel,
  Tag,
  Space,
  Checkbox,
  Affix,
  Tooltip,
  AutoComplete
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faSort, faSortDown, faSortUp, faXmark, faAngleLeft, faAngleRight, faPrint, faDownload } from "@fortawesome/free-solid-svg-icons";
import defaultImage from "../../images/user.png";
import {
  searchSku,
  searchActiveSkus,
  searchAllSkus,
  searchBySkuAndTitle,
  addToOrme,
} from "../../redux/actions/inventoryActive.action";
import "../../css/inventory.scss";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image as ImageA } from "@react-pdf/renderer";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

export default function Active() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { getInventoryProducts, getInventoryProductsBySkuAndTitle, validate, getProduct } = useSelector((state) => {
    return state;
  });

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataDetails, setDataDetail] = useState("");
  const [filterSKU, setFilterSKU] = useState(null);
  const [filterTitle, setFilterTitle] = useState();
  const [searchSKUs, setSearchSKUs] = useState([]);
  const [searchSKUsResult, setSearchSKUsResult] = useState([]);
  const [searchSKUsTotalData, setSearchSKUsTotalData] = useState([]);
  const [searchTitles, setSearchTitles] = useState([]);
  const [tempSKU, setTempSKU] = useState();
  const [tempTitle, setTempTitle] = useState();
  const [skuLoad, setSkuLoad] = useState(false);
  const [titleLoad, setTitleLoad] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [invSort, setInvSort] = useState("inventoryLowToHigh");
  const [ormeActive, setOrmeActive] = useState("active");
  const [ormeStatus, setOrmeStatus] = useState("active");
  const [sortOn, setSortOn] = useState("stock");
  const [sortBy, setSortBy] = useState("asc");
  const [variantData, setVariantData] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [pageLimit, setPageLimit] = useState(100);
  const [resetClick, setResetClick] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const [showActionColumn, setShowActionColumn] = useState(true);
  const [affixed, setAffixed] = useState(false);

  const limit = 100;



  // useEffect(() => {
  //   dispatch(searchBySkuAndTitle({ sku: "", sort_on: sortOn, sort: sortBy }, limit)).then((res) => {
  //     if (res.success) {
  //       setLoading(false);
  //       setSearchSKUs(res?.message);
  //     } else {
  //       console.log(res, "error");
  //       setLoading(false);
  //     }
  //   });
  // }, []);

  const handleDropdownVisibleChange = (visible) => {
    // If the dropdown is closing, clear the selected value
    if (!visible) {
      setFilterSKU(null);
    }
  };

  //   const handlePageClick = (e) => {
  //     console.log("e", e);
  //     const page = e.selected;
  //     setCurrentPage(page);
  //     setLoadd(true);
  //     setLoading(true);
  //     dispatch(getBoostedReviews(page + 1, limit, startDate, endDate, influencerId)).then((res) => {
  //       setLoading(false);
  //       setLoadd(false)
  //       setInfluencer("");
  //       setTotalEvent(res?.message?.total_records);
  //     });
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     setGroupByTable(banType)
  //     setStatus(true);
  //     dispatch(getBoostedReviews(currentPage + 1, limit, startDate, endDate, influencerId)).then((res) => {
  //       setLoading(false);
  //       setInfluencer("");
  //       setTotalEvent(res?.message?.total_records);
  //       setCurrentPage(0);
  //     });
  //     // setSubmit(groupBy);
  //   };

  const getStatus = (record) => {
    let status = record?.variants?.reduce((acc, item) => {
      return (acc = acc + item.inventory_quantity);
    }, 0);
    if (status > 70) {
      return (
        <div className="status-new text-success">
          {/* <div className="status-flag green"></div> */}
          High
          {/* <p>green</p> */}
        </div>
      );
    } else if (status >= 50 && status <= 70) {
      return (
        <div className="status-new text-warning-new">
          {/* <div className="status-flag yellow"></div> */}
          Medium
          {/* <p>yellow</p> */}
        </div>
      );
    } else if (status < 50) {
      return (
        <div className="status-new text-danger">
          {/* <div className="status-flag red"></div> */}
          Low
          {/* <p>red</p> */}
        </div>
      );
    } else {
      return null;
    }
  };

  const PDF = ({ item }) => {
    const styles = StyleSheet.create({
      page: {
        padding: 30,
      },
      container: {
        display: "flex",
        // flex: 1,
        flexDirection: 'row',
      },
      image: {
        marginBottom: 10,
      },
      marginBottom: {
        marginBottom: 10,
      },
      leftColumn: {
        flexDirection: 'column',
        width: 170,
        paddingTop: 30,
        paddingRight: 15,
      },
      footer: {
        fontSize: 12,
        textAlign: 'center',
        marginTop: 15,
        paddingTop: 5,
        borderWidth: 3,
        borderColor: 'gray',
        borderStyle: 'dashed',
        '@media orientation: landscape': {
          marginTop: 10,
        },
      },
      headerarea: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
      },
      detailColumn: {
        flexDirection: 'column',
        flexGrow: 9,
        textTransform: 'uppercase',
      },
      linkColumn: {
        flexDirection: 'column',
        flexGrow: 2,
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
      },
      hname: {
        fontSize: 16,
      },
      rightContainer: {
        // flex: 1,
        flexDirection: 'column',
        paddingLeft: 15,
        marginBottom: 10,
        paddingTop: 10,
      },
      text: {
        fontSize: 12,
        color: 'black',
      },
      headerContainer: {
        marginTop: 30,
        width: 380
      },
      date: {
        fontSize: 10,
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
      },
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
      tableRow: {
        margin: 'auto',
        flexDirection: 'row',
      },
      tableCellHead: {
        display: "flex",
        flexDirection: 'row',
        flexWrap: "wrap",
        // justifyContent: "flex-start",
        textAlign: "left",
        fontSize: 12,
        color: 'black',
        paddingLeft: 15,
      },
      tableCell: {
        margin: 'auto',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 10,
        padding: 3,
        borderWidth: 1,
        borderStyle: 'solid',
        justifyContent: "flex-start",
        textAlign: "left"
      },
      rowView: {
        display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8, paddingBottom: 8, textAlign: "left", fontSize: 10,
      }
    });
    const Table = ({ data }) => (
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>ID</Text>
          <Text style={styles.tableCell}>Name</Text>
          <Text style={styles.tableCell}>Age</Text>
        </View>
        {/* {data.map(item => (
          <TableRow {...item} />
        ))} */}
      </View>
    );
    const TableRow = ({ id, name, age }) => (
      <View style={styles.tableRow} key={id}>
        <Text style={styles.tableCell}>{id}</Text>
        <Text style={styles.tableCell}>{name}</Text>
        <Text style={styles.tableCell}>{age}</Text>
      </View>
    );
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerarea}>
            <View style={styles.detailColumn}>
              <Text style={styles.hname}>Worksheet - {item?.variants[0]?.sku} - {item?.title}</Text>
            </View>
            {/* <View style={styles.linkColumn}>
              <Text style={styles.date}>{moment(item?.created_at).utc().format("MM-DD-YYYY")}</Text>
            </View> */}
          </View>

          <View style={styles.container}>
            <View style={styles.leftColumn}>
              <ImageA
                src={item?.image?.src}
                style={styles.image}
              />
            </View>
            <View style={styles.headerContainer}>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>
                  Product Title : {item?.title}
                </Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product ID : {item?.id}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : {item?.variants[0]?.sku}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product Link :  {"https://" + item?.domain?.replace(/^https?:\/\//, '')}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product Price : ${item?.variants[0]?.price}</Text>
              </View>
              {
                item && item?.body_html?.length > 0 &&
                <View style={styles.rightContainer}>
                  <Text style={styles.text}>Product Description : {item && item?.body_html
                    ? dataDetails?.body_html.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                    )
                    : ""}</Text>
                </View>}
              {
                item && item?.tags?.length > 0 &&
                <View style={styles.rightContainer}>
                  <Text style={styles.text}>Product Tags : </Text>
                </View>}
              {
                item && item?.tags?.length > 0 &&
                <View style={styles.tableCellHead}>
                  {item && item?.tags?.split(",").map((e, i) => {
                    return <Text style={styles.tableCell}>{e}</Text>
                  })}
                </View>}
            </View>
          </View>


          {/* <Table data={item?.options} /> */}

          <View style={styles.marginBottom}>
            <Text style={styles.text}>
              Variants
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text style={{ width: '50' }}>S.#</Text>
            {item?.options?.map((it, i) => {
              return (
                <Text style={{ width: '150' }}>{it.name}</Text>
              );
            })}
            <Text style={{ width: '150' }}>Stock</Text>
            <Text style={{ width: '150' }}>Price</Text>
          </View>
          {item?.variants.map((rowData, index) => <>
            <View style={styles.rowView}>
              <Text style={{ width: '50' }}>{index + 1}</Text>
              {rowData.option1 && <Text style={{ width: '150' }}>{rowData.option1}</Text>}
              {rowData.option2 && <Text style={{ width: '150' }}>{rowData.option2}</Text>}
              {rowData.option3 && <Text style={{ width: '150' }}>{rowData.option3}</Text>}
              <Text style={{ width: '150' }}>{rowData.inventory_quantity}</Text>
              <Text style={{ width: '150' }}>${rowData.price}</Text>
            </View>
          </>)}

        </Page>
      </Document>
    );
  };

  const handlePageClick = (e) => {
    // setResetClick(false)
    const page = e.selected;

    setSkuLoad(true);
    setLoading(true);
    setCurrentPage(page);
    dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn ? sortOn : "stock", sort: sortBy ? sortBy : "asc", is_orme_product: ormeStatus === "active" ? true : false }, pageLimit, page + 1)).then(async (res) => {
      if (res?.success) {
        setSkuLoad(false);
        setLoading(false);
        setSearchSKUs(res?.message);
        setSearchSKUsResult(res?.message);
        // setSearchSKUsTotalData(res)
        // const hasMatchingId = selectedRowData.length > 0 && searchSKUs.every(sku => selectedRowData.some(row => row._id === sku._id));
        const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
        setSelectedAllData(hasMatchingId)
      } else {
        setSkuLoad(false);
        setLoading(false);
        // console.log(res, "error");
      }
    })
  };

  const showDetailModal = (s) => {
    setDataDetail(s);
    setVariantData(s.variants)
    setIsModalOpen(true);
  };
  //previous work
  // useEffect(() => {
  //   if (validate?.payload?.message?.salesforce || validate?.payload?.message?.shopify || validate?.payload?.message?.magento || validate?.payload?.message?.scoc || validate?.payload?.message?.seller_center) {
  //     setResetClick(false)
  //     console.log(searchSKUs, 'searchSKUs');
  //     if ((tempSKU || sortOn || sortBy || ormeActive) && !resetClick) {
  //       if (!sortOn && !sortBy) {
  //         setSortOn("stock");
  //         setSortBy("asc");
  //       }
  //       // console.log(resetClick, 'resetClick');
  //       const getData = setTimeout(() => {
  //         setSkuLoad(true);
  //         dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn, sort: sortBy, is_orme_product: ormeActive === "active" ? true : false }, pageLimit, 1)).then((res) => {
  //           setSkuLoad(false);
  //           if (res?.success) {
  //             setLoading(false);
  //             setCurrentPage(0);
  //             setResetClick(false)
  //             setShowActionColumn(ormeActive === "active" ? true : false)
  //             setSearchSKUs(res?.message);
  //             // console.log(hasMatchingId, 'hasMatchingId');
  //             const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
  //             setSelectedAllData(hasMatchingId)
  //           } else {
  //             console.log(res, "error");
  //             setLoading(false);
  //           }
  //         });
  //       }, 300);

  //       return () => clearTimeout(getData);
  //     }
  //   }
  // }, [tempSKU, sortOn, sortBy, resetClick, ormeActive]);
  //previous work

  useEffect(() => {
    if (validate?.payload?.message?.salesforce || validate?.payload?.message?.shopify || validate?.payload?.message?.magento || validate?.payload?.message?.scoc || validate?.payload?.message?.seller_center || validate?.payload?.message?.squarespace) {
      setResetClick(false)
      console.log(searchSKUs, 'searchSKUs');
      if ((tempSKU) && !resetClick) {
        // if (!sortOn && !sortBy) {
        //   setSortOn("stock");
        //   setSortBy("asc");
        // }
        // console.log(resetClick, 'resetClick');
        const getData = setTimeout(() => {
          setSkuLoad(true);
          dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn, sort: sortBy }, pageLimit, 1)).then((res) => {
            setSkuLoad(false);
            if (res?.success) {
              setLoading(false);
              setCurrentPage(0);
              setResetClick(false)
              // setShowActionColumn(ormeActive === "active" ? true : false)
              setSearchSKUs(res?.message);
              // console.log(hasMatchingId, 'hasMatchingId');
              // const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
              const hasMatchingId = checkAllExist(searchSKUsResult, selectedRowData);
              setSelectedAllData(hasMatchingId)
            } else {
              console.log(res, "error");
              setLoading(false);
            }
          });
        }, 300);

        return () => clearTimeout(getData);
      }
    }
  }, [tempSKU]);

  useEffect(() => {
    dispatch(searchBySkuAndTitle({ sku: "", sort_on: sortOn, sort: sortBy, is_orme_product: ormeActive === "active" ? true : false }, limit)).then((res) => {
      if (res.success) {
        setLoading(false);
        setSearchSKUs(res?.message);
        setSearchSKUsResult(res?.message);
        setSearchSKUsTotalData(res);
      } else {
        console.log(res, "error");
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    // if (ormeActive === "active") {
    setSelectedRowData([])
    setSelectedRowKeys([])
    // }
  }, [ormeStatus])
  useEffect(() => {
    const checkIfTop = () => {
      // Set affixed to false if the user has scrolled to the top of the page
      if (window.pageYOffset === 0) {
        setAffixed(false);
      }
    };

    window.addEventListener('scroll', checkIfTop);

    return () => {
      window.removeEventListener('scroll', checkIfTop);
    };
  }, []);
  useEffect(() => {
    const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
    setSelectedAllData(hasMatchingId)
  }, [selectedRowData, searchSKUs])
  const handleReset = (res) => {
    setResetClick(true)
    setLoading(true);
    setTempSKU();
    setTempTitle();
    setFilterSKU();
    setFilterTitle();
    setSortOn();
    setSortBy();
    setSortOn("stock");
    setSortBy("asc");
    setInvSort("inventoryLowToHigh");
    setResetKey(prevKey => prevKey + 1);
    setCurrentPage(0);
    setOrmeActive("active")
    setOrmeStatus("active")
    setShowActionColumn(false)
    setSelectedRowData([])
    setSelectedRowKeys([])
    dispatch(searchBySkuAndTitle({ sku: "", sort_on: "stock", sort: "asc", is_orme_product: true }, pageLimit, 1)).then((res) => {
      if (res.success) {
        setLoading(false);
        setSearchSKUs(res?.message);
        setSearchSKUsResult(res?.message);
        setSearchSKUsTotalData(res);
      } else {
        setLoading(false);
      }
    });
  };
  // console.log(selectedAllData, 'selectedAllData');
  // console.log(selectedRowData, 'selectedRowData');
  console.log(selectedRowData, 'selectedRowData');
  console.log(dataDetails, 'selectedRowData2');
  const addTOORME = (res) => {

    let data = selectedRowData.map((val) => {
      return (
        {
          id: val?._source?.id,
          is_orme_product: !val?._source?.is_orme_product
        }
      )
    })

    Swal.fire({
      title: `${ormeStatus === "active" ? `Are you sure you want to De-Activate ${selectedRowData.length} Product${selectedRowData.length > 1 ? "s" : ""} from ORME and remove from all related video content?` : `Are you sure you want to Activate ${selectedRowData.length} Product${selectedRowData.length > 1 ? "s" : ""} on ORME?`}`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: `var(--primary-color)`,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // setResetClick(true)
        // setTempSKU();
        // setTempTitle();
        // setFilterSKU();
        // setFilterTitle();
        // setSortOn();
        // setSortBy();
        // setInvSort("inventoryLowToHigh");
        // setResetKey(prevKey => prevKey + 1);
        // setCurrentPage(0);

        // setSpin(true);
        dispatch(addToOrme({ data })).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
            dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn ? sortOn : "stock", sort: sortBy ? sortBy : "asc", is_orme_product: ormeStatus === "active" ? true : false }, pageLimit, currentPage + 1)).then((res) => {
              if (res.success) {
                setSearchSKUs(res?.message);
                setSearchSKUsResult(res?.message);
                setSearchSKUsTotalData(res);
                setLoading(false);
              } else {
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
            setSelectedRowData([])
            setSelectedRowKeys([])
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });
      }
    });

  };
  const addSingleProductTOORME = (res) => {

    let data = [{
      id: dataDetails?.id,
      is_orme_product: !dataDetails?.is_orme_product
    }]


    Swal.fire({
      title: `${ormeStatus === "active" ? `Are you sure you want to De-Activate this Product from ORME and remove from all related video content?` : `Are you sure you want to Activate this Product on ORME?`}`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: `var(--primary-color)`,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // setResetClick(true)
        // setTempSKU();
        // setTempTitle();
        // setFilterSKU();
        // setFilterTitle();
        // setSortOn();
        // setSortBy();
        // setInvSort("inventoryLowToHigh");
        // setResetKey(prevKey => prevKey + 1);
        // setCurrentPage(0);

        // setSpin(true);
        dispatch(addToOrme({ data })).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
            setIsModalOpen(false)
            dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn ? sortOn : "stock", sort: sortBy ? sortBy : "asc", is_orme_product: ormeStatus === "active" ? true : false }, pageLimit, currentPage + 1)).then((res) => {
              if (res.success) {
                setSearchSKUs(res?.message);
                setSearchSKUsResult(res?.message);
                setSearchSKUsTotalData(res);
                setLoading(false);
              } else {
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
            setSelectedRowData([])
            setSelectedRowKeys([])
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });
      }
    });

  };
  const handleDeactive = (e) => {
    let data = [
      {
        id: e,
        is_orme_product: false
      }
    ]
    Swal.fire({
      title: `Are You Sure You Want To Deactive This Product?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: `var(--primary-color)`,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // setResetClick(true)
        // setTempSKU();
        // setTempTitle();
        // setFilterSKU();
        // setFilterTitle();
        // setSortOn();
        // setSortBy();
        // setInvSort("inventoryLowToHigh");
        // setResetKey(prevKey => prevKey + 1);
        // setCurrentPage(0);

        // setSpin(true);
        dispatch(addToOrme({ data })).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            // setTimeout(() => {
            dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn ? sortOn : "stock", sort: sortBy ? sortBy : "asc", is_orme_product: ormeStatus === "active" ? true : false }, pageLimit, 1)).then((res) => {
              if (res.success) {
                setLoading(false);
                setSearchSKUs(res?.message);
              } else {
                setLoading(false);
              }
            });
            // }, 500);

          } else {
            setLoading(false);
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });
      }
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOrmeStatus(ormeActive)
    setLoading(true);
    dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn, sort: sortBy, is_orme_product: ormeActive === "active" ? true : false }, pageLimit, 1)).then((res) => {
      setSkuLoad(false);
      if (res?.success) {
        setLoading(false);
        setCurrentPage(0);
        setResetClick(false)
        // setShowActionColumn(ormeActive === "active" ? true : false)
        setSearchSKUs(res?.message);
        setSearchSKUsResult(res?.message);
        setSearchSKUsTotalData(res);
        // console.log(hasMatchingId, 'hasMatchingId');
        // const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
        const hasMatchingId = checkAllExist(searchSKUsResult, selectedRowData);
        setSelectedAllData(hasMatchingId)
      } else {
        console.log(res, "error");
        setLoading(false);
      }
    });

    // dispatch(searchSku(1, limit, filterSKU, tempTitle)).then((res) => {
    //   if (res.success) {
    //     setLoading(false);
    //     setSearchSKUs(res?.message);
    //   } else {
    //     setLoading(false);
    //   }
    // });
    // dispatch(
    //     getInventory(
    //         pageLimit,
    //         1,
    //         "active",
    //         startDate,
    //         endDate,
    //         gender,
    //         category,
    //         subCategory,
    //         filterSKU,
    //         invSort,
    //         idType,
    //         searchID?.value
    //     )
    // ).then((res) => {
    //     setSpin(false);
    //     setCurrentPage(0);
    // });
  };

  const handleChange = (e) => {
    setInvSort(e)
    if (e === "inventoryLowToHigh") {
      setSortOn("stock");
      setSortBy("asc");
    } else if (e === "inventoryHighToLow") {
      setSortOn("stock");
      setSortBy("desc");
    } else if (e === "priceLowToHigh") {
      setSortOn("price");
      setSortBy("asc");
    } else if (e === "priceHighToLow") {
      setSortOn("price");
      setSortBy("desc");
    }
  };
  // console.log({ sortBy, sortOn });

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDataDetail("");
    setVariantData([]);
    setSortField(null);
    setSortDirection('asc');
  };

  const columnDetails = [
    {
      title: "Color",
      key: "option1",
      dataIndex: ["option1"],
      width: 40,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option1 ? record?.option1 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Wash",
      key: "option1",
      dataIndex: ["option1"],
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option2 ? record?.option2 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Inseam",
      key: "option1",
      dataIndex: ["option1"],
      width: 70,
      render: (text, record, index) => {
        return (
          <>
            <div className="">{record?.option3 ? record?.option3 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];

  function DefaultTable() {
    return (
      <>
        <div className="table-responsive scrollbar-style scrollable-table-data">
          <table className="transactions-box table">
            <thead className="table_heading">
              <tr>
                <th className="">S.#</th>
                <th className="">SKU</th>
                <th className="">Image</th>
                <th className="td-width-300">Title</th>
                <th className="">Price</th>
                <th className="">Stock</th>
                <th className="">Stock Level</th>
              </tr>
            </thead>
            <tbody>
              {(loading || skuLoad) ? (
                <div className="antd-loading-ift">
                  <Spin size="large"></Spin>
                </div>
              )
                : searchSKUsTotalData?.message?.length === 0 ||
                  searchSKUsTotalData?.length === 0 || searchSKUsTotalData?.message === "NULL" ? (
                  <tr>
                    <td colspan="12">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  searchSKUs?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1 + currentPage * limit}</td>
                        <td>{item?._source?.variants[0]?.sku}</td>
                        <td>
                          <div className="listing-image inventory-image">
                            {/* <img src={`${item?._source?.image?.src ? item?._source?.image?.src : defaultImage}`} /> */}
                            <Image
                              src={`${item?._source?.image?.src
                                ? item?._source?.image?.src
                                : defaultImage
                                }`}
                            />
                          </div>
                        </td>
                        <td
                          title={item?._source?.title?.replace(
                            /<\/?[^>]+(>|$)/g,
                            ""
                          )}
                        >
                          {item?._source?.title
                            ? item?._source?.title
                              .replace(/<\/?[^>]+(>|$)/g, "")
                              .slice(0, 100) + "..."
                            : ""}
                        </td>
                        {/* {console.log(
                          item?._source?.variants?.reduce((prevItem, currentItem) => {
                            return prevItem.price > currentItem.price ? prevItem : currentItem;
                          })
                        )} */}
                        <td>${item?._source?.variants[0]?.price}</td>
                        {/* <td>${item?._source?.variants && item?._source?.variants?.reduce((prevItem, currentItem) => {
                          return prevItem.price > currentItem.price ? prevItem : currentItem;
                        }).price}</td> */}
                        <td>
                          <div className="balance-area">
                            <div className="balance-count">
                              {item?._source?.variants?.reduce((acc, it) => {
                                return (acc = acc + it.inventory_quantity);
                              }, 0)}
                            </div>
                            <div className="balance-area">
                              <a
                                className="balance-btn default-btn outline small-btn"
                                onClick={() => {
                                  showDetailModal(item?._source);
                                }}
                              >
                                View
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>{getStatus(item?._source)}</td>
                      </tr>
                    );
                  })
                )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
  const handleCheckboxChange = (e, record) => {
    const key = record.key;
    const selectedKeys = [...selectedRowKeys];
    const selectedData = [...selectedRowData]; // Assuming selectedRowData is the state to store selected data

    if (e.target.checked) {
      selectedKeys.push(key);
      selectedData.push(record);
    } else {
      const index = selectedKeys.indexOf(key);
      if (index !== -1) {
        selectedKeys.splice(index, 1);
        selectedData.splice(index, 1);
      }
    }

    setSelectedRowKeys(selectedKeys);
    setSelectedRowData(selectedData); // Update the state with selected data
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // setSelectedAllData(false)
      const selectedKeysSet = new Set(selectedRowKeys);
      const newSelectedKeys = [...selectedRowKeys];
      const newSelectedData = [...selectedRowData];

      // Add all keys to the Set
      searchSKUs.forEach(it => selectedKeysSet.add(it._id));

      // Add new selected keys to the state
      selectedKeysSet.forEach(key => {
        if (!newSelectedKeys.includes(key)) {
          newSelectedKeys.push(key);
        }
      });

      // Add new selected data to the state
      searchSKUs.forEach(it => {
        if (!selectedRowData.some(row => row._id === it._id)) {
          newSelectedData.push(it);
        }
      });

      setSelectedRowKeys(newSelectedKeys);
      setSelectedRowData(newSelectedData);
      const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
      setSelectedAllData(hasMatchingId)
    } else {
      // setSelectedAllData(true)
      // Filter out the keys and data that are not in the current page
      const filteredKeys = selectedRowKeys.filter(key => !searchSKUs.some(item => item._id === key));
      const filteredData = selectedRowData.filter(item => !searchSKUs.some(it => it._id === item._id));

      setSelectedRowKeys(filteredKeys);
      setSelectedRowData(filteredData);
    }
  };
  function checkAllExist(searchSkus, selectedrow) {
    // If selectedrow is empty, return false
    if (selectedrow.length === 0) {
      return false;
    }

    // Check if every searchSKU exists in selectedrow
    // console.log({ searchSKUs, selectedRowData });
    return searchSkus.every(sku => selectedrow.some(row => row._id === sku._id));
  }
  const columns = [
    {
      title: 'S.No',
      align: "left",
      width: 20,
      render: (text, record, index) => {
        return (
          <>
            {(index + 1) + (currentPage * 100)}
          </>
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      align: "left",
      width: 150,
      render: (text, record, index) => {
        return (
          <>
            {record?._source?.variants[0]?.sku}
          </>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: "title",
      align: "left",
      width: 400,
      render: (text, record, index) => {
        return (
          <>
            <span title={record?._source?.title}>
              {
                record?._source?.title
                  ? record?._source?.title.length > 80 ? record?._source?.title
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .slice(0, 80) + "..."
                    : record?._source?.title
                  : ""
              }
            </span>
          </>
        );
      },
    },
    {
      title: 'Image',
      dataIndex: 'Image',
      key: "image",
      align: "left",
      render: (text, record, index) => {
        return (
          <div className="listing-image inventory-image text-center">
            <Image
              src={`${record?._source?.image?.src
                ? record?._source?.image?.src
                : defaultImage
                }`}
            />
          </div>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'Price',
      key: "price",
      align: "left",
      render: (text, record, index) => {
        return (
          <>
            {/* $ */}
            {numeral(record?._source?.variants[0]?.price).format('$0,0.00')}
          </>
        );
      },
    },
    {
      title: 'Stock',
      dataIndex: 'Stock',
      key: "stock",
      align: "left",
      width: 100,
      render: (text, record, index) => {
        return (
          <div className="balance-area justify-content-between ">
            <div className="balance-count  w-92">
              {numeral(record?._source?.variants?.reduce((acc, it) => {
                return (acc = acc + it.inventory_quantity);
              }, 0)).format('0,0')} {" "}
              {numeral(record?._source?.variants?.reduce((acc, it) => {
                return (acc = acc + it.inventory_quantity);
              }, 0)).format('0,0') < 0 && <Tag color="orange" className="m-0 fs-10">Pre Order</Tag>}
            </div>
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn ml-2"
                onClick={() => {
                  showDetailModal(record?._source);
                }}
              >
                View
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: "",
      align: "",
      width: 60,
    },
    {
      title: 'Stock Level',
      dataIndex: 'Stock Level',
      key: "stocklevel",
      align: "left",
      render: (text, record, index) => {
        return (
          <>
            {getStatus(record?._source)}
          </>
        );
      },
    },
    {
      title: (
        // ormeActive === "active" ? <Checkbox
        //   checked={searchSKUs.length > 0} defaultChecked disabled
        //   className="disable-checkbox"
        //   onChange={handleSelectAll}
        // />
        //   : 
        searchSKUs.length > 0 &&
        <>
          <span> Select All</span>
          <Tooltip placement="topLeft" title={getProduct && getProduct?.loading ? "Please wait while products are syncing" : ""}>
            <Checkbox
              disabled={getProduct && getProduct?.loading}
              checked={selectedAllData}
              onChange={handleSelectAll}
              className="select-all-checkbox ml-2"
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'select',
      key: 'select',
      align: "right",
      width: "100",
      render: (_, record) => {
        // if (record?._source?.is_orme_product) {
        //   return <Checkbox checked defaultChecked disabled className="disable-checkbox" />
        // } else {
        return (
          <Tooltip placement="left" title={getProduct && getProduct?.loading ? "Please wait while products are syncing" : ""}>
            <Checkbox
              className="active-inventory-checkbox"
              disabled={getProduct && getProduct?.loading}
              checked={selectedRowKeys.includes(record.key)}
              onChange={(e) => handleCheckboxChange(e, record)}
            />
          </Tooltip>
        )
        // }
      },
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'Action',
    //   key: "action",
    //   align: "center",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <div className="text-center">
    //           <a
    //             className="default-btn outline small-btn deactive-btn"
    //             onClick={(e) => {
    //               handleDeactive(record?._source?.id)
    //             }}
    //           >
    //             De-Active
    //           </a>
    //         </div>
    //       </>
    //     );
    //   },
    //   hidden: true,
    // },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    const uniqueSelectedRowKeys = new Set([...newSelectedRowKeys]);
    setSelectedRowKeys(Array.from(uniqueSelectedRowKeys));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const getSortIcon = (field) => {
    if (field !== sortField) return <FontAwesomeIcon icon={faSort} />;
    return sortDirection === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  };
  const sortData = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }

    const sortedData = [...variantData].sort((a, b) => {
      if (field === 'price') {
        return sortDirection === 'asc' ? a[field] - b[field] : b[field] - a[field];
      } else {
        return sortDirection === 'asc' ? a["inventory_quantity"] - b["inventory_quantity"] : b["inventory_quantity"] - a["inventory_quantity"];
      }
    });

    setVariantData(sortedData);
  };
  const handlePage = (link, menu) => {
    history(link, {
      state: { key: link.split("/").length > 2 ? link.split("/")[2] : link.split("/")[1], openKey: menu }
    }
    );
  }
  return (
    <>
      <div className="sales-page-main">
        <div className="title-area mb-3 pb-0 justify-content-between">
          <div className="d-flex align-items-center">
            <h1 className="mb-0 pb-0">Manage Ecom SKU</h1>
            {validate?.payload?.message?.last_sync && <span className="ml-2 fw-bold">( Last Sync: {moment(validate?.payload?.message?.last_sync).format("MM-DD-YYYY h:mm A")} )</span>}
          </div>
          {validate?.payload?.message?.salesforce || validate?.payload?.message?.shopify || validate?.payload?.message?.magento || validate?.payload?.message?.scoc || validate?.payload?.message?.seller_center || validate?.payload?.message?.squarespace ?
            <div className="mb-2 fw-bold">Total <span className="source_cap">{ormeStatus}</span> Products: {(getProduct && getProduct?.loading || loading) ? <span className="spin-color mx-2">
              <Spin size="small" />
            </span> : numeral(searchSKUsTotalData?.total).format("0,0")}</div> : <></>}

        </div>
        {
          !validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify && !validate?.payload?.message?.magento && !validate?.payload?.message?.scoc && !validate?.payload?.message?.seller_center && !validate?.payload?.message?.squarespace ?



            <div className="container-fluid">
              <div class="coming_iner">
                <h4 className="mb-4">Setup Ecommerce</h4>
                <div className="buttons-area">
                  {/* <Link
                to="/account/ecommerce"
                className="default-btn medium-btn fltr-hpr fw-normal"
              > */}
                  <Button
                    className="default-btn medium-btn fltr-hpr fw-normal"
                    onClick={() => handlePage("/account/ecommerce", "settings")}>

                    Ecommerce Setup
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
            :
            <>
              <div className="brand_container_main aff-payment">
                <Form>
                  <div className="row w-20-desktop-main">
                    <div className="col-xl-3 col-md-4 col-12 mb-15">
                      <p className="mb-5rem">Search SKU or Title</p>
                      <AutoComplete
                        listHeight={300}
                        dropdownMatchSelectWidth={false}
                        style={{ width: '100%' }}
                        size="large"
                        value={tempSKU}
                        options={searchSKUs && !skuLoad && searchSKUs?.map((item) => ({
                          value: item?._source?.title,
                          label: item?._source?.variants[0]?.sku + " - " + item?._source?.title + " - $" + item?._source?.variants[0]?.price,
                          // sku: item?.ProductSku,
                        }))}
                        onSearch={(e) => setTempSKU(e)}
                        // onChange={(e) => setFilterSkuField(e)}
                        onChange={(e) => setTempSKU(e)}
                        placeholder="Enter SKU"
                        notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                        loading={skuLoad}
                        filterOption={(inputValue, option) =>
                          option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
                        }
                      >
                        {/* {skuFieldLoader && <Spin size="small" />} */}
                      </AutoComplete>
                      {/* <Select

                        showSearch
                        size="large"
                        className="w-100"
                        key={resetKey}
                        type="Number"
                        notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                        loading={skuLoad}
                        optionFilterProp="children"
                        placeholder="Enter SKU or Title"
                        // suffixIcon={null}
                        onChange={(e) => setTempSKU(e)}
                        onSearch={(e) => setTempSKU(e)}
                        filterOption={(input, options) =>
                          options.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        focus={false}
                        // onChange={handleFilterSKU}
                        // value={filterSKU}
                        value={tempSKU}
                        onBlur={false}
                      // open={false} // for hide dropdown
                      // onDropdownVisibleChange={handleDropdownVisibleChange}
                      >
                        {searchSKUs &&
                          !skuLoad &&
                          searchSKUs?.map((item, i) => {
                            return <Option key={item?._id} value={item?._source?.title}>{item?._source?.variants[0]?.sku + " - " + item?._source?.title + " - $" + item?._source?.variants[0]?.price}</Option>;
                          })}
                      </Select> */}
                    </div>
                    {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Title</p>
                <Input
                  className="w-100"
                  placeholder="Enter Title"
                  size="large"
                  type="text"
                  value={tempTitle}
                  onChange={(e) => setTempTitle(e?.target?.value)}
                />
              </div> */}
                    <div className="col-xl-3 col-md-4 col-12 mb-15">
                      <p className="mb-5rem">Sort By</p>
                      <Select
                        className="w-100 select-style dark-field"
                        placeholder="Select Group By"
                        size="large"
                        focus={false}
                        loading={loading}
                        disabled={loading}
                        onBlur={false}
                        value={invSort}
                        onChange={(e) => handleChange(e)}
                        defaultValue="inventoryLowToHigh"
                      >
                        <Option value="inventoryLowToHigh">Stock - Low to High</Option>
                        <Option value="inventoryHighToLow">Stock - High to Low</Option>
                        <Option value="priceLowToHigh">Price - Low to High</Option>
                        <Option value="priceHighToLow">Price - High to Low</Option>

                      </Select>
                    </div>
                    <div className="col-xl-3 col-md-4 col-12 mb-15">
                      <p className="mb-5rem">ORME Products</p>
                      <Select
                        className="w-100 select-style dark-field"
                        placeholder="Select Group By"
                        size="large"
                        focus={false}
                        loading={loading}
                        disabled={loading}
                        onBlur={false}
                        value={ormeActive}
                        onChange={(e) => setOrmeActive(e)}
                        defaultValue={"active"}
                      >
                        <Option value="active">Active</Option>
                        <Option value="deactive">De-Active</Option>

                      </Select>
                    </div>
                    <div className="col-xl-4 col-md-12 col-12 d-flex align-items-end mb-15">
                      {/* <Button
                        onClick={handleReset}
                        className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                        type="primary"
                        htmlType="submit"
                        disabled={loading || skuLoad}
                      >
                        Refresh
                      </Button> */}
                      {/* <div className="mb-2 fw-bold">Total Products: {getInventoryProductsBySkuAndTitle?.total}</div> */}

                      <Button
                        onClick={handleSubmit}
                        className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                        type="primary"
                        htmlType="submit"
                      >
                        Search
                      </Button>
                      <Button
                        onClick={handleReset}
                        className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                        type="primary"
                        htmlType="submit"
                      >
                        Clear
                      </Button>
                    </div>
                    {/* <div className="col-xl-3 col-md-6 col-12 d-flex align-items-center">
                <span>Selected {selectedRowData.length} products</span>
              </div> */}
                  </div>
                  {
                    selectedRowData.length > 0 &&
                    <>
                      <Affix offsetTop={0}
                        className="affix-container"
                        // style={{ position: "absolute", top: 100, right: 30 }}
                        onChange={(affixed) => setAffixed(affixed)}
                      >
                        <div className={affixed ? 'affix-background' : ''}>
                          <span className="mb-10 mx-2">Selected {selectedRowData.length} products</span>
                          <Button
                            onClick={addTOORME}
                            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                            type="primary"
                            htmlType="submit"
                            disabled={loading || skuLoad}
                          >
                            {ormeStatus === "active" ? "De-Activate" : "Activate"}
                          </Button>
                        </div>
                      </Affix>
                    </>
                  }
                </Form>
                {/* <hr className="separator-line mt-0" /> */}
              </div>
              <div className="inventory-box-main">
                <div className="purchase-data referral-data">
                  {(loading) ? (
                    <div className="loading-wrap">
                      <span className="spinclass loader-center position-relative">
                        <Spin size="large" />
                      </span>
                    </div>
                  ) : (
                    <>
                      {/* <DefaultTable /> */}
                      {
                        // searchSKUs.length > 0 ?
                        <>
                          <Table
                            className={`active-table ${searchSKUsResult?.length > 8 && 'h-550'}`}
                            // rowSelection={rowSelection}
                            columns={columns}
                            // dataSource={(searchSKUsResult !== 'seller_center') ? searchSKUsResult?.map((it) => ({ ...it, key: it?._id })) : null}
                            dataSource={(searchSKUsResult !== 'seller_center') ? searchSKUsResult?.map((it) => ({ ...it, key: it?._id })) : null}
                            pagination={false}
                            scroll={{ x: 1200 }}
                            size="small"
                          // sticky={true}

                          />
                        </>
                        // :
                        // <></>
                      }
                      {searchSKUsTotalData?.total > 100 && (
                        //   <ReactPaginate
                        //     nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        //     onPageChange={handlePageClick}
                        //     pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                        //     // marginPagesDisplayed={2}
                        //     pageCount={
                        //       getInventoryProductsBySkuAndTitle?.total > 0 ? Math.ceil(getInventoryProductsBySkuAndTitle?.total / 100) : 0
                        //     }
                        //     forcePage={currentPage}
                        //     previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        //     pageClassName="page-item d-none"
                        //     pageLinkClassName="page-link"
                        //     previousClassName="page-item"
                        //     previousLinkClassName="page-link"
                        //     nextClassName="page-item"
                        //     nextLinkClassName="page-link"
                        //     breakLabel="..."
                        //     breakClassName="page-item d-none"
                        //     breakLinkClassName="page-link"
                        //     containerClassName={"pagination custom-paginate"}
                        //     activeClassName="active"
                        //     renderOnZeroPageCount={null}
                        //   />
                        <div style={{ position: 'relative' }}>
                          {/* Total page count element with positioning */}
                          <span style={{
                            position: 'absolute',
                            right: 90,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            paddingRight: '10px' // adjust as needed
                          }}>
                            Page {currentPage + 1} of {Math.ceil(searchSKUsTotalData?.total / 100)}
                          </span>

                          <ReactPaginate
                            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                            pageCount={searchSKUsTotalData?.total > 0 ? Math.ceil(searchSKUsTotalData?.total / 100) : 0}
                            forcePage={currentPage}
                            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                            pageClassName="page-item d-none"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item d-none"
                            breakLinkClassName="page-link"
                            containerClassName="pagination custom-paginate"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      )}
                      <Modal
                        maskClosable={false}
                        className="modal-generic modal-1300 product-detail active-inventory-modal"
                        centered
                        visible={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closeIcon={
                          // <FontAwesomeIcon icon={faXmark} style={{ marginTop: "20px" }} />
                          <Button
                            style={{ marginTop: "15px" }}
                            className="balance-btn default-btn outline small-btn"
                            // type="primary"
                            size="large"
                          // title="Download Worksheet"
                          >
                            <FontAwesomeIcon icon={faXmark} />
                            {/* Exit */}
                          </Button>
                        }
                        footer={false}
                        style={{
                          top: 55,
                        }}
                      >
                        <h2 className="modal-hd1 d-flex align-items-center justify-content-between">
                          <div>
                            Product Details
                          </div>
                          <div className="mr-6">
                            <Button
                              onClick={addSingleProductTOORME}
                              className="balance-btn default-btn outline small-btn mr-0"
                              type="primary"
                              size="large"
                              // title="Download Worksheet"
                              disabled={loading || skuLoad}
                            >
                              <FontAwesomeIcon
                                size="md"
                                className="event-icon"
                              // icon={faDownload}
                              // icon={faPrint}
                              />
                              {ormeStatus === "active" ? "De-Activate" : "Activate"}
                            </Button>
                            <PDFDownloadLink document={<PDF item={dataDetails} />} fileName={`worksheet-${dataDetails?.variants?.[0]?.sku}-${dataDetails?.title}.pdf`}>
                              <Button
                                className="balance-btn default-btn outline small-btn mr-3"
                                type="primary"
                                size="large"
                                title="Download Worksheet"
                              >
                                <FontAwesomeIcon
                                  size="md"
                                  className="event-icon"
                                  icon={faDownload}
                                // icon={faPrint}
                                />
                                Download</Button>
                            </PDFDownloadLink>
                          </div>
                        </h2>

                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <Carousel>
                              {dataDetails?.images?.map((item, i) => {
                                return (
                                  <div>
                                    <img
                                      src={`${item?.src ? item.src : defaultImage}`}
                                      className="img-fluid"
                                    />
                                  </div>
                                );
                              })}
                            </Carousel>
                          </div>
                          <div className="col-md-4 items-column">
                            <div className="product-items">
                              <span className="product-data">Product Title :</span>
                              <span className="product-desc">
                                {dataDetails?.title}
                              </span>
                            </div>
                            <div className="product-items">
                              <span className="product-data">Product ID :</span>
                              <span className="product-desc">
                                {dataDetails && dataDetails?.variants[0]?.product_id}
                              </span>
                            </div>
                            <div className="product-items">
                              <span className="product-data">Product SKU :</span>
                              <span className="product-desc">
                                {dataDetails && dataDetails?.variants[0]?.sku
                                  ? dataDetails?.variants[0]?.sku
                                  : "-"}
                              </span>
                            </div>
                            <div className="product-items">
                              <span className="product-data">Product Link:</span>
                              <span className="product-desc">
                                <a
                                  target="_blank"
                                  href={
                                    "https://" +
                                    dataDetails?.domain?.replace(/^https?:\/\//, '') +
                                    "/products/" +
                                    dataDetails?.handle
                                  }
                                >
                                  {"https://" + dataDetails?.domain?.replace(/^https?:\/\//, '') + "/..."}
                                </a>
                              </span>
                            </div>

                            <div className="product-items">
                              <span className="product-data">Product Price :</span>
                              <span className="product-desc">
                                ${dataDetails && dataDetails?.variants[0]?.price}
                              </span>
                            </div>
                            {dataDetails && dataDetails?.body_html?.length > 0 &&
                              <div className="product-items">
                                <span className="product-data">
                                  Product Description :
                                </span>
                                <span className="product-desc">
                                  <TextArea
                                    className="border-textarea-light"
                                    style={{
                                      height: 120,
                                      resize: 'none',
                                    }}
                                    rows={4}
                                    value={
                                      dataDetails && dataDetails?.body_html
                                        ? dataDetails?.body_html.replace(
                                          /<\/?[^>]+(>|$)/g,
                                          ""
                                        )
                                        : ""
                                    }
                                  />
                                </span>
                              </div>}
                            {dataDetails && dataDetails?.tags?.length > 0 &&
                              <div class="product-items">
                                <span className="product-data">Product Tags:</span>
                                <div className="product-desc prodcut-tags-scroll scrollbar-style">
                                  <Space size={[0, 8]} wrap>
                                    {dataDetails?.tags?.split(",")?.map((item, i) => {
                                      return <Tag>{item}</Tag>;
                                    })}
                                  </Space>
                                </div>
                              </div>}
                          </div>
                          <div className="col-md-5">
                            <div className="co mt3">
                              <h4>Variants</h4>

                              <div className="table-container inventory-table new-height">
                                <table class={`table m-0 ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                  <thead>
                                    <tr>
                                      <th className="width-15 border-bottom-0">S.#</th>
                                      {dataDetails?.options?.map((item, i) => {
                                        return (
                                          <th className="width-25 border-bottom-0">{item.name}</th>
                                        );
                                      })}
                                      <th className="width-25 border-bottom-0"
                                      // onClick={() => sortData('stock')}
                                      >
                                        Stock
                                        {/* {getSortIcon('stock')} */}
                                      </th>
                                      <th className="width-25 border-bottom-0"
                                      // onClick={() => sortData('price')}
                                      >
                                        Price
                                        {/* {getSortIcon('price')} */}
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                                <div className="table-body scrollbar-style">
                                  <table class={`table ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 6 ? "width-item-6" : ""}`}>
                                    <tbody>
                                      {variantData?.map((item, i) => {
                                        return (
                                          <tr>
                                            <td className="width-15">{i + 1}</td>
                                            {item.option1 && <td className="width-25">{item.option1}</td>}
                                            {item.option2 && (
                                              <td className="width-25">
                                                {item.option2}
                                              </td>
                                            )}
                                            {item.option3 && (
                                              <td className="width-25">
                                                {item.option3}
                                              </td>
                                            )}
                                            <td className="width-25">
                                              {item.inventory_quantity}
                                            </td>
                                            <td className="width-25">${item.price}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>


                              {/* <Table
                        pagination={false}
                        // columns={skuNewArr?.[viewIndex]}
                        columns={columnDetails}
                        size="small"
                        className="product-listing-tabl inventory-box-main details-table"
                        //  rowSelection={{ ...rowSelection }}
                        dataSource={dataDetails?.variants}
                        // scroll={
                        //     dataDetails.length > 32
                        //         ? { y: 450, x: 500 }
                        //         : false
                        // }
                        scroll={
                            dataDetails?.variants?.length > 10
                                ? { y: 400, x: 500 }
                                : { x: 500 }
                        }
                    /> */}
                            </div>
                          </div>
                        </div>


                      </Modal>

                    </>
                  )}
                </div>
              </div></>
        }
      </div>
    </>
  );
}
