import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
// import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import {
  Button,
  Form, Input, Modal, notification
} from 'antd';
import moment from 'moment';
import { Col, Row } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { addToReview } from '../../../../redux/actions/addToReview.action';
import * as tagActions from '../../../../redux/actions/tags';
import { getUserProfile } from '../../../../redux/actions/ugcUserProfile.action';

// import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  faPlus,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import GroupIcon from '@mui/icons-material/Group';
import Carousel from 'react-material-ui-carousel';
import CarouselIcon from '../../../../images/carouselIcon.svg';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const ExpandMore2 = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
var count = 10;
function Box({
  data,
  heading,
  monitorProfileUser,

}) {
  //const history = useHistory();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [videoIcon, setVideoIcon] = useState(false);
  const [isCheckedOpen, setIsCheckedOpen] = useState(false);
  const [infoDesc, setInfoDesc] = useState('');
  const [mediaId, setmediaId] = useState('');
  const [userId, setuserId] = useState('');
  const [infoTitle, setInfoTitle] = useState('');
  const [platform, setPlatform] = useState(false);
  const [influencer, setInfluencer] = useState("");
  const [influencerError, setInfluencerError] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [imageThumb, setImageThumb] = useState("");
  const [showAddInfModal, setShowAddInfModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [userExist, setUserExist] = useState();
  const [profileUsername, setUsername] = useState('');
  const [profileFollowerCount, setFollowers] = useState('');
  const [UserId, setUserId] = useState('');

  const childRef = useRef()
  const [form] = Form.useForm();
  form.setFieldsValue({ dm_message: "Dear Creator, your content tagging @brand has been used by the brand to sell on ormelive.com. You will earn a fee on products tagged to your content. Please login into ormelive.com to manage your account." });
  const { TextArea } = Input;

  function Pauseplay(e, id) {
    e.preventDefault();

    var testvideo = document.getElementById(id);

    if (testvideo.paused) {
      testvideo.play();
      setVideoIcon(true);
    } else {
      testvideo.pause();
      setVideoIcon(false);
    }
  }
  const handleExpandClick = () => {
    setExpanded2(false);
    setExpanded(!expanded);
  };
  const handleExpand2Click = () => {
    setExpanded(false);
    setExpanded2(!expanded2);
  };

  const onChangeTitle = (e) => {
    setInfoTitle(e.target.value);
  };

  const handleCancelInfo = () => {
    setIsCheckedOpen(false);
    setPlatform(false)
  };


  const closeInfluencerFalse = () => {
    setuserId("")
    setmediaId("")
    setUserExist()
    setUsername('')
    setFollowers('')
    setIsCheckedOpen(false);
  };


  const closeInfluencerTrue = () => {
    setIsCheckedOpen(false);
    form.resetFields();

  };



  const onFinishFalse = (values) => {

    setSaveLoading(true);
    dispatch(addToReview(heading, mediaId, UserId, profileUsername, profileFollowerCount)).then((res) => {
      if (res?.payload?.success) {
        notification.success({
          message: res?.payload?.message,
          className: 'toast-success',
        });
        setIsCheckedOpen(false);
        setuserId("")
        setmediaId("")
        setUserExist()
        setUsername('')
        setFollowers('')
        form.resetFields();
        childRef.current?.clear()
        setSaveLoading(false)
      } else {
        setSaveLoading(false);
        notification.error({
          message: res?.payload?.data?.message,
          className: 'toast-error',
        });
      }
    });

  }
  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num >= 1000) return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
  }


  const onFinishTrue = (values) => {
    setSaveLoading(true);
    setSaveLoading(false);
    setIsCheckedOpen(false);

    // dispatch(getCustomerRegister(values, monitorProfileUser?.message?.username)).then((res) => {
    //   if (res?.payload?.success) {
    //     notification.success({
    //       message: 'Record Added Successfully!',
    //       className: 'toast-success',
    //     });
    //     setIsCheckedOpen(false);
    //     setSaveLoading(false);
    //     setInstaStatus(false);
    //     setinstaData("");
    //     setInstaUsername("");
    //     form.resetFields();
    //     setInstaUploadData("")
    //     childRef.current?.clear()

    //   } else {
    //     setSaveLoading(false);
    //     notification.error({
    //       message: res?.payload?.data?.message,
    //       className: 'toast-error',
    //     });
    //   }
    // });


  }

  const onFinishFailedTrue = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinishFailedFalse = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  const onReset = () => {
    form.resetFields();
  };


  const onChangeDescription = (e) => {
    setInfoDesc(e.target.value);
  };


  const OpenSubmit = () => {
    setIsCheckedOpen(false);
  };

  const handleOkInfo = (val, name) => {
    setmediaId(val._id)
    setuserId(val.user_id)
    setSaveLoading(true);
    dispatch(getUserProfile({ username: name })).then((res) => {
      if (res.payload.success === true) {
        setIsCheckedOpen(true);
        setSaveLoading(false);
        setUserExist(res?.payload?.message?.user_exist)
        setUsername(res?.payload?.message?.username)
        setFollowers(res?.payload?.message?.followers_count)
        setUserId(res?.payload?.message?.user_id)
      }
    })

  };
  useEffect(() => {

  }, [monitorProfileUser])

  function renderMedia(item) {
    if (item?.media_type === 'IMAGE' || item?.media_type === 'CAROUSEL_ALBUM') {
      return (
        <>
          {item?.media_type === 'CAROUSEL_ALBUM' && (
            <button className="btn-link btn-play btn-carousel">
              <img
                src={CarouselIcon}
                alt="CarouselIcon"
                className="carouselsvg"
              />
            </button>
          )}
          {item?.children === false ? (
            <CardMedia
              component="img"
              height="400"
              sx={{ objectFit: 'cover', borderRadius: 2 }}
              image={item.media_url}
              alt="Paella dish"
            />
          ) : item?.children?.data[0]?.media_type === 'VIDEO' ? (
            <>
              <button
                onClick={(e) => Pauseplay(e, item?.children?.data[0]?.id)}
                className="btn-link btn-play"
              >
                {!videoIcon ? (
                  <i class="fa fa-play" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-pause" aria-hidden="true"></i>
                )}
              </button>
              <CardMedia
                component="video"
                sx={{ objectFit: 'cover', borderRadius: 2 }}
                autoPlay={false}
                controls
                //loop
                height="400"
                image={item?.children?.data[0].media_url}
                alt="Paella dish"
                id={item?.children?.data[0].id}
              />
            </>
          ) : (

            <CardMedia
              component="img"
              height="400"
              sx={{ objectFit: 'cover', borderRadius: 2 }}
              image={
                item.media_type === 'CAROUSEL_ALBUM'
                  ? item.children?.data[0].media_url
                  : item.media_url
              }
              alt="Paella dish"
            />

          )}
        </>
      );
    }
    if (item.media_type === 'VIDEO' || item.media_type === 'CAROUSEL_ALBUM') {
      return (
        <>
          <button
            onClick={(e) => Pauseplay(e, item._id)}
            className="btn-link btn-play"
          >
            {!videoIcon ? (
              <i class="fa fa-play" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-pause" aria-hidden="true"></i>
            )}
          </button>
          <CardMedia
            component="video"
            sx={{ objectFit: 'cover', borderRadius: 2 }}
            autoPlay={false}
            controls
            //loop
            height="400"
            image={item.media_url}
            alt="Paella dish"
            id={item._id}
          />
        </>
      );
    }
    return null;
  }

  function renderCarousel(item) {
    return (
      <Carousel
        className="cr-album"
        navButtonsAlwaysVisible={true}
        navButtonsProps={{
          style: {
            backgroundColor: 'rgba(87, 87, 87, 0.4)',
          },
        }}
        indicators={false}
        swipe={true}
      >
        {item.children.data.map((it2, i) => {
          if (it2.media_type == 'IMAGE') {
            return (
              <a target="_blank" href={data.permalink}>
                <CardMedia
                  component="img"
                  height="400"
                  sx={{ objectFit: 'cover', borderRadius: 2 }}
                  image={it2.media_url}
                  alt="Paella dish"
                />
              </a>
            );
          }
          if (it2.media_type == 'VIDEO') {
            return (
              <a target="_blank" href={data.permalink}>
                <CardMedia
                  component="video"
                  sx={{ objectFit: 'cover', borderRadius: 2 }}
                  autoPlay={false}
                  controls
                  loop
                  height="400"
                  image={it2.media_url}
                  alt="Paella dish"
                />
              </a>
            );
          }
        })}
      </Carousel>
    );
  }

  return (
    <>
      <Card elevation={1}>
        {/* <CardHeader
          // avatar={
          //   <Avatar
          //     alt={data.username}
          //     src={data.userInfo?.business_discovery?.profile_picture_url}
          //   />
          // }

          className="card-head-main"
          action={
            <div

              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                // marginTop: 5,
              }}
            >
              {data.media_type === "VIDEO" ?
                <Typography
                  variant="h6"
                  className='ff-nunito'
                  style={{
                    color: 'black',
                    // marginTop: 3,
                    marginRight: 20
                  }}
                >
                  <Button className="default-btn medium-btn padb-3 w-initial br-radius"
                    onClick={() => handleOkInfo(data, data.username)}
                    loading={saveLoading}
                  >

                    {saveLoading ? (null) :
                      <FontAwesomeIcon icon={faPlus} />}
                  </Button>
                </Typography>
                : null}
              <GroupIcon sx={{ color: 'gray', fontSize: 25 }} />
              <Typography
                variant="h6"
                className='ff-nunito'
                style={{ color: 'gray', marginTop: 3, marginLeft: 5 }}
              >

                {data.userInfo?.business_discovery?.followers_count ? numeral(data.userInfo?.business_discovery?.followers_count).format('0a') : 0}
              </Typography>
            </div>
          }
          title={
            <Typography variant="body" color="$primary-color"
              className='ff-nunito'>
              <a
                style={{ color: '$primary-color', fontSize: 14, fontWeight: 'bold' }}
                target="_blank"
                href={`https://www.instagram.com/${data.username}`}
              >
                {"@" + data.username}
              </a>
            </Typography>
          }
        // subheader={`${new Date(data.timestamp).toLocaleDateString()}`}
        /> */}

        <div className="ugc-card-header">
          <div className="ugc-head-title">
            <Typography variant="body" color="$primary-color"
              className='ff-nunito'>
              <a
                style={{ color: '$primary-color', fontSize: 14, fontWeight: 'bold' }}
                target="_blank"
                href={`https://www.instagram.com/${data.username}`}
                maxLength={5}
              >
                @
                {data.username.length > 20
                  ? data.username.slice(
                    0,
                    20
                  ) + "..."
                  : data.username}
              </a>
            </Typography>
          </div>
          <div className="ugc-head-add-button">
            {data.media_type === "VIDEO" ?
              <Typography
                variant="h6"
                className='ff-nunito'
                style={{
                  color: 'black',
                  // marginTop: 3,
                  marginRight: 20
                }}
              >
                <Button className="default-btn medium-btn padb-3 w-initial br-radius"
                  onClick={() => handleOkInfo(data, data.username)}
                  loading={saveLoading}
                >

                  {saveLoading ? (null) :
                    <FontAwesomeIcon icon={faPlus} />}
                </Button>
              </Typography>
              : null}
          </div>
          <div className="ugc-head-followers">
            <GroupIcon sx={{ color: 'gray', fontSize: 25 }} />
            <Typography
              variant="h6"
              className='ff-nunito'
              style={{ color: 'gray', marginTop: 3, marginLeft: 5 }}
            >

              {data.userInfo?.business_discovery?.followers_count ? numberFormat(data.userInfo?.business_discovery?.followers_count) : 0}
            </Typography>
          </div>
        </div>


        <Divider />
        <div className="media-box-post" style={{ padding: '15px' }}>
          {data.media_type === 'CAROUSEL_ALBUM' ? (
            <a target="_blank" href={data.permalink}>
              {renderMedia(data)}
            </a>
          ) : (
            //renderCarousel(data)
            <a target="_blank" href={data.permalink}>
              {renderMedia(data)}
            </a>
          )}
        </div>
        <CardActions disableSpacing>
          {/* {!expanded ? ( */}
          <div
          // style={{
          //   display: "flex",
          //   justifyContent: "flex-end",
          //   // marginLeft: 10,
          //   alignItems: "center",
          //   flexGrow: 1,
          // }}
          >
            {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <ThumbUpIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                <Typography
                  variant="h6"
                  style={{ color: "#b3b3b3", marginTop: 3, marginLeft: 5 }}
                >
                  {numeral(data.like_count ? data.like_count : 0).format("0,0")}
                </Typography>
              </div> */}
            {/* <div
                style={{ width: 2, height: 20, backgroundColor: "lightgrey" }}
              /> */}
            {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                {data?.commentInfo ? (
                  <>
                    <ExpandMore2
                      expand={expanded2}
                      onClick={handleExpand2Click}
                      aria-expanded={expanded2}
                      aria-label="show more"
                    >
                      <CommentIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                      <Typography
                        variant="h6"
                        style={{
                          color: "#b3b3b3",
                          marginTop: 3,
                          marginLeft: 5,
                        }}
                      >
                        {numeral(
                          data.comments_count ? data.comments_count : 0
                        ).format("0,0")}
                      </Typography>
                    </ExpandMore2>
                  </>
                ) : (
                  <>
                    <CommentIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                    <Typography
                      variant="h6"
                      style={{ color: "#b3b3b3", marginTop: 3, marginLeft: 5 }}
                    >
                      {numeral(
                        data.comments_count ? data.comments_count : 0
                      ).format("0,0")}
                    </Typography>
                  </>
                )}
              </div> */}

            <Typography
              variant="body"
              sx={{ fontSize: '12px', marginLeft: '15px' }}
              color="gray"
              textAlign="center"
            >
              {moment(data.timestamp).utc()
                .format('YYYY-MM-DD')}
              {/* {new Date().toDateString()} */}
            </Typography>
          </div>
          {/*  ) : null} */}
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
        </CardActions>
        <Collapse in={expanded2} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>
              <span
                style={{
                  marginRight: 5,
                }}
              >
                <a
                  href={`https://www.instagram.com/${data?.commentInfo?.username}`}
                  target="_blank"
                  style={{
                    color: '#222',
                    fontWeight: 600,
                  }}
                  className="btn-link"
                >
                  {data?.commentInfo?.username}
                </a>
              </span>
              {data?.commentInfo?.text}
            </Typography>
          </CardContent>
        </Collapse>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>
              {data?.commentInfo ? data?.commentInfo?.text : data.caption}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 5,
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 15,
                }}
              >
                <ThumbUpIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                <Typography
                  variant="h6"
                  style={{ color: "#b3b3b3", marginTop: 3, marginLeft: 5 }}
                >
                  {numeral(data.like_count ? data.like_count : 0).format("0,0")}
                </Typography>
              </div> */}
              {/* <div
                style={{ width: 2, height: 20, backgroundColor: "lightgrey" }}
              /> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                {data?.commentInfo ? (
                  <>
                    <ExpandMore2
                      expand={expanded2}
                      onClick={handleExpand2Click}
                      aria-expanded={expanded2}
                      aria-label="show more"
                    >
                      <CommentIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                      <Typography
                        variant="h6"
                        style={{
                          color: "#b3b3b3",
                          marginTop: 3,
                          marginLeft: 5,
                        }}
                      >
                        {numeral(
                          data.comments_count ? data.comments_count : 0
                        ).format("0,0")}
                      </Typography>
                    </ExpandMore2>
                  </>
                ) : (
                  <>
                    <CommentIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                    <Typography
                      variant="h6"
                      style={{ color: "#b3b3b3", marginTop: 3, marginLeft: 5 }}
                    >
                      {numeral(
                        data.comments_count ? data.comments_count : 0
                      ).format("0,0")}
                    </Typography>
                  </>
                )}
              </div> */}
            </div>

            {/* <Typography
              variant="body"
              sx={{ fontSize: "14px", marginRight: "15px" }}
              color="gray"
              textAlign="right"
            >
              {new Date(data.timestamp).toDateString()}
            </Typography> */}
          </CardActions>
        </Collapse>
      </Card>






      <Modal
        className="modal-generic modal-500"
        centered
        visible={isCheckedOpen}
        // onOk={handleOk}
        onCancel={handleCancelInfo}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      >

        <h2 className="modal-hd1">
          {userExist == true ? 'Registered Creator' : ' UnRegistered Creator'}

        </h2>
        {userExist === true ?

          <Form
            name="addinfluencer"

            onFinish={onFinishFalse}
            onFinishFailed={onFinishFailedFalse}
            autoComplete="off"
            form={form}
          >
            <div class="add-influencer-form">
              <Row className="insta-profile mb-20 ml-0 mr-0">

                <Col className="influencer-modal-data-main">
                  <div className="influencer-pic">
                    <img
                      src={monitorProfileUser?.message?.profile_picture_url}
                      alt="profile"
                    />
                  </div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Name</h4><h3 class="count"> {monitorProfileUser?.message?.name}</h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Username</h4>
                    <a
                      title={monitorProfileUser?.message?.username}
                      className="inf-username"
                      target="_blank"
                      href={`https://www.instagram.com/${monitorProfileUser?.message?.username}`}
                    >
                      {monitorProfileUser?.message?.username}
                    </a>
                  </div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Followers</h4><h3 class="count"> {numberFormat(monitorProfileUser?.message?.followers_count)}</h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Following</h4><h3 class="count"> {numberFormat(monitorProfileUser?.message?.follows_count)}</h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Content Date</h4><h3 class="count">  {moment(data.timestamp).utc().format('YYYY-MM-DD')} </h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Content Status</h4><h3 class="count"> {userExist == true ? data?.linked == true ? 'ADDED' : 'NEW' : 'New'}  </h3></div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Message"
                    className="mb-40"
                    initialValue={
                      form.getFieldValue().dm_message
                    }
                    name="dm_message"
                  >
                    <TextArea
                      showCount
                      maxLength={600}
                      // onChange={onChange}
                      className="dark-field"

                      autoSize={{ minRows: 4, maxRows: 6 }}
                    // onFocus={() => setActiveKey('3')}
                    />
                  </Form.Item>
                  {/* <p className="text-center">
                    Dear Creator, your content tagging @brand has been used by the brand to sell on ormelive.com. You will earn a fee on products tagged to
                    your content. Please login into ormelive.com to manage your account.
                  </p> */}
                </Col>
              </Row>
              <div className="separator-line mb-15"></div>
              <div className="d-flex justify-content-between">

                {data?.linked == true ? null :
                  <Button
                    className="default-btn d-flex align-items-center justify-content-center min-w-initial-res  res-btn1"
                    type="primary"
                    size="large"
                    htmlType="submit"
                  // loading={saveLoading}
                  >
                    Add Review
                  </Button>
                }

                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center min-w-initial-res  res-btn1"
                  onClick={closeInfluencerFalse}
                >
                  Exit
                </Button>

              </div>
            </div>

          </Form>
          :


          <Form
            name="addinfluencer"

            onFinish={onFinishFalse}
            onFinishFailed={onFinishFailedFalse}
            autoComplete="off"
            form={form}
          >
            <div class="add-influencer-form">
              <Row className="insta-profile mb-20 ml-0 mr-0">

                <Col className="influencer-modal-data-main">
                  <div className="influencer-pic">
                    <img
                      src={monitorProfileUser?.message?.profile_picture_url}
                      alt="profile"
                    />
                  </div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Name</h4><h3 class="count"> {monitorProfileUser?.message?.name}</h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Username</h4>
                    <a
                      title={monitorProfileUser?.message?.username}
                      className="inf-username"
                      target="_blank"
                      href={`https://www.instagram.com/${monitorProfileUser?.message?.username}`}
                    >
                      {monitorProfileUser?.message?.username}
                    </a>
                  </div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Followers</h4><h3 class="count"> {numberFormat(monitorProfileUser?.message?.followers_count)}</h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Following</h4><h3 class="count"> {numberFormat(monitorProfileUser?.message?.follows_count)}</h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Content Date</h4><h3 class="count">  {moment(data.timestamp).utc().format('YYYY-MM-DD')} </h3></div>
                  <div class="Influencer-listing-box"><h4 class="count-title">Content Status</h4><h3 class="count"> {monitorProfileUser?.message?.user_exist == true ? data?.linked == true ? 'Added' : 'New' : 'New'}  </h3></div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Message"
                    className="mb-40"
                    initialValue={
                      form.getFieldValue().dm_message
                    }
                    name="dm_message"
                  >
                    <TextArea
                      showCount
                      maxLength={600}
                      // onChange={onChange}
                      className="dark-field"

                      autoSize={{ minRows: 4, maxRows: 6 }}
                    // onFocus={() => setActiveKey('3')}
                    />
                  </Form.Item>
                  {/* <p className="text-center">
                    Dear Creator, your content tagging @brand has been used by the brand to sell on ormelive.com. You will earn a fee on products tagged to
                    your content. Please login into ormelive.com to manage your account.
                  </p> */}
                </Col>
              </Row>
              <div className="separator-line mb-15"></div>
              <div className="d-flex justify-content-between">

              <Button
                  className="default-btn d-flex align-items-center justify-content-center min-w-initial-res"
                  type="primary"
                  size="large"
                  htmlType="submit"
             
                // loading={saveLoading}
                >
                  Add Review
                </Button>
                {data?.linked == true ? null :
                  <Button
                    className="default-btn d-flex align-items-center justify-content-center min-w-initial-res"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    
                    disabled={true}
                  // loading={saveLoading}
                  >
                    Send DM
                  </Button>
                }
               
                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center min-w-initial-res"
                  onClick={closeInfluencerFalse}
                >
                  Exit
                </Button>

              </div>
            </div>
          </Form>

        }






      </Modal >



    </>
  );
}

function mapStateToProps({ monitorProfileUser }) {
  return { monitorProfileUser };
}

export default connect(mapStateToProps, tagActions)(Box);