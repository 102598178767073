import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import {
    faAngleLeft,
    faAngleRight,
    faXmark,
    faCalendarDays,
    faClock,
    faEye,
    faDollar,
    faCartFlatbed,
    faCircleInfo,
    faBullhorn,
    faThumbsUp,
    faPlay,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import {
    Spin,
    Button,
    Form,
    Input,
    Upload,
    message,
    Select,
    notification,
    Avatar,
    DatePicker,
    Empty,
    Modal as Modala,
    InputNumber,
    Menu,
    Dropdown
} from "antd";
import { Player, LoadingSpinner } from "video-react";
import "video-react/dist/video-react.css";
import { UserOutlined } from "@ant-design/icons";
import numeral from "numeral";
import GroupIcon from "@mui/icons-material/Group";
import ReactPlayer from "react-player";
import LoadingSpin from "../../reUsable/loadingSpin";
import * as savedCategoriesAction from "../../redux/actions/newCategory.action";
import * as boostActions from "../../redux/actions/boostReview.action";
import * as feeStructuresAction from "../../redux/actions/feestructure.action";
import { useNavigate } from "react-router";
import { searchReviewID } from "../../redux/actions/eventID.action";
import { getInfluencers } from "../../redux/actions/influencerNew.action";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

function CreateCompaign({
    getPromotedReviewControl,
    getPromotedReviewControlData,
    reviewPublish,
    getAllCreator,
    getAllCreatorData,
    getSavedGeneralCategories,
    getSavedCategories,
    getSavedSubCategories,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
    reviewOrders,
    orderReview,
    detailsReviewSec,
    detailsReview,
    detailsOrder,
    deBoostReview,
    boostReview,
    updateBoostReview,
    getBoostDetails,
    getAdCost,
    getStructureFees,
    getFeeStructure,
    title,
    getImpressionCriteria
}) {
    const childRef = useRef();
    const { RangePicker } = DatePicker;
    const {
        getAllInfluencers, getPromotionImpression
    } = useSelector((state) => {
        return state;
    });
    // const [formLink] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [publishLoading, setPublishLoading] = useState(false);

    const toDate = moment(new Date()).format("YYYY-MM-DD");
    const fromDate = moment().startOf("year").format("YYYY-MM-DD");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [boostStartDate, setBoostStartDate] = useState(null);
    const [boostEndDate, setBoostEndDate] = useState(null);
    const [influencerId, setInfluencerId] = useState();
    const [influencer, setInfluencer] = useState("");
    const [sortBy, setSortBy] = useState("date");
    const [videoType, setVideoType] = useState("all");
    const [boostType, setBoostType] = useState("all");
    const [gender, setGender] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [generalLoad, setGeneralLoad] = useState(false);
    const [catLoad, setCatLoad] = useState(false);
    const [subCatLoad, setSubCatLoad] = useState(false);
    const [modalTable, setModalTable] = useState(false);
    const [order, setOrder] = useState([]);
    const [totalEvent, setTotalEvent] = useState();
    const [currentPageTable, setCurrentPageTable] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [modal, setModal] = useState(false);
    const [reviewID, setReviewID] = useState();
    const [reviewIDLoad, setReviewIDLoad] = useState("");
    const [boostUpdate, setBoostUpdate] = useState("Create Campaign");
    const [days, setDays] = useState(0);
    const [boostReviewID, setBoostReviewID] = useState("");
    const [boostID, setBoostID] = useState("");
    const [reviewLoad, setReviewLoad] = useState(false);
    const [isModalOpenSocial, setIsModalOpenSocial] = useState(false);
    const [searchReviewIDs, setSearchReviewIDs] = useState([]);
    const [adCost, setAdCost] = useState(0);
    const [adName, setAdName] = useState("");
    const [boostStatus, setBoostStatus] = useState("");
    const [btnLoader, setBtnLoader] = useState(false);
    const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);
    const limit = 8;
    const limitPg = 15;
    const upperLimit = (currentPageTable + 1) * limitPg;
    const roleKey = localStorage.getItem("Role");
    const history = useNavigate();
    const { confirm } = Modala;

    useEffect(() => {
        if (boostStartDate && boostEndDate) {
            setDays(Math.abs(boostStartDate.diff(boostEndDate, 'days') - 1))
        }

        if (!boostStartDate) {
            setBoostEndDate(null)
        }
    }, [boostStartDate, boostEndDate])

    useEffect(() => {
        dispatch(getInfluencers()).then((res) => {
        })
        setGeneralLoad(true);
        setReviewIDLoad(true)
        dispatch(searchReviewID("", "published")).then((res) => {
            setReviewIDLoad(false)
            if (res?.success) {
                setSearchReviewIDs(res?.message)
            }
        })
        getSavedGeneralCategories().then((res) => {
            if (res.success) {
                setGeneralLoad(false);
            } else {
                setGeneralLoad(false);
            }
        });

        getPromotedReviewControl(
            videoType === "user" ? true : videoType === "brand" ? false : undefined,
            1,
            limit,
            "published",
            startDate,
            endDate,
            influencerId,
            gender,
            category,
            subCategory,
            sortBy,
            boostStatus,
            undefined,
            title === "all" ? undefined : title,
            videoType === "without_product" ? true : undefined
        ).then(() => setLoading(false));

        // getAdCost().then((res) => {
        //     if (res?.success) {
        //         setAdCost(res?.AdCost?.review_cost)
        //     }
        // })

        getAllCreator().then((res) => {
            if (res.payload.success) {
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        });
        getImpressionCriteria().then((res) => {
            // if (res.payload.success) {
            //     // setLoading(false);
            // } else {
            //     // setLoading(false);
            // }
        });
    }, []);
    useEffect(() => {
        setCurrentPage(0)
        setLoading(true)
        getPromotedReviewControl(
            videoType === "user" ? true : videoType === "brand" ? false : undefined,
            1,
            limit,
            "published",
            startDate,
            endDate,
            influencerId,
            gender,
            category,
            subCategory,
            sortBy,
            boostStatus,
            undefined,
            title === "all" ? undefined : title,
            videoType === "without_product" ? true : undefined
        ).then((res) => {
            // setTimeout(() => {
            setLoading(false)
            // }, 1000);
        });
    }, [title])

    const TableClose = () => {
        setModalTable(false);
        setOrder();
        setCurrentPageTable(0);
        setTotalEvent();
    };

    const detailView = (orderDetail) => {
        setModal(true);
        detailsReviewSec(orderDetail).then((res) => {
            setTotalEvent(res?.payload?.message?.total_records);
        });
    };

    const onDemote = (item) => {
        confirm({
            title: "Are you sure you want to De-Active the campaign?",
            content:
                "De-Activing it will remove it from Sponsored videos.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                setBtnLoader(true)
                deBoostReview(item?.promoted?._id).then((res) => {
                    if (res?.success) {
                        console.log(res, "res");

                        setLoading(true)
                        setCurrentPage((getPromotedReviewControlData?.data?.length === 1 && currentPage !== 1) ? currentPage - 1 : currentPage)
                        getPromotedReviewControl(
                            videoType === "user" ? true : videoType === "brand" ? false : undefined,
                            (getPromotedReviewControlData?.data?.length === 1 && currentPage !== 0) ? currentPage : currentPage + 1,
                            limit,
                            "published",
                            startDate,
                            endDate,
                            influencerId,
                            gender,
                            category,
                            subCategory,
                            sortBy,
                            boostStatus,
                            undefined,
                            title,
                            videoType === "without_product" ? true : undefined
                        ).then(() => setLoading(false));
                        notification.success({
                            message: res?.message,
                            className: "toast-success",
                        });
                        setBtnLoader(false)
                    }
                    else {
                        notification.error({
                            message: res?.response?.data?.message,
                            className: "toast-error",
                        });
                        setBtnLoader(false)
                        // if (res?.response?.data?.message?.includes('Failed! card has not enough balance')) {

                        //     Swal.fire({
                        //         title: 'Payment Error',
                        //         text: res?.response?.data?.message,
                        //         icon: 'warning',
                        //         showCancelButton: false,
                        //         confirmButtonColor: '$primary-color',
                        //         cancelButtonColor: '#d33',
                        //         confirmButtonText: 'Ok',
                        //         allowOutsideClick: false
                        //     }).then((result) => {
                        //         if (result.isConfirmed) {
                        //             setLoading(true);
                        //             getPromotedReviewControl(
                        //                 undefined,
                        //                 1,
                        //                 limit,
                        //                 "published",
                        //                 undefined,
                        //                 undefined,
                        //                 "",
                        //                 "",
                        //                 "",
                        //                 "",
                        //                 "date",
                        //                 "",
                        //                 undefined,
                        //                 title === "all" ? undefined : title,
                        //                 undefined
                        //             ).then(() => {
                        //                 setLoading(false);
                        //                 setInfluencer("");
                        //                 setGender(null);
                        //                 setCategory(null);
                        //                 setSubCategory(null);
                        //             });
                        //         } else {
                        //         }
                        //     });
                        //     setBtnLoader(false)
                        // } else {
                        //     notification.error({
                        //         message: res?.response?.data?.message,
                        //         className: "toast-error",
                        //     });
                        //     setBtnLoader(false)
                        // }
                    }


                }).catch((err) => {
                    console.log(err, 'err');

                })
            }
        })





    }

    const onBoostClick = (item) => {
        setBoostReviewID(item?._id)
        setBoostID(item?.promoted?._id)
        setIsModalOpenSocial(true)
    }

    const reviewBoost = () => {
        // setBoostUpdate("Boosting...")
        boostReview(boostReviewID, 1 * days, days, moment(boostStartDate).format("YYYY-MM-DD"), moment(boostEndDate).format("YYYY-MM-DD"), adCost, adName).then((res) => {

            if (res.success) {
                // setBoostUpdate("Boost")
                setLoading(true)
                setCurrentPage((getPromotedReviewControlData?.data?.length === 1 && currentPage !== 1) ? currentPage - 1 : currentPage)
                getPromotedReviewControl(
                    videoType === "user" ? true : videoType === "brand" ? false : undefined,
                    (getPromotedReviewControlData?.data?.length === 1 && currentPage !== 0) ? currentPage : currentPage + 1,
                    limit,
                    "published",
                    startDate,
                    endDate,
                    influencerId,
                    gender,
                    category,
                    subCategory,
                    sortBy,
                    boostStatus,
                    undefined,
                    title === "all" ? undefined : title,
                    videoType === "without_product" ? true : undefined
                ).then(() => setLoading(false));
                notification.success({
                    message: res?.message,
                    className: "toast-success",
                });
                setIsModalOpenSocial(false)
                setBoostEndDate(null)
                setBoostStartDate(null)
                setDays(0)
            } else {
                // setBoostUpdate("Boost")
            }
        })
    }
    const reviewUpdateBoost = () => {
        setBoostUpdate("Updating...")
        updateBoostReview(boostReviewID, 1 * days, days, moment(boostStartDate).format("YYYY-MM-DD"), moment(boostEndDate).format("YYYY-MM-DD"), adCost, adName, boostID).then((res) => {
            if (res.success) {
                // setBoostUpdate("Boost")
                setLoading(true)
                setCurrentPage((getPromotedReviewControlData?.data?.length === 1 && currentPage !== 1) ? currentPage - 1 : currentPage)
                getPromotedReviewControl(
                    videoType === "user" ? true : videoType === "brand" ? false : undefined,
                    (getPromotedReviewControlData?.data?.length === 1 && currentPage !== 0) ? currentPage : currentPage + 1,
                    limit,
                    "published",
                    startDate,
                    endDate,
                    influencerId,
                    gender,
                    category,
                    subCategory,
                    sortBy,
                    boostStatus,
                    undefined,
                    title,
                    videoType === "without_product" ? true : undefined
                ).then(() => setLoading(false));
                notification.success({
                    message: res?.message,
                    className: "toast-success",
                });
                setIsModalOpenSocial(false)
                setBoostEndDate(null)
                setBoostStartDate(null)
            } else {
                setBoostUpdate("Update")
                notification.error({
                    message: "Something went wrong",
                    className: "toast-error",
                });

            }
        })
    }

    const editPromotion = (item) => {
        setBoostReviewID(item?._id)
        setBoostID(item?.promoted?._id)
        setIsModalOpenSocial(true)
        setReviewLoad(true)
        setBoostUpdate("Update")

        getBoostDetails(item?._id, item?.promoted?._id).then((res) => {
            setReviewLoad(false)
            setAdName(res?.message?.campaign_name)
            setAdCost(res?.message?.budget)
            setBoostStartDate(moment(res?.message?.started_date))
            setBoostEndDate(moment(res?.message?.ended_date))
        })
    }

    const handleCancel = () => {
        setIsModalOpenSocial(false)
        setBoostEndDate(null)
        setBoostStartDate(null)
        setDays(0)
        setBoostUpdate("Create Campaign")
        setAdCost(0)
        setAdName("")
        setBoostType("all")
    }

    const handleOk = () => {
        // setIsModalOpenSocial(false)
    }

    function onPublish(seletedItem) {
        setCurrentData(seletedItem);
        Swal.fire({
            title: "Are You Sure?",
            text: "You Want To UnPublish This Video?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: `var(--primary-color)`,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
        }).then((result) => {
            if (result.isConfirmed) {
                setPublishLoading(true);
                reviewPublish({
                    review_id: seletedItem._id,
                    status: false,
                })
                    .then(() => {
                        setPublishLoading(false);
                        notification.success({
                            message: "Successfully UnPublished",
                            className: "toast-success",
                        });
                        setLoading(true);
                        setCurrentPage((getPromotedReviewControlData?.data?.length === 1 && currentPage !== 1) ? currentPage - 1 : currentPage)
                        getPromotedReviewControl(
                            videoType === "user" ? true : videoType === "brand" ? false : undefined,
                            (getPromotedReviewControlData?.data?.length === 1 && currentPage !== 0) ? currentPage : currentPage + 1,
                            limit,
                            "published",
                            startDate,
                            endDate,
                            influencerId,
                            gender,
                            category,
                            subCategory,
                            sortBy,
                            boostStatus,
                        ).then(() => setLoading(false));
                    })
                    .catch((err) => {
                        notification.error({
                            message: err.response.message,
                            className: "toast-error",
                        });
                    });
            }
        });
    }

    const manageInventory = (id) => {
        history(`/inventory/manage/${id}`, {
            state: {
                inventory: "review",
                title: "publish"
            },
        });
    };
    const getMul = (a1, a2) => parseFloat(a1) * parseFloat(a2);
    function nFormatter(num) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
        }
        if (num < 1000) {
            return numeral(num).format("0.00")
        }
        return num;
    }
    function intlFormat(num) {
        return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    }
    function numberFormat(num) {
        if (num >= 1000000) return intlFormat(num / 1000000) + "M";
        if (num >= 1000) return intlFormat(num / 1000) + "k";
        return intlFormat(num);
    }

    const handleReviewID = (e) => {
        setReviewIDLoad(true)
        dispatch(searchReviewID(e.replaceAll("-", ''), "published")).then((res) => {
            setReviewIDLoad(false)
            if (res?.success) {
                setSearchReviewIDs(res?.message)
            }
        })
    }

    const recordedEvents = (id, pageName) => {
        history(`/influencer-review/${id}`, {
            state: {
                pageName: "Creator",
            },
        });
    };
    function renderContent() {
        if (!loading) {
            return (
                <Row className="post-analytics-tab-boxes-ift influencer-reviews-main">
                    {getPromotedReviewControlData.data.length > 0 ? (
                        getPromotedReviewControlData.data.map((item, i) => (
                            <Col xs={12} xl={3} md={6}>
                                <div
                                    key={i}
                                    className={`card any_bx analytic-box campaign-box pb-0`}
                                >
                                    <div className="camp-row row">
                                        <div className="campaign-header review-control-box col-12">
                                            {/* <h6>
                          <span className="review-box-header mr-2">
                            {item?.influencer?.profile_image_url ?
                              <Avatar
                                icon={<UserOutlined />}
                                src={item?.influencer?.profile_image_url}
                              />
                              :
                              <Avatar
                                icon={<UserOutlined />}
                              />
                            }
  
                          </span>
  
                          <div className="inf-username-box">
                            {item?.influencer?.instagram_username ?
                              <a
                                title={item?.influencer?.instagram_username}
                                className="inf-username"
                                style={{ color: "$primary-color", fontSize: 14, fontWeight: "bold" }}
                                target="_blank"
                                href={`https://www.instagram.com/${item?.influencer?.instagram_username}`}
                              >
                                {item?.influencer?.instagram_username.length > 20
                                  ? item?.influencer?.instagram_username.slice(0, 20) + "..."
                                  : item?.influencer?.instagram_username}
                              </a>
                              :
                              <span
                                className="inf-username"
                                title={item?.influencer?.name}
                              >
                                {item?.influencer?.name}
                              </span>
                            }
                            <span className="inf-email">
                              {item?.influencer?.email?.length > 24
                                ? item?.influencer?.email.slice(0, 24) + "..."
                                : item?.influencer?.email}
                            </span>
  
                          </div>
                        </h6> */}

                                            <div className={`inf-header
                                             
                                             `}>
                                                {/* <span className="review-box-header mr-2">
                            {item?.influencer?.profile_image_url ? (
                              <Avatar
                                icon={<UserOutlined />}
                                src={item?.influencer?.profile_image_url}
                              />
                            ) : (
                              <Avatar icon={<UserOutlined />} />
                            )}
                          </span> */}
                                                <div className="inf-username-box">
                                                    {item?.influencer?.name ? (
                                                        <a
                                                            title={item?.influencer?.name}
                                                            className="inf-username inf-username-published"
                                                            target="_blank"
                                                        // href={`https://www.instagram.com/${item?.influencer?.name}`}
                                                        >
                                                            {item?.influencer?.name.length > 20
                                                                ? item?.influencer?.name.slice(0, 20) + "..."
                                                                : item?.influencer?.name}
                                                        </a>
                                                    ) : item?.influencer?.insta_handle ? (
                                                        <a
                                                            title={item?.influencer?.insta_handle}
                                                            className="inf-username"
                                                            target="_blank"
                                                            href={`https://www.instagram.com/${item?.influencer?.insta_handle}`}
                                                        >
                                                            @
                                                            {item?.influencer?.insta_handle?.length > 20
                                                                ? item?.influencer?.insta_handle.slice(0, 20) +
                                                                "..."
                                                                : item?.influencer?.insta_handle}
                                                        </a>
                                                    ) : (
                                                        <a
                                                            title={item?.influencer?.ig_handle}
                                                            className="inf-username"
                                                            target="_blank"
                                                            href={`https://www.instagram.com/${item?.influencer?.ig_handle}`}
                                                        >
                                                            @
                                                            {item?.influencer?.ig_handle?.length > 20
                                                                ? item?.influencer?.ig_handle.slice(0, 20) +
                                                                "..."
                                                                : item?.influencer?.ig_handle}
                                                        </a>
                                                        // <span
                                                        //   className="inf-username"
                                                        //   title={item?.influencer?.name}
                                                        // >
                                                        //   {item?.influencer?.name}
                                                        // </span>
                                                    )}

                                                    {/* <span className="inf-email">{item?.influencer?.email}</span> */}
                                                    {/* <span className="inf-email">Followers: 10</span> */}
                                                </div>
                                                {/* {item?.promoted?.status === "cancelled" && <div className={`promoted ${!item?.trans?.[0] && "ml-5 pl-4"}`}>
                                                    <FontAwesomeIcon className="promote-icon" icon={faBullhorn} />
                                                    Deactivated
                                                </div>} */}

                                                {item?.promoted?.status === "active" && <div className={`promoted ${!item?.trans?.[0] && "ml-5 pl-4"}`}>
                                                    <FontAwesomeIcon className="promote-icon" icon={faBullhorn} />
                                                    Promoted
                                                </div>}

                                                {item?.promoted?.status === "pending" && <div className={`promoted ${!item?.trans?.[0] && "ml-5 pl-4"}`}>
                                                    <FontAwesomeIcon className="promote-icon" icon={faBullhorn} />
                                                    Scheduled
                                                </div>}

                                                {/* {item?.content_type === "brand"
                                                    ? <div className={`promoted`}>
                                                        {/* <FontAwesomeIcon className="promote-icon" icon={faBullhorn} /> 
                                                        Brand Review
                                                    </div>
                                                    : <div className={`promoted`}>
                                                        {/* <FontAwesomeIcon className="promote-icon" icon={faBullhorn} /> 
                                                        User Review
                                                    </div>
                                                } */}

                                                {item?.trans?.[0] ? (
                                                    <div className="inf-hd1">
                                                        <a
                                                            className="open-modal blue-link"
                                                            onClick={() => {
                                                                setLoad(true);
                                                                setModalTable(true);
                                                                setOrder(item);
                                                                reviewOrders(1, limitPg, item?._id, "").then(
                                                                    (res) => {
                                                                        setTotalEvent(
                                                                            res?.payload?.message?.total_records
                                                                        );
                                                                        setLoad(false);
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            ${nFormatter(item?.trans?.[0]?.total_amount)}
                                                        </a>

                                                        {/* {numeral(item?.trans[0]?.total_amount).format("$0,0.0'")}  */}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div style={{ width: "40px" }}></div>
                                                    </>
                                                )}

                                                {/* <div className="inf-head-followers">
                            <GroupIcon className="fol-icon" />
                            <h5>
                              {item?.influencer?.followers
                                ? numberFormat(item?.influencer?.followers)
                                : 0}
                            </h5>
                          </div> */}
                                            </div>

                                            {/* <div className="category-text-main">
                          <div className="category-text">
                            {item?.category?.category_name}
                          </div>
                        </div> */}
                                        </div>
                                        <div
                                            className="any-post-img-col col-12"
                                        // style={{ marginBottom: 50 }}
                                        >
                                            <div className="any-post-image media-post-new">
                                                <div className="any-image-box">
                                                    <div className="any-image-box-iner review-video">
                                                        <div className="review-video">
                                                            <ReactPlayer
                                                                playing
                                                                // className={`review_react_player ${item?.media_url?.split(".")[
                                                                //   item?.media_url?.split(".")?.length - 1
                                                                // ] === "m3u8" && "objectFit"
                                                                //   }`}
                                                                // className="review_react_player objectFit"
                                                                // className={`review_react_player ${item?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                                                                className={`review_react_player`}
                                                                width={"100%"}
                                                                height={"250px"}
                                                                url={item?.media_url}
                                                                controls
                                                                light={item?.banner}
                                                                playsinline
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`inf-content-area ${title === "cancelled" && "mb-4"}`}>
                                            <div className="">


                                                <div className="event-date-main top-date justify-content-between mb-2">
                                                    <div className="event-date">
                                                        <FontAwesomeIcon
                                                            className="event-icon"
                                                            icon={faEye}
                                                        />

                                                        <div className="event-txt">
                                                            {item?.views ? item?.views : "0"}
                                                        </div>

                                                        <div className="ml-2 event-date">
                                                            <FontAwesomeIcon
                                                                className="event-icon"
                                                                icon={faThumbsUp}
                                                            />

                                                            <div className="event-txt">
                                                                {item?.likes ? item?.likes : "0"}
                                                            </div>
                                                        </div>
                                                        {item?.promoted &&
                                                            <div className="ml-2 event-date" title="Budget">
                                                                <FontAwesomeIcon
                                                                    className="event-icon"
                                                                    icon={faDollar}
                                                                    title="Budget"
                                                                />

                                                                <div className="event-txt">
                                                                    {item?.promoted?.budget ? item?.promoted?.budget : "0"}
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>


                                                    <div className="category-text-main">
                                                        {item?.gen_category?.name && (
                                                            <div className="category-text">
                                                                {item?.gen_category?.name}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="event-date-main top-date">
                                                    <div className="event-date w-100 justify-content-between">
                                                        {/* <FontAwesomeIcon
                                                            className="event-icon"
                                                            icon={faCalendarDays}
                                                        /> */}
                                                        <div className="event-txt">
                                                            <span>
                                                                Start Date: {" "}
                                                            </span>
                                                            {moment(item?.promoted?.started_date)
                                                                .utc()
                                                                .format("MM-DD-YYYY")}
                                                        </div>
                                                        <div className="event-txt">
                                                            <span>
                                                                End Date: {" "}
                                                            </span>
                                                            {moment(item?.promoted?.ended_date)
                                                                .utc()
                                                                .format("MM-DD-YYYY")}
                                                        </div>
                                                    </div>

                                                    {/* <div className="event-date">
                                                        <FontAwesomeIcon
                                                            className="event-icon"
                                                            icon={faClock}
                                                        />

                                                        <div className="event-txt">
                                                            {moment(item?.created_date).format("hh:mm A")}
                                                        </div>
                                                    </div> */}


                                                </div>


                                            </div>

                                            <div className="campaign-header review-control-box col-12 p-0">
                                                <h5 className="btm-title d-flex flex-column">
                                                    {item?.promoted?.campaign_name && <span title={item?.promoted?.campaign_name} className="boost_heading_span">
                                                        Campaign Name: {item?.promoted?.campaign_name.length > 24
                                                            ? item.promoted?.campaign_name.slice(0, 24) + "..."
                                                            : item.promoted?.campaign_name}
                                                    </span>
                                                    }
                                                    <span title={item.title} className="boost_heading_span">
                                                        {item.title.length > 50
                                                            ? item.title.slice(0, 50) + "..."
                                                            : item.title}
                                                    </span>

                                                </h5>


                                            </div>
                                            {/* <div className="fee-box-main">
                          <div className="fee-box fee-height">
                            <div className="fee-state red-state">
                              {item?.referral_percent === "0" ||
                                item?.referral_percent === "" ||
                                item?.referral_percent === "null" ||
                                item?.referral_percent === "undefined" ||
                                !item?.referral_percent
                                ? ""
                                : item?.referral_percent + "%" + " Referral Fee"}
                              {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " Referral Fee"}
                            </div>
                          </div>
                        </div>
                        <div className="fee-box-main">
                          <div className="fee-box fee-height">
                            <div className="fee-state orange-state">
                              {item?.influencer_percent === "0" ||
                                item?.influencer_percent === "" ||
                                item?.influencer_percent === "null" ||
                                item?.influencer_percent === "undefined" ||
                                !item?.influencer_percent
                                ? ""
                                : item?.influencer_percent + "%" + " Influencer Fee"}
                              {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " Creator Fee"}
                            </div>
                          </div>
                        </div> */}
                                            {item?.review_counter && <p className="mt-2 mb-0"><span className="fw-bold">Video ID: </span> {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                                        </div>
                                    </div>
                                    <div className="cam-buttons col-12 trigger-btn-style manage-style2">
                                        {roleKey === "inventoryManager" ? (<>
                                            {/* <Button
                                                // className="default-btn medium-bt padb-3 ml-0 w-inherit"
                                                className="default-btn d-flex align-items-center justify-content-center ml-0 w-inherit"
                                                type="primary"
                                                size="large"
                                                onClick={() =>
                                                    recordedEvents(item?._id)
                                                }
                                                icon={
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        className="event-icon"
                                                        // icon={faVideo}
                                                        icon={faPlay}
                                                    />
                                                }
                                            // disabled={publishLoading}
                                            >
                                                Play
                                            </Button>
                                            <Button
                                                // className="default-btn medium-bt padb-3 m-0 w-inherit"
                                                className="default-btn d-flex  align-items-center justify-content-center m-0 w-inherit"
                                                type="primary"
                                                size=""
                                                // disabled={true}

                                                onClick={() => manageInventory(item?._id)}
                                                icon={
                                                    <FontAwesomeIcon
                                                        size="large"
                                                        className="event-icon"
                                                        // icon={faVideo}
                                                        icon={faCartFlatbed}
                                                    />
                                                }
                                            >
                                                Manage Inventory
                                            </Button> */}
                                        </>
                                        ) : (<>
                                            {/* <button
                                                className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                onClick={() =>
                                                    recordedEvents(item?._id)
                                                }
                                            // disabled={publishLoading}
                                            >
                                                Play
                                            </button>
                                            <button
                                                className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                onClick={() => onPublish(item)}
                                                disabled={publishLoading}
                                            >
                                                UnPublish
                                            </button> */}
                                            {
                                                item?.promoted ?
                                                    (item?.promoted?.status === "active") ?
                                                        <button
                                                            className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                            onClick={() => onDemote(item)}
                                                            disabled={btnLoader}
                                                        >
                                                            {btnLoader && <span className="mr-2 ads-white-spin">
                                                                <Spin size="small" />
                                                            </span>}
                                                            Deactivate
                                                        </button>
                                                        :
                                                        item?.promoted?.status === "pending"
                                                            ?
                                                            <>
                                                                <button
                                                                    className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                                    onClick={() =>
                                                                        // recordedEvents(item?._id)
                                                                        editPromotion(item)
                                                                    }
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                                    onClick={() => onDemote(item)}
                                                                >
                                                                    Deactivate
                                                                </button>
                                                            </>
                                                            :
                                                            <></>
                                                    // <button
                                                    //     className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                    //     onClick={() => onBoostClick(item)}
                                                    // >
                                                    //     Create Campaign
                                                    // </button>
                                                    :
                                                    <button
                                                        className="default-btn medium-btn padb-3 ml-0 w-inherit"
                                                        onClick={() => onBoostClick(item)}
                                                    >
                                                        Create Campaign
                                                    </button>
                                            }
                                        </>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        ))
                    ) : (
                        <div className="col-md-12">
                            <NoDataFound />
                        </div>
                    )}
                </Row>
            );
        } else {
            return <LoadingSpin />;
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day') || current < boostStartDate;
    };

    const handlePageClick = (e) => {
        setLoading(true);
        const page = e.selected;
        setCurrentPage(page);
        getPromotedReviewControl(
            videoType === "user" ? true : videoType === "brand" ? false : undefined,
            page + 1,
            limit,
            "published",
            startDate,
            endDate,
            influencerId,
            gender,
            category,
            subCategory,
            sortBy,
            boostStatus,
            undefined,
            title === "all" ? undefined : title,
            videoType === "without_product" ? true : undefined
        ).then(() => setLoading(false));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const page = e.selected;
        setCurrentPage(0);
        setLoading(true);
        getPromotedReviewControl(
            videoType === "user" ? true : videoType === "brand" ? false : undefined,
            1,
            limit,
            "published",
            startDate,
            endDate,
            influencerId,
            gender,
            category,
            subCategory,
            sortBy,
            boostStatus,
            reviewID,
            title === "all" ? undefined : title,
            videoType === "without_product" ? true : undefined
        ).then(() => {
            setLoading(false);
            setInfluencer("");
        });
    };

    const handleReset = () => {
        setInfluencerId();
        setGender(null);
        setCategory(null);
        setSubCategory(null);
        setSortBy("date");
        setVideoType("all")
        setBoostStatus("");
        setStartDate(undefined);
        setReviewID(null)
        setEndDate(undefined);
        setCurrentPage(0);
        setLoading(true);
        childRef.current?.clear();
        getPromotedReviewControl(
            undefined,
            1,
            limit,
            "published",
            undefined,
            undefined,
            "",
            "",
            "",
            "",
            "date",
            "",
            undefined,
            title === "all" ? undefined : title,
            undefined
        ).then(() => {
            setLoading(false);
            setInfluencer("");
            setGender(null);
            setCategory(null);
            setSubCategory(null);
        });
    };

    const dateRangePickerChanger = (value, dataString) => {
        const startDate = dataString[0];
        const endDate = dataString[1];
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const selectInfluencer = (value) => {
        setInfluencerId(value);
    }
    const getInfluencer = (influencerData) => {
        setInfluencerId(influencerData);
    };

    const handleGender = (e) => {
        setGender(e);
        setCategory(null);
        setSubCategory(null);
        setCatLoad(true);
        getSavedCategories(e).then((res) => {
            if (res.success) {
                setCatLoad(false);
            } else {
                setCatLoad(false);
            }
        });
    };
    const handleCategory = (e) => {
        setCategory(e);
        setSubCategory(null);
        setSubCatLoad(true);
        getSavedSubCategories(gender, e).then((res) => {
            if (res.success) {
                setSubCatLoad(false);
            } else {
                setSubCatLoad(false);
            }
        });
    };

    const handleSubCategory = (e) => {
        setSubCategory(e);
    };

    const handleSortBy = (value) => {
        setSortBy(value);
    };
    const handleVideoType = (value) => {
        setVideoType(value);
    };
    const handleBoostType = (value) => {
        setBoostType(value);
    };

    const handleBoostStatus = (value) => {
        setBoostStatus(value);
    };

    const handleCreator = (value, option) => {
        setInfluencerId(value);
    };
    function Summary() {
        return (
            <div className="sales-summery mb-3">
                <div className="row">
                    <div className="col-lg-6 col-xl-4 mb-2">
                        <div className="summarry-box new-style">
                            <h4 className="summary-hd">Summary</h4>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Orders</h4>
                                <h3 className="count">
                                    {orderReview?.payload?.message?.summary[0]?.order_count}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Amount</h4>
                                <h3 className="count">
                                    {numeral(
                                        orderReview?.payload?.message?.summary[0]?.order_totalprice
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Cashback</h4>
                                <h3 className="count">
                                    {numeral(
                                        orderReview?.payload?.message?.summary[0]?.cashback_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Commission</h4>
                                <h3 className="count">
                                    {numeral(
                                        orderReview?.payload?.message?.summary[0]
                                            ?.customer_commission
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total ORME Fee</h4>
                                <h3 className="count">
                                    {numeral(
                                        orderReview?.payload?.message?.summary[0]?.kb_fee
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function DefaultTable() {
        let data = orderReview?.payload?.message?.data;
        if (data) {
            return (
                <>
                    <div className="table-responsive scrollbar-style">
                        <table className="transactions-box table">
                            <thead className="table_heading">
                                <tr>
                                    <th className="td-width-60">S.#</th>
                                    <th className="td-width-150">Order Date</th>
                                    <th className="td-width-150">Order #</th>
                                    <th className="td-width-150">Purchased By</th>
                                    <th className="td-width-150 text-right">Amount</th>
                                    <th className="td-width-150 text-right">Cashback</th>
                                    <th className="td-width-150 text-right">Commission</th>
                                    <th className="td-width-150 text-right">ORME Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderReview.loading ? (
                                    <div className="antd-loading-ift">
                                        <Spin size="large"></Spin>
                                    </div>
                                ) : orderReview?.payload?.message?.data.length === 0 ? (
                                    <tr>
                                        <td colspan="6">
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </td>
                                    </tr>
                                ) : (
                                    data?.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1 + currentPage * 15}</td>
                                                <td>
                                                    {" "}
                                                    {moment(item?.created_date).format("DD-MM-YYYY")}
                                                </td>
                                                <td>
                                                    <a
                                                        className="open-modal blue-link"
                                                        onClick={() => {
                                                            detailView(item);
                                                        }}
                                                    >
                                                        {item?.order_id}
                                                    </a>
                                                </td>
                                                <td>
                                                    {item?.event === "productshare"
                                                        ? "Anonymous"
                                                        : item?.customer?.name}
                                                </td>
                                                <td className="text-right">
                                                    {numeral(item?.order_totalprice).format("$0,0.0'")}
                                                </td>
                                                <td className="text-right">
                                                    {numeral(item?.cashback_amount).format("$0,0.0'")}
                                                </td>
                                                <td className="text-right">
                                                    {numeral(item?.customer_commission).format("$0,0.0'")}
                                                </td>
                                                <td className="text-right">
                                                    {numeral(item?.kb_fee).format("$0,0.0'")}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}

                                {orderReview?.payload?.message?.data.length > 0 && (
                                    <tr>
                                        <td></td>
                                        <td>
                                            <b>Total</b>
                                        </td>
                                        <td>
                                            <b>
                                                {orderReview?.payload?.message?.data?.length +
                                                    currentPage * 15}
                                            </b>
                                        </td>
                                        <td></td>
                                        <td className="text-right">
                                            <b>
                                                {numeral(
                                                    data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_totalprice ? b.order_totalprice : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                ).format("$0,0.0'")}
                                            </b>
                                        </td>
                                        <td className="text-right">
                                            <b>
                                                {numeral(
                                                    data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.cashback_amount ? b.cashback_amount : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                ).format("$0,0.0'")}
                                            </b>
                                        </td>
                                        <td className="text-right">
                                            <b>
                                                {numeral(
                                                    data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.customer_commission
                                                                    ? b.customer_commission
                                                                    : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                ).format("$0,0.0'")}
                                            </b>
                                        </td>
                                        <td className="text-right">
                                            <b>
                                                {numeral(
                                                    data.reduce((a, b) => {
                                                        return (
                                                            Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                        );
                                                    }, 0)
                                                ).format("$0,0.0'")}
                                            </b>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="purchase-data referral-data">
                        {orderReview?.payload?.message?.data?.length ? (
                            <ReactPaginate
                                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                                onPageChange={handlePageClickTable}
                                pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                                pageCount={Math.ceil(totalEvent / limitPg)}
                                forcePage={currentPageTable}
                                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                                pageClassName="page-item d-none"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item d-none"
                                breakLinkClassName="page-link"
                                containerClassName={"pagination custom-paginate"}
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    {orderReview?.payload?.message?.data?.length > 0 && <Summary />}
                </>
            );
        }
    }

    const handlePageClickTable = (e) => {
        const page = e.selected;
        setCurrentPageTable(page);

        reviewOrders(
            page + 1,
            limitPg,
            "",
            order?._id
            // ).then((res) => {
            //     setTotalEvent(res?.payload?.message?.total_records);

            // }
        );

        setTotalEvent(detailsOrder?.payload?.message?.total_records);
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row w-20-desktop-main w-25-desktop-main">
                    {/* <div className="col-xl-3 col-md-6 col-12 mb-20">
            <p>Select Start Date / End Date</p>
            <RangePicker
              size="large"
              className="w-100"
              key={4}
              value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
              allowClear={false}
              ranges={{
                Today: [moment(), moment()],
                Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
                Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
              }}
              format={dateFormat}
              onChange={dateRangePickerChanger}
            />
          </div> */}

                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Select Reviews</p>
                        <Select
                            className="w-100 select-style dark-field"
                            placeholder="Select Sort By"
                            size="large"
                            onChange={handleVideoType}
                            value={videoType}
                        >
                            <Option value="all">All</Option>
                            <Option value="user">User Videos</Option>
                            <Option value="brand">Brand Videos</Option>
                            <Option value="without_product">Content Without Product</Option>
                        </Select>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Department</p>
                        <Select
                            size="large"
                            className="w-100"
                            key={4}
                            placeholder="Select Department"
                            focus={false}
                            onChange={handleGender}
                            value={gender}
                            onBlur={false}
                            loading={generalLoad}
                            disabled={!savedGeneralCategories || generalLoad}
                        >
                            {savedGeneralCategories &&
                                savedGeneralCategories?.message?.map((item, i) => {
                                    return (
                                        <Option value={item?.category_id}>{item?.name}</Option>
                                    );
                                })}
                        </Select>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Category</p>
                        <Select
                            size="large"
                            className="w-100"
                            key={4}
                            placeholder="Select Category"
                            focus={false}
                            onChange={handleCategory}
                            value={category}
                            onBlur={false}
                            loading={catLoad}
                            disabled={!savedCategory.success || catLoad || !gender}
                        >
                            <Option value="all">{"All"}</Option>
                            {savedCategory &&
                                savedCategory?.message?.map((item, i) => {
                                    return (
                                        <Option value={item?.category_id}>
                                            {item?.category_name}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Sub Category</p>
                        <Select
                            size="large"
                            className="w-100"
                            key={4}
                            placeholder="Select Sub Category"
                            focus={false}
                            onChange={handleSubCategory}
                            value={subCategory}
                            onBlur={false}
                            loading={subCatLoad}
                            disabled={
                                !savedSubCategory?.success || subCatLoad || !gender || !category
                            }
                        >
                            <Option value="">{"All"}</Option>
                            {savedSubCategory &&
                                savedSubCategory?.message?.map((item, i) => {
                                    return (
                                        <Option value={item?.sub_category_id}>
                                            {item?.sub_category_name}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Select Creator</p>
                        {/* <div className="select-inf-field d-flex flow-row">
                <AsyncInfluencer
                  ref={childRef}
                  getInfluencer={getInfluencer}
                  influencer={influencer}
                />
              </div> */}
                        <Select
                            showSearch
                            name="sort"
                            size="large"
                            placeholder="Select Creator"
                            className="w-100 select-style dark-field"
                            optionFilterProp="children"
                            value={influencerId}
                            onChange={(value) => selectInfluencer(value)}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={getAllInfluencers?.message && [

                                ...getAllInfluencers?.message]?.map((influencer) => {
                                    return ({
                                        label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                                        value: influencer?.user_id
                                    }
                                    )
                                })
                            }
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Video ID</p>
                        <Select
                            showSearch
                            size="large"
                            className="w-100"
                            key={4}
                            type="Number"
                            notFoundContent={reviewIDLoad && <Spin size="small" />}
                            loading={reviewIDLoad}
                            optionFilterProp="children"
                            placeholder="Enter Video ID"
                            labelInValue
                            onChange={(e) => setReviewID(e?.value?.[0] + "-" + e?.value?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-'))}
                            optionLabelProp="label"
                            onSearch={handleReviewID}
                            filterOption={(input, options) =>
                                options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            focus={false}
                            value={reviewID}
                            onBlur={false}
                        >
                            {searchReviewIDs && !reviewIDLoad &&
                                searchReviewIDs?.map((item, i) => {
                                    return (
                                        <Option value={item?.review_counter}>
                                            {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12 mb-15">
                        <p className="mb-5rem">Sort By</p>
                        <Select
                            className="w-100 select-style dark-field"
                            placeholder="Select Sort By"
                            size="large"
                            // focus={false}
                            // onBlur={false}
                            onChange={handleSortBy}
                            value={sortBy}
                        >
                            <Option value="date">Date</Option>
                            {/* <Option value="follower">Followers</Option> */}
                            <Option value="views">Views</Option>
                            <Option value="sales">Sales</Option>
                            <Option value="likes">Likes</Option>
                            {/* <Option value="boost">Promoted</Option> */}
                        </Select>
                    </div>
                    {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
              <p className="mb-5rem">Boost Status</p>
              <Select
                className="w-100 select-style dark-field"
                placeholder="Select Boost Status"
                size="large"
                onChange={handleBoostStatus}
                value={boostStatus}
              >
                <Option value="">All</Option>
                <Option value="active">Active</Option>
                <Option value="pending">Scheduled</Option>
                <Option value="cancelled">Deactivated</Option>
              </Select>
            </div> */}

                    <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                        <Button
                            onClick={handleSubmit}
                            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={handleReset}
                            className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
                <hr className="separator-line mt-0" />
                {renderContent()}
                {getPromotedReviewControlData?.data?.length > 0 && !loading && (
                    <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        pageClassName="page-item "
                        pageLinkClassName="page-link custom-paginate-link btn btn-primary"
                        previousClassName="page-item"
                        previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
                        nextClassName="page-item"
                        nextLinkClassName="page-link custom-paginate-next btn btn-primary"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        forcePage={currentPage}
                        pageCount={Math.ceil(getPromotedReviewControlData.total_count / limit)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                        onPageChange={handlePageClick}
                        containerClassName={
                            "pagination justify-content-center mt-2 custom-paginate review-paginate"
                        }
                        activeClassName={"active"}
                    />
                )}
            </div>

            <Modal
                dialogClassName="code-activated-popup"
                size="lg"
                scrollable
                show={modal}
                onHide={() => setModal(false)}
                centered
                backdrop="static"
            >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Header className="modal-header-style2" closeButton>
                    <Modal.Title className="mb-0">Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {load ? (
                        <span className="mt-2 mr-2 loader-center position-relative">
                            <Spin size="large" />
                        </span>
                    ) : (
                        // <Spin
                        //   style={{
                        //     display: "flex",
                        //     justifyContent: "center",
                        //   }}
                        // />
                        <>
                            <div className="order-detail-title-area">
                                <div className="d-flex flex-column">
                                    <div className="order-num">
                                        Order #
                                        <div className="order-name">
                                            {
                                                detailsOrder?.payload?.message?.order_detail
                                                    ?.order_number
                                            }
                                        </div>
                                    </div>
                                    <duv className="order-num order-name-main">
                                        Customer Name:
                                        <div className="order-name">
                                            {detailsOrder?.payload?.message?.order_detail?.customer
                                                ?.first_name +
                                                " " +
                                                detailsOrder?.payload?.message?.order_detail?.customer
                                                    ?.last_name}
                                        </div>
                                    </duv>
                                </div>

                                <span className="order-date">
                                    {moment
                                        .utc(
                                            detailsOrder?.payload?.message?.order_detail?.created_at
                                        )
                                        .format("MMMM Do YYYY [from Online Shop]")}
                                </span>
                            </div>
                            <Table responsive size="sm" className="transactions-box">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-120">Order Date</th>
                                        <th className="td-width-100">SKU #</th>
                                        <th className="td-width-200">Description</th>
                                        <th className="td-width-80">Qty</th>
                                        <th className="td-width-100 text-right">Price</th>
                                        <th className="td-width-100 text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!detailsOrder?.loading &&
                                        detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                                            (item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>
                                                            {moment
                                                                .utc(order?.created_at)
                                                                .format("DD-MM-YYYY")}
                                                        </td>
                                                        <td>{item?.sku}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.quantity}</td>
                                                        <td className="text-right">
                                                            {numeral(item?.price).format("$0,0.0'")}
                                                        </td>
                                                        <td className="text-right">
                                                            {numeral(
                                                                getSum(item?.quantity, item?.price)
                                                            ).format("$0,0.0'")}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </Table>
                            <div className="order-sum">
                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Subtotal</div>
                                        <div className="left-info">
                                            {
                                                detailsOrder?.payload?.message?.order_detail?.line_items
                                                    ?.length
                                            }{" "}
                                            item
                                        </div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            detailsOrder?.payload?.message?.order_detail
                                                ?.total_line_items_price
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Discount</div>
                                        {detailsOrder?.payload?.message?.order_detail
                                            ?.total_discounts
                                            ? numeral(
                                                detailsOrder?.payload?.message?.order_detail
                                                    ?.total_discounts /
                                                detailsOrder?.payload?.message?.order_detail
                                                    ?.total_line_items_price
                                            ).format("0%")
                                            : ""}
                                    </div>
                                    {detailsOrder?.payload?.message?.order_detail
                                        ?.total_discounts ? (
                                        <>
                                            <div className="right-txt">
                                                -
                                                {numeral(
                                                    detailsOrder?.payload?.message?.order_detail
                                                        ?.total_discounts
                                                ).format("$0,0.0'")}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="right-txt">
                                            {numeral(0).format("$0,0.0'")}
                                        </div>
                                    )}
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Shipping</div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            detailsOrder?.payload?.message?.order_detail
                                                ?.total_shipping_price_set?.presentment_money?.amount
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Tax</div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            detailsOrder?.payload?.message?.order_detail?.total_tax
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>
                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd fw-bold">Total Amount</div>
                                    </div>
                                    <div className="right-txt fw-bold">
                                        {numeral(
                                            detailsOrder?.payload?.message?.order_detail?.total_price
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => setModal(false)}
                        className="default-btn outline padb-3"
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                dialogClassName="code-activated-popup"
                size="lg"
                scrollable
                show={modalTable}
                onHide={() => TableClose(false)}
                centered
            >
                <Modal.Header className="modal-header-style2">
                    <Modal.Title className="mb-0">Order Detail</Modal.Title>
                </Modal.Header>
                {load ? (
                    <>
                        <Spin
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        />
                    </>
                ) : (
                    <Modal.Body>
                        <DefaultTable />
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button
                        onClick={() => TableClose(false)}
                        className="default-btn outline padb-3"
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modala
                className="modal-generic modal-500"
                centered
                visible={isModalOpenSocial}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={false}
                maskClosable={false}
            // footer={[
            //   <Button key="back" onClick={handleCancel}>
            //     Cancel
            //   </Button>,
            //   <Button key="submit" type="primary"
            //     className=""
            //     // onClick={handleOk}
            //     onClick={{}}
            //     >
            //     Next
            //   </Button>,
            // ]}
            >
                <h2 className="modal-hd1">{boostUpdate === "Update" ? "Update Campaign" : "Create Campaign"}</h2>
                {reviewLoad ?
                    <span className="spinclass loader-center position-relative">
                        <Spin size="medium" />
                    </span>
                    :
                    <div className="form-styling ">
                        <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">Campaign Name</p>
                                <Input
                                    className="w-100"
                                    size="large"
                                    // disabled
                                    // defaultValue={adCost}
                                    placeholder="Enter Campaign Name"
                                    value={adName}
                                    onChange={(e) => {
                                        setAdName(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">Campaign Budget</p>
                                <InputNumber
                                    className="w-100"
                                    size="large"
                                    // disabled
                                    // defaultValue={adCost}
                                    value={adCost}
                                    onChange={(e) => {
                                        setAdCost(e);
                                    }}
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">Start Date</p>
                                <DatePicker className="w-100" value={boostStartDate} disabled={adCost <= 0} disabledDate={(current) => current < dayjs().startOf('day')} size="large" onChange={(date) => setBoostStartDate(date)} />
                            </div>
                        </div>
                        <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">End Date</p>
                                <DatePicker className="w-100" value={boostEndDate} size="large" disabled={!boostStartDate} disabledDate={disabledDate} onChange={(date) => setBoostEndDate(date)} />
                            </div>
                        </div>
                        {/* <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">Select Type</p>
                                <Select
                                    className="w-100 select-style dark-field"
                                    placeholder="Select Sort By"
                                    size="large"
                                    onChange={handleBoostType}
                                    value={boostType}
                                >
                                    <Option value="all">All</Option>
                                    <Option value="foryou">For You</Option>
                                    <Option value="following">Following</Option>
                                </Select>
                            </div>
                        </div> */}
                        {boostStartDate && boostEndDate && <div className="boost-message">
                            <FontAwesomeIcon className="info-icon" icon={faCircleInfo} />
                            Based on your campaign duration and budget, you'll spend ${adCost} for {days} days of promotion. The estimated impressions for this campaign will reach
                            {" "}
                            {numeral(Math.round((adCost / getPromotionImpression?.data?.price) * getPromotionImpression?.data?.impression)).format("0,0'")}{"."}
                        </div>}

                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={boostUpdate === "Update" ? reviewUpdateBoost : reviewBoost}
                                disabled={!(boostEndDate && boostStartDate && adCost != 0 && adName)}
                                // className="default-btn padb-3 d-inline-flex align-items-center justify-content-center color-secondary"
                                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center `}
                            >
                                <span className="nav-text">{boostUpdate}</span>
                            </Button>
                            <Button
                                className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center mr-0"
                                key="back"
                                onClick={handleCancel}
                            >
                                Exit
                            </Button>
                        </div>
                    </div>}
            </Modala >
        </>
    );
}

function mapStateProps({
    getPromotedReviewControlData,
    getAllCreatorData,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
    orderReview,
    detailsReview,
    detailsOrder,
    getStructureFees
}) {
    return {
        getPromotedReviewControlData,
        getAllCreatorData,
        savedGeneralCategories,
        savedCategory,
        savedSubCategory,
        orderReview,
        detailsReview,
        detailsOrder,
        getStructureFees
    };
}
export default connect(mapStateProps, {
    ...influencerReviewAction,
    ...savedCategoriesAction,
    ...boostActions,
    ...feeStructuresAction
})(CreateCompaign);