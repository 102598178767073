import React, { useEffect, useState, useRef } from 'react';
import { useStepsForm } from 'sunflower-antd';
import { Button, Form, Input, message, Spin, Upload, ColorPicker, Popover, Image, Modal } from 'antd';
import '../../css/basicSetup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import placeholderImage from '../../images/placeholder-image.jpg';
import { getUserInfo } from '../../redux/actions/userInfo.action';
import axios from 'axios';
import { basicProfileImage } from '../../redux/actions/profileImageBasic.action';
import { notification } from 'antd';
import { SetLocalDescriptionTask } from 'amazon-chime-sdk-js';
import { basicPassChange } from '../../redux/actions/basicPassChange.action';
import { getSocialFollowers } from '../../redux/actions/socialFollowers.action ';
import { createBrowserHistory } from 'history';
import { updateProfileBio } from '../../redux/actions/basicProfileFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import GroupIcon from '@mui/icons-material/Group';
import numeral from 'numeral';
import { store } from '../../redux/store';
import { AUTHENTICATE } from '../../redux/types/types';
import { visitorURL } from '../../config';
import { SketchPicker, HuePicker } from 'react-color';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { coverImageBasic } from '../../redux/actions/coverImageBasic.action';
import ImgCrop from 'antd-img-crop';
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();

export const history = createBrowserHistory();
export default function BasicSetup({ reRender }) {
  const { TextArea } = Input;

  const dispatch = useDispatch();
  const { userInfo, validate, socialMediaFollowers, checkInstagramConnection } = useSelector((state) => state);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [instaData, setInstaData] = useState([]);
  const [color, setColor] = useState(primaryColor); // Default color (black)
  const [coverImageUrl, setCoverImageUrl] = useState([]);
  const [coverImageLocal, setCoverImageLocal] = useState("");
  // const [imageSrc, setImageSrc] = useState(null);
  // const [croppedImage, setCroppedImage] = useState(null);
  const [mobileCoverImage, setMobileCoverImage] = useState(null);
  const [webCoverImage, setWebCoverImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageData, setCurrentImageData] = useState(null);

  // const inputRef = useRef(null);
  // const canvasRef = useRef(null);
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({});
  const handleValues = (e) => {
    form.setFieldsValue({ username: e.target.value });
  };

  useEffect(() => {
    setLoading(true)
    dispatch(getUserInfo()).then((res) => {
      if (res.success) {
        form.setFieldsValue({
          username: res?.message?.data?.name,
          notes: res?.message?.data?.bio,
          website_address: res?.message?.data?.website_address,
          color: res?.message?.data?.cover_color || primaryColor,
        });
        setCoverImageUrl(res?.message?.data?.cover_image)
        setColor(res?.message?.data?.cover_color || primaryColor)
        if (checkInstagramConnection?.payload?.connection) {
          dispatch(getSocialFollowers(res?.message?.data?._id)).then((r) => {
            if (r?.payload?.success) {
              setInstaData(r?.payload?.message)
              setLoading(false)
            }
            else {
              setLoading(false)
            }
          });
        } else {
          setLoading(false)
        }
      }
    });

  }, []);

  const handleColorChange = (color) => {
    console.log(color, 'color');

    if (color && !/^\s*(?:white|#ffffff|rgb\(255,\s*255,\s*255\)|rgba\(255,\s*255,\s*255,\s*1\))\s*$/i.test(color.hex)) {
      setColor(color.hex); // Update color when the user selects a new color
      form.setFieldsValue({ color: color.hex }); // Set color in form field
    }
    else {
      notification.info({
        message: 'Do not use white color.',
        className: 'toast-info',
      })
      setColor(primaryColor); // Update color when the user selects a new color
      form.setFieldsValue({ color: primaryColor }); // Set color in form field
    }
  };

  const content = (
    <SketchPicker
      color={color}
      onChange={handleColorChange}
    />
  );



  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp' || file.type === 'image/gif';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG/JPEG/WEBP/GIF file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 10;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 10MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // };
  const beforeUpload = (file) => {

    return new Promise((resolve, reject) => {

      // Check if the file is of a valid type (JPG/PNG/JPEG/WEBP/GIF)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/JPEG/WEBP/GIF file!');
        return resolve(false); // Return false to reject the upload
      }

      // Check if the file size is less than 10MB
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Image must be smaller than 10MB!');
        return resolve(false); // Return false to reject the upload
      }

      // For image dimension check (minimum width: 1920px, minimum height: 700px)
      const img = new window.Image();
      const reader = new FileReader();

      // Reader onload callback
      reader.onload = () => {
        img.src = reader.result;
      };

      // Image onload callback - after the image has loaded, check the dimensions
      img.onload = () => {
        // Check if image dimensions meet the minimum requirement
        const isValidDimensions = img.width >= 1920 && img.height >= 825;

        if (!isValidDimensions) {
          message.error('Image must be at least 1920px in width and 825px in height!');
          return resolve(false); // Reject upload if dimensions are invalid
        }

        // If all checks pass, resolve the promise with true
        resolve(true);
      };

      img.onerror = () => {
        message.error('Invalid image file!');
        resolve(false); // Reject upload if image is invalid
      };

      // Read the file as a Data URL to load it into the Image object
      reader.readAsDataURL(file);
    });
  };



  const beforeCrop = (file) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      const reader = new FileReader();

      reader.onload = () => {
        img.src = reader.result;
      };

      img.onload = () => {
        // Check if image dimensions meet the minimum requirement
        if (img.width >= 1920 && img.height >= 825) {
          resolve(true); // Proceed to crop if dimensions are valid
        } else {
          // message.error('Image must be at least 1920px in width and 825px in height!');
          reject(false); // Reject cropping if dimensions are invalid
        }
      };

      // img.onerror = () => {
      //   message.error('Invalid image file!');
      //   reject(false); // Reject cropping if the image is invalid
      // };

      // Read the file as a Data URL to load it into the Image object
      reader.readAsDataURL(file);
    }).catch(() => {
      return false; // Explicitly return false if promise is rejected
    });
  };


  const handleChange = (info) => {
    console.log(info, 'info');

    if (info.file.status === 'uploading') {
      setCoverLoading(true);
      return;
    }
    console.log(info, 'info');

    if (info.file.status === 'done') {

      setCoverImageLocal(info.file.originFileObj);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, async (url) => {
        setCoverLoading(false);
        setCoverImageUrl(url);
        const { base64, file } = await cropImage(info.file.originFileObj);
        const { base64web, fileweb } = await cropImageWeb(info.file.originFileObj);
        console.log({ base64, file });
        console.log({ base64web, fileweb });
        setMobileCoverImage(base64)
        setWebCoverImage(base64web)
      });
    }
  };

  // const cropImage = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const image = new window.Image();
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       image.src = reader.result;
  //     };

  //     reader.onerror = (error) => {
  //       reject(error);
  //     };

  //     reader.readAsDataURL(file);

  //     image.onload = () => {
  //       // Ensure the canvas is created only after image is loaded
  //       const canvas = document.createElement('canvas');
  //       const ctx = canvas.getContext('2d');

  //       if (!ctx) {
  //         reject('Failed to get canvas context');
  //         return;
  //       }

  //       const width = image.width;
  //       const height = image.height;

  //       // Crop percentages
  //       const cropLeftPercentage = 23.96;
  //       const cropRightPercentage = 23.96;

  //       // Calculate the crop width (removing from left and right)
  //       const cropWidth = width * (1 - (cropLeftPercentage + cropRightPercentage) / 100);
  //       const cropX = (width - cropWidth) / 2;

  //       // Set canvas size
  //       canvas.width = cropWidth;
  //       canvas.height = height;

  //       // Draw cropped portion of the image on canvas
  //       ctx.drawImage(image, cropX, 0, cropWidth, height, 0, 0, cropWidth, height);

  //       // Convert canvas to base64 and Blob (image file)
  //       canvas.toDataURL('image/png', (base64) => {
  //         // Convert canvas to Blob for file
  //         canvas.toBlob((blob) => {
  //           if (blob) {
  //             // Create a new File from the Blob (keeping original file name and type)
  //             const croppedFile = new File([blob], file.name, {
  //               type: file.type,
  //             });

  //             // Return both base64 and File
  //             resolve({ base64, file: croppedFile });
  //           } else {
  //             reject('Failed to convert canvas to Blob');
  //           }
  //         }, 'image/png');
  //       });
  //     };

  //     image.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  const cropImage = (file) => {
    return new Promise((resolve, reject) => {
      const image = new window.Image();
      const reader = new FileReader();

      reader.onload = () => {
        image.src = reader.result;
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);

      image.onload = () => {
        // Ensure the canvas is created only after image is loaded
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject('Failed to get canvas context');
          return;
        }

        const width = image.width;
        const height = image.height;

        // Crop percentages
        const cropLeftPercentage = 23.96;
        const cropRightPercentage = 23.96;

        // Calculate the crop width (removing from left and right)
        const cropWidth = width * (1 - (cropLeftPercentage + cropRightPercentage) / 100);
        const cropX = (width - cropWidth) / 2;

        // Set canvas size
        canvas.width = cropWidth;
        canvas.height = height;

        // Draw cropped portion of the image on canvas
        ctx.drawImage(image, cropX, 0, cropWidth, height, 0, 0, cropWidth, height);

        // Get base64 of the cropped image
        const croppedDataUrl = canvas.toDataURL();
        console.log(croppedDataUrl, 'croppedDataUrl');

        const byteString = atob(croppedDataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'image/png' }); // You can change the MIME type if needed

        // Create a File object from the Blob
        const fileName = file.name; // Use the original file name
        const newFile = new File([blob], fileName, {
          type: 'image/png', // Keep the file type as image/png or match the original file type
        });

        resolve({ base64: croppedDataUrl, file: newFile });



        resolve(croppedDataUrl);
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const cropImageWeb = (file) => {
    return new Promise((resolve, reject) => {
      const image = new window.Image();
      const reader = new FileReader();

      reader.onload = () => {
        image.src = reader.result;
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);

      image.onload = () => {
        // Ensure the canvas is created only after image is loaded
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject('Failed to get canvas context');
          return;
        }

        const width = image.width;
        const height = image.height;

        // Crop percentages (for top and bottom)
        const cropTopBottomPercentage = 32.14;

        // Calculate the crop height (removing from top and bottom)
        const cropHeight = height * (1 - (cropTopBottomPercentage * 2) / 100);
        const cropY = (height - cropHeight) / 2; // Vertically center the crop

        // Set canvas size
        canvas.width = width;
        canvas.height = cropHeight;

        // Draw cropped portion of the image on canvas
        ctx.drawImage(image, 0, cropY, width, cropHeight, 0, 0, width, cropHeight);

        // Get base64 of the cropped image
        const croppedDataUrl = canvas.toDataURL();
        console.log(croppedDataUrl, 'croppedDataUrlWEB');

        // Create a Blob from the base64 string
        const byteString = atob(croppedDataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'image/png' });

        // Create a File object from the Blob
        const fileName = file.name; // Use the original file name
        const newFile = new File([blob], fileName, {
          type: 'image/png', // You can keep the MIME type as image/png or match the original file type
        });

        resolve({ base64web: croppedDataUrl, fileweb: newFile }); // Return both the base64 and File
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  };



  const uploadButton = (
    <div>
      {coverLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const formList = [
    <>
      <Form.Item
        label="Enter Name"
        name="username"
        labelCol={{ span: 24 }}
        initialValue={form.getFieldValue().username}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please input your Name',
        //   },
        // ]}
        onChange={handleValues}
      >
        <Input size="large" placeholder="Enter Name" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        label="Enter Bio"
        name="notes"
        initialValue={form.getFieldValue().notes}
        onChange={(e) => form.setFieldsValue({ notes: e.target.value })}
      >
        <TextArea
          showCount
          maxLength={200}
          className="dark-field"
          placeholder="Enter Bio"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        label="Enter Website Address"
        name="website_address"
        initialValue={form.getFieldValue().website_address}
        onChange={(e) => form.setFieldsValue({ website_address: e.target.value })}
        rules={[
          {
            type: 'url',
            // warningOnly: true,
          },
          // {
          //   type: 'string',
          //   min: 6,
          // },
        ]}
      >
        <Input size="large" placeholder="Enter Website Address" />
      </Form.Item>
      {/* <Form.Item
        label="Select Cover Color"
        name="color"
        labelCol={{ span: 24 }}
      >
        <div style={{ border: '1px solid black' }}>
          <Popover content={content} trigger="click" placement="bottom">

            <Button
              className='color-picker-btn'
              style={{
                backgroundColor: color, color: "transparent",
              }}
            >
              {color}

            </Button>
            <span className='ml-2'>
              {color}
            </span>

          </Popover>
        </div>
      </Form.Item> */}
      <Button
        type="primary"
        htmlType="submit"
        loading={loader}
        className="default-btn ml-0 fw-normal"
      // onClick={saveBioData}
      // disabled={!validURL}
      >
        Save
      </Button>
    </>,
  ];
  const onChangeInputImage = (e) => {
    if (e.target.files.length === 1) {
      if (
        e.target.files[0]?.type === 'image/jpeg' ||
        e.target.files[0]?.type === 'image/webp' ||
        e.target.files[0]?.type === 'image/png' ||
        e.target.files[0]?.type === 'image/svg+xml'
      ) {
        const files = [];
        const reader = new FileReader();
        console.log(e.target.files[0], 'e.target.files[0])');

        files.push(e.target.files[0]);
        reader.onloadend = () => {
          files[0].preview = reader.result;
          files[0].toUpload = true;
          setImage(files);
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        notification.error({
          message: 'We Only Support PNG, WEBP, Or JPG Image!',
          className: 'toast-error',
        });
      }
    }
  };

  const clearImage = () => {
    setImage([]);
  };

  const uploadImage = async () => {
    setLoading(true)
    var formData = new FormData();
    formData.append('image', image?.[0]);
    formData.append('instagram_username', form.getFieldValue().username);
    dispatch(basicProfileImage(formData, validate?.payload?.message?._id)).then((res) => {
      if (res.success) {
        dispatch(getUserInfo()).then((res) => {
          setImage([]);

        })
        notification.success({
          message: 'Image Has Been Uploaded!',
          className: 'toast-success',
        });
        setLoading(false)
      }

    }).catch(err => {
      setLoading(false)
    });
  };

  const uploadCover = async () => {
    setCoverLoading(true)
    var formData = new FormData();
    coverImageLocal && formData.append('image', coverImageLocal);
    formData.append('cover_color', form.getFieldValue().color);
    // formData.append('instagram_username', form.getFieldValue().username);
    dispatch(coverImageBasic(formData, validate?.payload?.message?._id)).then((res) => {
      if (res.success) {
        dispatch(getUserInfo()).then((res) => {
          // setCoverImageUrl([]);
          setCoverImageLocal("");

        })
        notification.success({
          message: res?.message,
          className: 'toast-success',
        });
        setCoverLoading(false)
      }
      else {
        console.log(res, 'res');

      }

    }).catch(err => {
      console.log(err?.response?.data?.message?.message, 'err');
      notification.error({
        message: err?.response?.data?.message?.message || 'Something went wrong',
        className: 'toast-error',
      });
      setCoverLoading(false)
    });
  };
  const handlePassword = () => {

    setLoad(true)
    if (form.getFieldValue().newPassword) {
      if (form.getFieldValue().newPassword === form.getFieldValue().changepassword) {
        dispatch(basicPassChange(form.getFieldValue().currentPassword, form.getFieldValue().newPassword, validate?.payload?.message?._id)).then((res) => {
          if (res.success) {
            notification.success({
              message: 'Password Has Been Changed!',
              className: 'toast-success',
            });
            localStorage.removeItem("token");
            localStorage.removeItem("sc_token");
            store.dispatch({ type: AUTHENTICATE, payload: {} })
            setLoad(false)
          }
        }).catch(err => {
          setLoad(false)
          notification.error({
            message: err.message,
            className: 'toast-error',
          });
        });
      }
      else {
        setLoad(false)
        notification.error({
          message: 'New Passwords Do Not Match!',
          className: 'toast-error',
        });
      }
    } else {
      setLoad(false)
      notification.error({
        message: 'Password Field is empty!',
        className: 'toast-error',
      });
    }
  };

  const handleCoverReset = (e, item) => {
    Swal.fire({
      title: `Are you sure you want to set default cover image and color?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: `var(--primary-color)`,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setCoverLoading(true)
        var formData = new FormData();
        formData.append('cover_image_default', true);
        formData.append('cover_color', primaryColor);
        // formData.append('instagram_username', form.getFieldValue().username);
        dispatch(coverImageBasic(formData, validate?.payload?.message?._id)).then((res) => {
          if (res.success) {
            dispatch(getUserInfo()).then((res) => {
              // setCoverImageUrl([]);
              setCoverImageLocal("");
              form.setFieldsValue({
                color: primaryColor,
              });
              setCoverImageUrl(res?.message?.data?.cover_image)
              setColor(primaryColor)
            })
            notification.success({
              message: res?.message,
              className: 'toast-success',
            });
            setCoverLoading(false)
          }

        }).catch(err => {
          setCoverLoading(false)
        });
      }
    });
  }
  const handleImageReset = (e, item) => {
    Swal.fire({
      title: `Are you sure you want to set default profile image?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: `var(--primary-color)`,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true)
        var formData = new FormData();
        formData.append('default_profile_image', true);
        dispatch(basicProfileImage(formData, validate?.payload?.message?._id)).then((res) => {
          if (res.success) {
            dispatch(getUserInfo()).then((res) => {
              setImage([]);

            })
            notification.success({
              message: res?.message,
              className: 'toast-success',
            });
            setLoading(false)
          }

        }).catch(err => {
          setLoading(false)
        });
      }
    });
  }

  useEffect(() => {
    reRender(userInfo)
  }, [userInfo])

  const saveBioData = () => {
    setLoader(true)
    dispatch(updateProfileBio(form.getFieldValue().username, form.getFieldValue().notes, form.getFieldValue().website_address, validate?.payload?.message?._id, form.getFieldValue().color)).then(res => {
      if (res.success) {
        notification.success({
          message: 'Profile Data Updated!',
          className: 'toast-success',
        });
        // history.go(0)
        setLoader(false)
        dispatch(getUserInfo())
        // .then((res) => {
        // if (res.success) {
        //   form.setFieldsValue({
        //     username: res?.message?.data?.name,
        //     notes: res?.message?.data?.bio,
        //   });
        // }
        // });
      }
      setLoader(false)
    })
  }

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num >= 1000) return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
  }
  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Url Copied!',
      className: 'toast-success',
    });
  };

  const showImage = (imageType) => {
    const imageUrl = imageType === 'Mobile' ? mobileCoverImage : webCoverImage;
    setCurrentImageData({ imageType, imageUrl }); // Set the image dynamically
    setIsModalVisible(true);   // Show the modal

  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Basic Setup</h1>
      </div>
      <div className="container p-0 ml-0">
        <div className="row">
          <div className="profile_box_main col-md-6 mb-20">
            <div className="stream-box-white mb-20 ">
              <h3 className="mb-20 basic-hd-top">Profile Image</h3>
              <div className="dp_cont mb-0 align-items-baseline flex-column">
                <span className='w-100 mb-2 profile-image'>
                  <Image
                    alt="logo"
                    src={
                      image?.[0]?.preview
                        ? image?.[0]?.preview
                        : (userInfo?.message?.data?.profile_image_url
                          ? userInfo?.message?.data?.profile_image_url
                          : placeholderImage)
                    }
                    // key={`img-id-${idx.toString()}`}
                    style={{ width: '76px', height: '76px' }}
                    className="circle profile-icon"
                  />
                  {/* <strong>Logo</strong> */}
                  {/* <img src={avatar} alt="Profile" /> */}
                </span>
                <div
                  // className='d-flex flex-column dp_buttons_container mt-4'
                  className='d-flex flex-column w-100 mt-4'
                >
                  <div className="dp_buttons ml-0 pt-6">
                    <input
                      accept=".jpg, .jpeg, .png, .webp, .gif"
                      onChange={onChangeInputImage}
                      onClick={(e) => e.target.value = null}
                      id="fileupload5"
                      type="file"
                      name="file"
                      className="d-none"
                    />
                    {/* <Button
                    accept=".jpg, .jpeg, .png, .webp, .gif"
                    onChange={onChangeInputImage}
                    type="file"
                    color="default"
                    className="select-image profile-btn "
                  > */}

                    <label htmlFor="fileupload5" className="select-image profile-btn text-center cursor-pointer">
                      Change Image
                    </label>

                    {/* </Button> */}
                    <Button
                      onClick={uploadImage}
                      className="d-block upload-btn profile-btn color-secondary"
                      disabled={image.length > 0 ? false : true}
                      loading={loading}
                    >
                      Save
                    </Button>

                    <Button
                      onClick={clearImage}
                      disabled={image.length > 0 ? false : true}
                      type="button"
                      color="default"
                      className="select-image profile-btn color-secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleImageReset}
                      disabled={!userInfo?.message?.data?.profile_image_url}
                      type="button"
                      color="default"
                      className="select-image profile-btn color-secondary"
                    >
                      Reset
                    </Button>
                  </div>
                  <div className='mt-2 logo-image-dimension'>
                    Accepted file format: JPG, JPEG, PNG, WEBP, GIF
                    <br />
                    Recommended dimensions: 110 x 110 pixels
                  </div>
                </div>
              </div>
              {/* <div className='profile-hd-area'>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Instagram Following: </div>
                  <div className='followers-box'>
                    <div className='followers-icon'>
                      <GroupIcon sx={{ color: 'gray' }} />
                    </div>
                    <div className='followers-count'>{social?.follows_count ? numberFormat(social?.follows_count) : 0}</div>
                  </div>

                </div>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Instagram Followers:</div>
                  <div className='followers-box'>
                    <div className='followers-icon'>
                      <GroupIcon sx={{ color: 'gray' }} />
                    </div>
                    <div className='followers-count'>{social?.followers_count ? numberFormat(social?.followers_count) : 0}</div>
                  </div>
                </div>
              </div> */}




            </div>
            {/* <div className="stream-box-white mb-20"> */}
            {/* <h3 className="mb-20 basic-hd-top">Shop URL</h3>
              <div class="your-copy-link">
                <div class="item-a">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={visitorURL + "/" + validate?.payload?.message?.pixel_id}
                    title={visitorURL + "/" + validate?.payload?.message?.pixel_id}
                  >
                    {visitorURL + "/" + validate?.payload?.message?.pixel_id}
                  </a>
                </div>
                <div class="item-b">
                  <Button onClick={() => copyUrl(visitorURL + "/" + validate?.payload?.message?.pixel_id)}>
                    Copy
                  </Button>
                </div>
              </div> */}

            {/* <div className='profile-hd-area'>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Following</div>


                </div>
                <div className='followers-box-main'>
                  <div className='followers-box'>
                    
                    {
                      loading ? (
                        <Spin size="small" />
                      )
                        :
                        <div className='followers-count'>{instaData?.follows_count ? numberFormat(instaData?.follows_count) : 0}</div>
                    } </div>
                </div>
              </div>
              <div className='profile-hd-area'>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Followers </div>


                </div>
                <div className='followers-box-main'>
                  <div className='followers-box'>
                    
                    {
                      loading ? (
                        <Spin size="small" />
                      )
                        :
                        <div className='followers-count'>{instaData?.followers_count ? numberFormat(instaData?.followers_count) : 0}</div>
                    }</div>
                </div>
              </div> */}
            {/* </div> */}
            <div className='stream-box-white'>
              <h5
                class="mb-20 basic-hd-top"
              // class="mb-20 basic-hd-top top-space"
              >Profile</h5>


              <Form {...formProps}
                onFinish={saveBioData}>{formList[current]}
              </Form>
            </div>
          </div>
          <div className="profile_box_main col-md-6 mb-20">
            <div className="stream-box-white mb-20 cover-uploader">
              <div className="mb-20 basic-hd-top d-flex justify-content-between align-items-end">
                <h3 className='cover-image-heading'>Cover Image</h3>
                {/* <div>
                  <button
                    onClick={() => showImage('Mobile')}
                    type="button" class="default-btn  small-btn padt-4 w-initial m-0 btn btn-primary mx-2"><span title="remove">
                      <i class="fa fa-mobile-phone" style={{ fontSize: '20px' }} />
                    </span></button>
                  <button
                    onClick={() => showImage('Desktop')}
                    type="button" class="default-btn  small-btn padt-4 w-initial m-0 btn btn-primary"><span title="remove">
                      <i class="fa fa-desktop" style={{ fontSize: '12px' }} />
                    </span></button>

                </div> */}
              </div>
              <Form.Item
                label="Select Cover Color"
                name="color"
                labelCol={{ span: 24 }}
              >
                <div style={{ border: '1px solid black' }}>
                  <Popover content={content} trigger="click" placement="top">
                    <Button
                      className='color-picker-btn'
                      style={{
                        backgroundColor: color && !/^\s*(?:white|#ffffff|rgb\(255,\s*255,\s*255\)|rgba\(255,\s*255,\s*255,\s*1\))\s*$/i.test(color)
                          ? color
                          : `var(--primary-color)`,
                        color: "transparent",
                      }}
                    >
                      {color && !/^\s*(?:white|#ffffff|rgb\(255,\s*255,\s*255\)|rgba\(255,\s*255,\s*255,\s*1\))\s*$/i.test(color) ? color : `var(--primary-color)`}
                    </Button>
                    <span className='ml-2'>
                      {color && !/^\s*(?:white|#ffffff|rgb\(255,\s*255,\s*255\)|rgba\(255,\s*255,\s*255,\s*1\))\s*$/i.test(color) ? color : `var(--primary-color)`}
                    </span>
                  </Popover>
                </div>
              </Form.Item>
              {userInfo?.message?.data?.cover_image || coverImageUrl?.length > 0 ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100px', // Fixed height to 100px
                    marginBottom: '5px',
                    overflow: 'hidden', // Ensures the content doesn't overflow if the image is larger
                  }}
                >
                  {/* Ant Design Image Component */}
                  <Image
                    src={
                      coverImageUrl?.length > 0
                        ? coverImageUrl
                        : userInfo?.message?.data?.cover_image
                    }
                    alt="avatar"
                    style={{
                      width: '100%',
                      height: '100%', // Ensures the image fits within the fixed height
                      objectFit: 'cover', // Ensures the image covers the container without distortion
                    }}
                  />

                  {/* Gradient Overlay with pointer-events: none */}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: `linear-gradient(to top, ${color || '#052977'} 0%, transparent 100%)`,
                      opacity: 0.6, // Adjust this for gradient intensity
                      pointerEvents: 'none', // Prevent the overlay from blocking image interactions
                    }}
                  />
                </div>
              ) : (
                <ImgCrop rotationSlider aspect={1920 / 825} showGrid={true} beforeCrop={beforeCrop}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className=""
                    showUploadList={false}
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    action="https://httpbin.org/post"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    id='fileupload6'
                  >


                    {uploadButton}
                  </Upload>
                </ImgCrop>
              )}

              <div className='d-flex flex-column'>
                <div className="dp_buttons ml-0 pt-6">
                  {/* <input
                    accept=".jpg, .jpeg, .png, .webp, .gif"
                    onChange={onChangeInputImage}
                    onClick={(e) => e.target.value = null}
                    id="fileupload6"
                    type="file"
                    name="file"
                    className="d-none"
                  /> */}
                  {/* <Button
                    accept=".jpg, .jpeg, .png, .webp, .gif"
                    onChange={onChangeInputImage}
                    type="file"
                    color="default"
                    className="select-image profile-btn "
                  > */}
                  <ImgCrop rotationSlider aspect={1920 / 825} showGrid={true} beforeCrop={beforeCrop}>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="d-none"
                      showUploadList={false}
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      action="https://httpbin.org/post"
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                      id='fileupload6'
                    >


                      {uploadButton}
                    </Upload>
                  </ImgCrop>

                  <label htmlFor="fileupload6" className="select-image profile-btn text-center cursor-pointer">
                    Change Cover
                  </label>

                  {/* </Button> */}
                  <Button
                    onClick={uploadCover}
                    className="d-block upload-btn profile-btn color-secondary"
                    disabled={coverImageLocal || (color !== userInfo?.message?.data?.cover_color) ? false : true}
                    loading={coverLoading}
                  >
                    Save
                  </Button>

                  <Button
                    onClick={() => { setCoverImageUrl([]); setCoverImageLocal("") }}
                    disabled={coverImageLocal ? false : true}
                    type="button"
                    color="default"
                    className="select-image profile-btn color-secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleCoverReset}
                    disabled={!userInfo?.message?.data?.cover_color || !userInfo?.message?.data?.cover_image}
                    type="button"
                    color="default"
                    className="select-image profile-btn color-secondary"
                  >
                    Reset
                  </Button>
                </div>
                <div className='mt-2 logo-image-dimension'>
                  Accepted file format: JPG, JPEG, PNG, WEBP, GIF
                  <br />
                  Recommended dimensions: 1920 x 825 pixels
                </div>
              </div>


            </div>




            <div className="stream-box-white h-10 mb-0">
              <h5 className="mb-20 basic-hd-top">Change Password</h5>
              <Form name="basic" layout="vertical">
                <Form.Item
                  label="Enter Old Password"
                  name="currentPassword"
                  onChange={(e) => form.setFieldsValue({ currentPassword: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Current Password"
                  />
                </Form.Item>
                <Form.Item
                  label="Enter New Password"
                  name="newPassword"
                  onChange={(e) => form.setFieldsValue({ newPassword: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter New Password"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="changepassword"
                  onChange={(e) => form.setFieldsValue({ changepassword: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Confirm password"
                  />
                </Form.Item>
                <div className="mt-9">
                  <Button
                    onClick={handlePassword}
                    type="primary"
                    htmlType="submit"
                    className="default-btn ml-0 fw-normal"
                    loading={load}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div >

      <Modal
        title={currentImageData?.imageType + ' Preview'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null} // No footer buttons
        width={600}   // Set the width as needed
      >
        <img
          alt="cover"
          style={{ width: '100%', height: 'auto' }}  // Make the image responsive
          src={currentImageData?.imageUrl}  // Show the image based on state
        />
      </Modal>
    </>
  );
}