import { BASIC_COVER_IMAGE } from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

export const coverImageBasic = (formData, id) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .put(`users/revise/updateCoverImage/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: BASIC_COVER_IMAGE,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

