import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Alert, Button, Form, Input, InputNumber, Modal as Modald, Tooltip, notification } from "antd";
import { DatePicker, Spin, Select, Empty, Card, Icon, Tag } from "antd";
import {
    customerorderHistory,
    details,
    getPurchaser,
    getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faPlus, faUsd, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { unFollow } from "../../redux/actions/unFollowBrand";
import defaultImage from '../../images/user.png';
import { getBoostedReviews } from "../../redux/actions/boost.action";
import { deBoostReview } from "../../redux/actions/boostReview.action";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AsyncInfluencer from "../influencerReview/asyncInfluencer";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
import { addStripeCard } from "../../redux/actions/addStripeCard";
import { stripeCardDetail } from "../../redux/actions/stripeCardDetail";
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { color } from "styled-system";
import Swal from "sweetalert2";
import { makeDefaultStripeCard, makeStripePayment, updateStripeCard } from "../../redux/actions/stripeCard";
import { deleteStripeCard } from "../../redux/actions/deleteStripeCard";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
    const [form] = Form.useForm();
    const dateFormat = "MM-DD-YYYY";
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [makePaymentLoading, setMakePaymentLoading] = useState(false);

    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment().endOf("year").format("YYYY-MM-DD");

    const [modal, setModal] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadd, setLoadd] = useState(true);
    const [cardNo, setCardNo] = useState("**** **** **** 4242");
    const [expiryMonth, setExpiryMonth] = useState(0);
    const [expiryYear, setExpiryYear] = useState(0);
    const [stripeMessage, setStripeMessage] = useState("");
    const [stripeStatus, setStripeStatus] = useState("");
    const [stripeTimer, setStripeTimer] = useState(false);
    const [cardDetails, setCardDetails] = useState();


    const cardData = [
        {
            id: 1,
            cardType: 'visa',
            cardNumber: '7830',
            expiryDate: '06/24',
            isDefault: true
        },
        {
            id: 2,
            cardType: 'mastercard',
            cardNumber: '1075',
            expiryDate: '02/25',
            isDefault: false
        },
    ];
    const { stripeCardDetails } = useSelector(
        (state) => {
            return state;
        }
    );

    useEffect(() => {
        let query = new URL(window.location.href);
        let searchParams = new URLSearchParams(query.search);
        if (searchParams.get('campaign_status') === "success") {
            setStripeMessage("Your card has been successfully connected")
            setStripeStatus("success")
            setStripeTimer(true)
            setTimeout(() => {
                setStripeTimer(false)
            }, 3000);
            // notification.success({
            //   message: "Your card has been successfully connected",
            //   className: 'toast-success',
            // });
        }
        else if (searchParams.get('campaign_status') === "error") {
            setStripeMessage("Your card is not connected")
            setStripeStatus("error")
            setStripeTimer(true)
            setTimeout(() => {
                setStripeTimer(false)
            }, 3000);
            // notification.error({
            //   message: "Your card is not connected",
            //   className: 'toast-error',
            // });
        }
        setLoading(true);
        // dispatch(getInfluencers()).then((res) => {
        // })

        dispatch(stripeCardDetail()).then((res) => {
            setLoadd(false)
            setLoading(false);
        })



    }, []);



    const detailView = (cardDetail) => {
        setCardDetails(cardDetail)
        setExpiryMonth(cardDetail?.card?.exp_month);
        setExpiryYear(cardDetail?.card?.exp_year);
        setModal(true);
    };

    const addCard = () => {
        setLoading(true);
        dispatch(addStripeCard()).then((res) => {
            window.location.replace(res?.message);
        })
    }
    const removeCard = (id) => {
        Swal.fire({
            title: `Are you sure you want to delete this card?`,
            icon: "warning",
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: `var(--primary-color)`,
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes`,
        }).then((result) => {
            if (result.isConfirmed) {
                setLoadd(true);
                dispatch(deleteStripeCard(id)).then((res) => {
                    setLoadd(false);
                    if (res?.type === "DELETE_STRIPE_CARD_ERROR") {

                        Swal.fire({
                            title: res?.payload?.data?.error,
                            icon: "warning",
                            cancelButtonText: "No",
                            showCancelButton: true,
                            confirmButtonColor: `var(--primary-color)`,
                            cancelButtonColor: "#d33",
                            confirmButtonText: `Yes`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setLoadd(true);
                                dispatch(makeStripePayment()).then((res) => {
                                    if (res?.type === "MAKE_STRIPE_PAYMENT_ERROR") {
                                        setMakePaymentLoading(false)
                                        notification.error({
                                            message: "Error in making payment",
                                            className: "toast-error",
                                        });
                                    } else {
                                        notification.success({
                                            message: res?.payload?.message,
                                            className: "toast-success",
                                        });
                                        setMakePaymentLoading(false)
                                        setLoadd(true);
                                        dispatch(stripeCardDetail()).then((res) => {
                                            setLoadd(false)
                                            // setLoading(false);
                                        })
                                    }
                                });
                            }
                        })
                    } else {
                        notification.success({
                            message: res?.messages,
                            className: "toast-success",
                        });
                        setLoadd(true);
                        dispatch(stripeCardDetail()).then((res) => {
                            setLoadd(false)
                            // setLoading(false);
                        })
                    }
                });
            }
        });
    };
    const makeDefaultCard = (id) => {
        Swal.fire({
            title: `Are you sure you want to set this card as default?`,
            icon: "warning",
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: `var(--primary-color)`,
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes`,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(makeDefaultStripeCard(id)).then((res) => {
                    if (res?.type === "DEFAULT_STRIPE_CARD_ERROR") {
                        notification.error({
                            message: "Error in card",
                            className: "toast-error",
                        });
                    } else {
                        notification.success({
                            message: "Card set default successfully",
                            className: "toast-success",
                        });
                        setLoadd(true);
                        dispatch(stripeCardDetail()).then((res) => {
                            setLoadd(false)
                            // setLoading(false);
                        })
                    }
                });
            }
        });
    };

    const updateCard = (id) => {
        setUpdateLoading(true)
        console.log(cardDetails?.card?.exp_month, 'cardDetails?.card?.exp_month');
        dispatch(updateStripeCard(id, expiryMonth, expiryYear)).then((res) => {
            if (res?.type === "UPDATE_STRIPE_CARD_ERROR") {
                notification.error({
                    message: "Error in saving card",
                    className: "toast-error",
                });
            } else {
                notification.success({
                    message: "Card updated successfully",
                    className: "toast-success",
                });
                setExpiryMonth(0);
                setExpiryYear(0);
                setUpdateLoading(false)
                setLoadd(true);
                setModal(false)
                dispatch(stripeCardDetail()).then((res) => {
                    setLoadd(false)
                    // setLoading(false);
                })
            }
        });



    };

    const makePayment = () => {
        Swal.fire({
            title: `Are you sure you want to make previous payments?`,
            icon: "warning",
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: `var(--primary-color)`,
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes`,
        }).then((result) => {
            if (result.isConfirmed) {
                setMakePaymentLoading(true)
                // dispatch(makeStripePayment()).then((res) => {
                //   if (res?.type === "MAKE_STRIPE_PAYMENT_ERROR") {
                //     setMakePaymentLoading(false)
                //     notification.error({
                //       message: "Error in making payment",
                //       className: "toast-error",
                //     });
                //   } else {
                //     notification.success({
                //       message: "Payment charged successfully",
                //       className: "toast-success",
                //     });
                //     setMakePaymentLoading(false)

                //     setLoadd(true);
                //     dispatch(stripeCardDetail()).then((res) => {
                //       setLoadd(false)
                //       // setLoading(false);
                //     })
                //   }
                // });
            }
        });
    };



    useEffect(() => {
        document.body.classList.add("bioshop-body", "shopper-bioshop-body");
    }, []);




    return (
        <>
            <div className="sales-page-main">
                <div className="title-area mb-3 pb-0">
                    <h1 className="mb-0 pb-0">Reports</h1>
                    {/* {
                        stripeCardDetails?.is_previous_due &&
                        <Button
                            loading={makePaymentLoading}
                            onClick={() => makePayment()}
                            disabled={makePaymentLoading}
                            className="default-btn  padb-3 btn btn-primary" size="medium" style={{ marginRight: 8, height: 35 }}>
                            Make Payment
                        </Button>
                    } */}

                </div>
                <div className="row">
                    <div className="col-lg-6 col-xl-6">
                        {
                            loading ?
                                <div className="stream-box-white">
                                    <span className="spinclass loader-center position-relative">
                                        <Spin size="large" />
                                    </span>
                                </div>
                                :
                                stripeCardDetails?.payload?.is_payment_method ?
                                    <div className="stream-box-white">
                                        {
                                            !loading ?
                                                <div class="data-listing-box pb-3 social-header-hd-area">
                                                    <h4 class="count-title align-items-center d-flex">Your credit card will be charged once your total ad spend reaches ${stripeCardDetails?.payload?.threshold}.
                                                    </h4>
                                                </div>
                                                :
                                                <span className="spinclass loader-center position-relative">
                                                    <Spin size="large" />
                                                </span>
                                        }
                                        <div className="container m-0 p-0">
                                            <h5 className="pt-3">Account Balance</h5>
                                            <div className="row">
                                                <div className="col-12 col-xl col-lg-3 col-md-6 ">
                                                    <div className="dashboard-info-box btm-space-mob">
                                                        <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
                                                        <div class="value-info-hd">
                                                            {loading ? (
                                                                <Spin size="small" />
                                                            )
                                                                :
                                                                <div>
                                                                    {numeral(stripeCardDetails?.payload?.totalCharges?.paid_amount).format("$0,0.0'")}
                                                                </div>}
                                                        </div>
                                                        <div class="total-info-hd">Paid Amount</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl col-lg-3 col-md-6">
                                                    <div className="dashboard-info-box btm-space-mob">
                                                        <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
                                                        <div class="value-info-hd">
                                                            {loading ? (
                                                                <Spin size="small" />
                                                            )
                                                                :
                                                                <div>
                                                                    {numeral(stripeCardDetails?.payload?.totalCharges?.total_amount).format("$0,0.0'")}
                                                                </div>}
                                                        </div>
                                                        <div class="total-info-hd">Total Amount</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl col-lg-3 col-md-6">
                                                    <div className="dashboard-info-box btm-space-mob">
                                                        <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
                                                        <div class="value-info-hd">
                                                            {loading ? (
                                                                <Spin size="small" />
                                                            )
                                                                : <div>
                                                                    {numeral(stripeCardDetails?.payload?.totalCharges?.total_amount - stripeCardDetails?.payload?.totalCharges?.paid_amount).format("$0,0.0'")}
                                                                </div>}
                                                        </div>
                                                        <div class="total-info-hd">Due Amount</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="stream-box-white">
                                        <div class="data-listing-box social-header-hd-area">
                                            <h4 class="count-title align-items-center d-flex">No data available
                                            </h4>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>

                {/* {stripeCardDetails?.payload?.is_payment_method && <p class="payment-note-para note-txt fw-bold">Note:  Your credit card will be charged once your total ad spend reaches ${stripeCardDetails?.payload?.threshold}.
                    <Tooltip overlayClassName="payment-note-tooltip" className="payment-note" placement="right" title={`This means that once the cumulative total of advertising expenses reaches $${stripeCardDetails?.payload?.threshold}, your credit card will be automatically charged for that amount. For any questions or assistance, contact our support team.`}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>} */}

                {/* <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],

                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Status</p>

                <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Status"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={filterStatus}
                  defaultValue=""
                  onChange={(value) => selectStatus(value)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  // options={getAllInfluencers?.message && [
                  //   ...getAllInfluencers?.message].map((influencer) => {
                  //     return ({
                  //       label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  //       value: influencer?.user_id
                  //     }
                  //     )
                  //   })
                  // }
                  options={[
                    { value: '', label: 'All' },
                    { value: 'active', label: 'Active' },
                    { value: 'pending', label: 'Scheduled' },
                    { value: 'cancelled', label: 'De-Activated' },
                  ]}
                />
              </div>




              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>



          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              // <div className="antd-loading-ift">
              // 	<Spin size="large" />
              // </div>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {submit === "none" && <DefaultTable />}

                <div className="purchase-data referral-data">
                  {boostedReviews?.payload?.message?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      // pageCount={Math.ceil(boostedReviews?.payload?.message?.total_records / limit)}
                      pageCount={
                        boostedReviews?.payload?.message?.total_records > 0 ? Math.ceil(boostedReviews?.payload?.message?.total_records / limit) : 0
                      }
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>

              </>
            )}
          </div>
        </div> */}
            </div>





            <Modald
                maskClosable={false}
                className="modal-generic modal-500"
                centered
                visible={modal}
                onCancel={() => setModal(false)}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={false}
            >
                <h2 className="modal-hd1">Card Detail</h2>

                {load ?
                    <span className="spinclass loader-center position-relative">
                        <Spin size="medium" />
                    </span>
                    :
                    <div className="form-styling ">

                        <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">Card Number</p>
                                <InputNumber
                                    className="w-100"
                                    size="large"
                                    disabled
                                    // defaultValue={cardNo}
                                    value={"**** **** **** " + cardDetails?.card?.last4}
                                // onChange={(e) => {
                                //   setAdCost(e);
                                // }}
                                // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center mb-20">
                            <div className="col">
                                <p className="mb-2">Expiry Month</p>
                                <InputNumber className="w-100 custom-month-picker"
                                    picker="month"
                                    format="MM"
                                    defaultValue={cardDetails?.card?.exp_month}
                                    value={expiryMonth}
                                    size="large"
                                    min={1}
                                    max={12}
                                    onChange={(e) => setExpiryMonth(e)}
                                />
                            </div>
                            <div className="col">
                                <p className="mb-2">Expiry Year</p>
                                <InputNumber className="w-100"
                                    picker="year"
                                    format="YYYY"
                                    defaultValue={cardDetails?.card?.exp_year}
                                    value={expiryYear}
                                    size="large"
                                    onChange={(e) => setExpiryYear(e)}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={() => updateCard(cardDetails?.id)}
                                loading={updateLoading}
                                disabled={updateLoading}
                                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center `}
                            >
                                <span className="nav-text">Update</span>
                            </Button>
                            {/* <Button
                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center `}
              >
                <span className="nav-text">Set as Default Card</span>
              </Button> */}
                            <Button
                                className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center mr-0"
                                key="back"
                                onClick={() => { setCardDetails(); setExpiryMonth(0); setExpiryYear(0); setModal(false); }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>}
            </Modald>
        </>
    );
};
